/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { Regions, Regions_regions_results as Region } from './types/Regions'

export const REGIONS = gql`
  query Regions {
    regions {
      results {
        id
        name
        mapPictureId
        location {
          coordinates
        }
      }
    }
  }
`

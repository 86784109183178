export const Modal = {
  header: {
    padding: {
      vertical: '30px',
      horizontal: '48px',
      bottom: '22px',
    },
  },
  body: {
    padding: {
      vertical: '30px',
      horizontal: '48px',
    },
  },
  title: {
    lineHeight: '44px',
    fontSize: '24px',
  },
}

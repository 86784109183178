import { Space } from 'antd'

import { CardSection, Loader } from 'components'
import { LotsIcon, HectaresIcon } from 'assets/icons'
import { ItemInfo } from 'features/MainRouter/components'
import { NumberUtils } from 'utils'

import { MapImage } from './components'
import { LoaderContainer } from '..'

interface Props {
  lotsQty: number
  haQty: number
  image?: string
  isLoading: boolean
}
export const DetailsCard: React.FC<Props> = ({ lotsQty, haQty, image, isLoading }) => {
  return (
    <CardSection title="Total de la zona">
      {isLoading ? (
        <LoaderContainer $minHeight="352px" align="middle" justify="center">
          <Loader />
        </LoaderContainer>
      ) : (
        <Space direction="vertical" size={50}>
          <Space size={50}>
            <ItemInfo Icon={<LotsIcon />} title="Lotes" data={NumberUtils.localize(lotsQty)} />
            <ItemInfo
              Icon={<HectaresIcon />}
              title="Hectareas"
              data={NumberUtils.localize(haQty)}
            />
          </Space>
          <MapImage src={image} />
        </Space>
      )}
    </CardSection>
  )
}

import { gql } from '@apollo/client'

export type {
  OrganizationCurrentAnalyticsSummaryVariables,
  OrganizationCurrentAnalyticsSummary,
  OrganizationCurrentAnalyticsSummary_organizationCurrentAnalyticsSummary_commercialExecutives as OrganizationAnalyticsSummaryCommercialExecutive,
  OrganizationCurrentAnalyticsSummary_organizationCurrentAnalyticsSummary_varieties as OrganizationAnalyticsSummaryVariety,
  OrganizationCurrentAnalyticsSummary_organizationCurrentAnalyticsSummary_companies as OrganizationAnalyticsCompanies,
} from './types/OrganizationCurrentAnalyticsSummary'

export const ORGANIZATION_CURRENT_ANALYTICS_SUMMARY = gql`
  query OrganizationCurrentAnalyticsSummary(
    $regionId: Int
    $subregionId: Int
    $commercialExecutiveId: Int
    $companyId: Int
    $cropId: Int!
    $seasonStartRange: DateTime
    $seasonEndRange: DateTime
    $varietyId: Int
  ) {
    organizationCurrentAnalyticsSummary(
      regionId: $regionId
      subregionId: $subregionId
      commercialExecutiveId: $commercialExecutiveId
      companyId: $companyId
      cropId: $cropId
      seasonStartRange: $seasonStartRange
      seasonEndRange: $seasonEndRange
      varietyId: $varietyId
    ) {
      totals {
        lots
        size
      }
      companies {
        company {
          id
          name
        }
        totals {
          lots
          size
        }
      }
      commercialExecutives {
        commercialExecutive {
          id
          name
          lastName
        }
        totals {
          lots
          size
        }
      }
      varieties {
        totals {
          lots
          size
        }
        variety {
          id
          locales {
            es
          }
        }
      }
      totalLotsWithEmergenceDate
      totalSizeWithEmergenceDate
    }
  }
`

import { PieChart, Pie, ResponsiveContainer } from 'recharts'

const RADIAN = Math.PI / 180
const renderCustomizedLabel = (props: {
  cx: number
  cy: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  payload: { labelColor: string }
  percent: number
  totalHa: number
  midAngle: number
}) => {
  const { cx, cy, innerRadius, outerRadius, payload, percent, midAngle } = props

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      textAnchor="middle"
      fill={payload.labelColor}
      dominantBaseline="central"
      style={{ fontSize: '20px', fontWeight: 500 }}
    >
      {!!percent && `${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

interface Props {
  data: { name: string; value: number; fill: string; labelColor: string }[]
}

export const Chart: React.FC<Props> = ({ data }) => {
  const dataHasValues = data.some(dataValue => !!dataValue.value)
  const getDataValues = () => {
    if (dataHasValues) return data
    return [{ value: 0 }, { value: 100 }]
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={200} height={200}>
        <Pie
          data={getDataValues()}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={dataHasValues && renderCustomizedLabel}
          outerRadius={100}
          dataKey="value"
        />
      </PieChart>
    </ResponsiveContainer>
  )
}

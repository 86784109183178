import styled from 'styled-components'
import { Menu } from 'antd'

export const MenuContainer = styled(Menu)`
  width: 220px;
  position: absolute;
  top: 14px;
  border-radius: 4px;
  border: none;
  background-color: ${({ theme }) => theme.colors.baseColor[900]};

  .ant-dropdown-menu-item {
    display: flex;
    gap: 17px;
    padding: 0 17px;
    align-items: center;
    font-weight: 600 !important;
    margin: 0 !important;
    height: 52px !important;
    color: ${({ theme }) => theme.colors.baseColor[400]} !important;
  }
  .ant-dropdown-menu-item-selected {
    background-color: ${({ theme }) => theme.colors.baseColor[850]} !important;
    color: ${({ theme }) => theme.colors.white} !important;
  }
  .ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item-selected {
    background-color: ${({ theme }) => theme.colors.baseColor[850]} !important;
    color: ${({ theme }) => theme.colors.white} !important;
    border-radius: 4px !important;
  }
`

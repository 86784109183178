import { useQuery } from '@apollo/client'

import { RegionFilterContext } from 'contexts'

import { ORGANIZATION_COMPANIES, OrganizationCompanies } from '../api'

export const useFetchOrganizationCompanies = () => {
  const { region } = RegionFilterContext.useRegionFilterContext()

  const { data, loading } = useQuery<OrganizationCompanies>(ORGANIZATION_COMPANIES, {
    variables: { regionId: region?.id },
  })

  return {
    loading,
    organizationCompanies: data?.organizationCompanies.results,
  }
}

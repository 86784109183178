import { Col, Row } from 'antd'
import styled from 'styled-components'

export { Chart } from './Chart'
export { FortnightSelect } from './FortnightSelect'
export { Collapse } from './Collapse'

export const LoaderContainer = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ChartContainer = styled(Col)`
  height: 300px;
  margin-top: 45px;
`

export const ContainerRow = styled(Row)`
  min-height: 40px;
`

export const ColContainer = styled(Col)`
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  border: 2px solid ${({ theme }) => theme.colors.baseColor[300]};
  padding-top: 10px;
`
export const CollapseContainer = styled(Col)`
  margin: 45px 25px 20px 25px;
`

export const TitleContainer = styled.div`
  margin: 0 25px 0 25px;
`

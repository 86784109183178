import { gql } from '@apollo/client'

export type { UpdateOrganization, UpdateOrganizationVariables } from './types/UpdateOrganization'

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($id: Int!, $updateOrganizationDTO: UpdateOrganizationInput!) {
    updateOrganization(id: $id, updateOrganizationDTO: $updateOrganizationDTO) {
      organization {
        id
        name
        logo
      }
    }
  }
`

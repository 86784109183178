import { useQuery } from '@apollo/client'
import { Select } from 'antd'
import { useMemo } from 'react'

import { Selects } from 'types'

import { Varieties, VARIETIES, VarietiesVariables } from './api'

interface Props {
  cropId?: number
  onChange: (varietyId?: number | Selects.NO_VARIETY) => void
  value?: number | Selects
  commercialExecutiveId?: number
  seasonStartRange?: string
  seasonEndRange?: string
  regionId?: number
  subregionId?: number
}

export const VarietiesSelect: React.FC<Props> = ({
  cropId,
  onChange,
  value,
  commercialExecutiveId,
  seasonStartRange,
  seasonEndRange,
  regionId,
  subregionId,
}) => {
  const { data, loading } = useQuery<Varieties, VarietiesVariables>(VARIETIES, {
    variables: { commercialExecutiveId, seasonStartRange, seasonEndRange, regionId, subregionId },
    skip: !cropId,
  })

  const options = useMemo(() => {
    const filteredVarieties = data?.organization.crops?.find(crop => crop.id === cropId)

    const varietiesOptions =
      filteredVarieties?.varieties?.results?.map(variety => ({
        value: variety.id,
        label: variety.locales.es,
      })) ?? []

    return {
      varietiesOptions,
      showNoVarietyOption: filteredVarieties?.varieties?.withNoVarietyOption,
    }
  }, [data, cropId])

  return (
    <Select
      showSearch
      filterOption={(input, option) =>
        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      placeholder={'Seleccionar variedad'}
      loading={loading}
      disabled={!cropId}
      value={value || Selects.ALL_VARIETIES}
      onChange={id => {
        const filteredVarieties = data?.organization.crops?.find(crop => crop.id === cropId)
        const newSelectedVarietyId = filteredVarieties?.varieties?.results?.find(
          variety => variety.id === id,
        )?.id
        const isNoVarietySelected = id === Selects.NO_VARIETY
        onChange(isNoVarietySelected ? Selects.NO_VARIETY : newSelectedVarietyId)
      }}
    >
      <Select.Option value={Selects.ALL_VARIETIES}>Todas las variedades</Select.Option>
      {options.showNoVarietyOption && (
        <Select.Option value={Selects.NO_VARIETY}>Sin Variedad</Select.Option>
      )}
      {options.varietiesOptions.map(variety => (
        <Select.Option key={variety.value} value={variety.value}>
          {variety.label}
        </Select.Option>
      ))}
    </Select>
  )
}

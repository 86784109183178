import { Select as AntSelect } from 'antd'
import { useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { useHistoricalSeasons, useUser } from 'hooks'
import { CountryId } from 'types'

import { HistoricalSeason } from './types'

interface SelectProps {
  width?: string
}

export const Select = styled(AntSelect)<SelectProps>`
  .ant-select-selector {
    width: ${({ width }) => width && width} !important;
  }
`

const countriesWithHiddenActiveOption = [CountryId.ARGENTINA, CountryId.PARAGUAY, CountryId.URUGUAY]

interface Props {
  selectedHistoricalSeason?: HistoricalSeason
  onChange: (historicalSeason: HistoricalSeason) => void
  width?: string
}

export const HistoricalSeasonSelect: React.FC<Props> = ({
  selectedHistoricalSeason,
  onChange,
  width,
}) => {
  const user = useUser()
  const organizationCountry = user?.organizationRole?.organization.country.id

  const { historicalSeasons, loading } = useHistoricalSeasons()

  const options = useMemo(() => {
    if (!organizationCountry) return

    if (
      historicalSeasons?.length &&
      organizationCountry &&
      countriesWithHiddenActiveOption.includes(organizationCountry)
    ) {
      return { allOptions: historicalSeasons, defaultOption: historicalSeasons[0] }
    }

    const defaultOption = { name: 'Lotes activos' } as HistoricalSeason

    return {
      allOptions: [defaultOption, ...(historicalSeasons ?? [])],
      defaultOption,
    }
  }, [historicalSeasons, organizationCountry])

  useEffect(() => {
    if (!organizationCountry) return

    if (!selectedHistoricalSeason && options?.defaultOption) {
      onChange(options.defaultOption)
    }
  }, [onChange, options?.defaultOption, organizationCountry, selectedHistoricalSeason])

  return (
    <Select
      loading={loading}
      value={selectedHistoricalSeason?.name}
      width={width}
      onChange={seasonName => {
        const selectedSeason = options?.allOptions.find(season => season.name === seasonName)
        if (!selectedSeason) return

        const { name, endRange, startRange } = selectedSeason

        onChange({
          endRange,
          name,
          startRange,
        })
      }}
      options={options?.allOptions.map(season => {
        return { value: season.name, label: season.name }
      })}
    />
  )
}

import { Table as AntTable } from 'antd'
import styled from 'styled-components'

import { Loader } from 'components'
import { NumberUtils } from 'utils'
import { useFeatures } from 'hooks'
import { FeatureSet, OrganizationGeneralModuleFlag } from 'types'

import { useResultsCard } from './hooks'
import { Props } from '../../types'

interface ResultData {
  id: number
  governmentId: string
  companyName: string
  lotName: string
  commercialName: string
  variety: string
  area: number
  status: string
  date: string
  commercialExecutive: string
  estimatedProduction: number
  harvestedProduction: number
  deliveryType: string
}

export const LoaderContainer = styled.div`
  min-height: 200px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const Table = styled(AntTable)<ResultData>`
  th {
    background: transparent !important;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.baseColor[200]}`} !important;
    font-size: 16px;
    text-align: center;
    font-weight: 700 !important;
  }
  td {
    font-size: 13px;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.baseColor[200]}`} !important;
  }
  .ant-table-body {
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.baseColor[900]};
      background-clip: padding-box;
    }
  }
` as unknown as typeof AntTable

export const ResultsCard: React.FC<Props> = ({ data, loading }) => {
  const { tableData } = useResultsCard({ data })
  const { featureFlags, featureSetId, isCommercialNameEnabled } = useFeatures()
  const columns = [
    {
      title: 'Id. Tributaria',
      dataIndex: 'governmentId',
      width: 140,
      hide: !featureFlags.crm?.enabled,
    },
    {
      title: 'Productor',
      dataIndex: 'companyName',
    },
    {
      title: 'Lote',
      dataIndex: 'lotName',
      width: 120,
    },
    {
      title: 'Campo',
      dataIndex: 'fieldName',
      width: 150,
      hide: !featureFlags.general?.flags.includes(OrganizationGeneralModuleFlag.FIELD_ENTITY),
    },
    {
      title: 'Sucursal',
      dataIndex: 'commercialName',
      hide: !isCommercialNameEnabled,
    },
    {
      title: 'Cultivo',
      dataIndex: 'crop',
    },
    {
      title: 'Variedad',
      dataIndex: 'variety',
    },
    {
      title: 'Superficie',
      dataIndex: 'area',
      render: (value: ResultData['area']) => `${NumberUtils.localize(value)} ha`,
    },
    {
      title: 'Estado',
      dataIndex: 'status',
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
    },
    {
      title: 'Comercial',
      dataIndex: 'commercialExecutive',
      hide: !featureFlags.crm?.enabled,
    },
    {
      title: 'T. Est.',
      dataIndex: 'estimatedProduction',
      width: 100,
      render: (value: ResultData['estimatedProduction']) =>
        `${NumberUtils.localize(value, { noFractionDigits: true })} t`,
    },
    {
      title: 'T. Cos.',
      dataIndex: 'harvestedProduction',
      width: 100,
      render: (value: ResultData['harvestedProduction']) =>
        `${NumberUtils.localize(value, { noFractionDigits: true })} t`,
    },
    {
      title: 'Entrega',
      dataIndex: 'deliveryType',
      hide: featureSetId !== FeatureSet.GRUPO_DIANA,
    },
  ]

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <Table
          rowKey="id"
          columns={columns.filter(column => !column.hide)}
          dataSource={tableData}
          pagination={false}
          scroll={{ x: 1500, y: '60vh' }}
        />
      )}
    </>
  )
}

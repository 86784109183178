import { useContext, useState, createContext } from 'react'

import { Region } from 'hooks/useRegions/api'

interface RegionFilterContextType {
  region?: Region
  setRegion: (region?: Region) => void
}

const Context = createContext<RegionFilterContextType>({
  region: undefined,
  setRegion: () => {
    throw new Error(
      'Attempted to use default setRegion function for RegionFilterContext, make sure to pass a custom function implementation',
    )
  },
})

const useValue = (): RegionFilterContextType => {
  const [region, setRegion] = useState<Region>()

  return {
    region,
    setRegion,
  }
}

const useRegionFilterContext = () => {
  return useContext(Context)
}

export const RegionFilterContext = {
  Provider: Context.Provider,
  useValue,
  useRegionFilterContext,
}

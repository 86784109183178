import React from 'react'

import { SimpleCard, CollapsibleCard, Container } from './components'

interface Props {
  title: string
  children: React.ReactNode
  isCollapsible?: boolean
  openCollapsible?: boolean
  actionBar?: JSX.Element
}
export const CardSection = React.forwardRef<HTMLDivElement, Props>(
  ({ title, children, isCollapsible, openCollapsible, actionBar }, ref) => {
    return (
      <Container ref={ref}>
        {isCollapsible ? (
          <CollapsibleCard title={title} open={openCollapsible}>
            {children}
          </CollapsibleCard>
        ) : (
          <SimpleCard title={title} actionBar={actionBar}>
            {children}
          </SimpleCard>
        )}
      </Container>
    )
  },
)

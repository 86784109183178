import { Space } from 'antd'
import styled from 'styled-components'

export { HarvestCard } from './HarvestCard'
export { ChartsCard } from './ChartsCard'

export const CardsContainer = styled(Space).attrs({
  size: [0, 60],
  direction: 'vertical',
})`
  display: flex;
`

import { gql } from '@apollo/client'

export type { Login, LoginVariables } from './types/Login'
export type { VerifyAccount, VerifyAccountVariables } from './types/VerifyAccount'

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      success
      token
      user {
        id
        organizationRole {
          role
        }
      }
    }
  }
`

export const VERIFY_ACCOUNT = gql`
  mutation VerifyAccount($token: String!) {
    verifyAccount(token: $token) {
      success
    }
  }
`

import { RICE_LOT, UNITS } from 'consts'
import { HarvestedCalendarLots } from 'features/MainRouter/features/HarvestStats/hooks/useHarvestedCalendar'
import { NumberUtils } from 'utils'

export const useTableResults = (data: HarvestedCalendarLots[]) => {
  const tableData = data.map(({ lot }) => {
    return {
      id: lot.id,
      companyName: lot.seasonField?.season?.company.name ?? '-',
      fieldName: lot.seasonField?.field?.name ?? '-',
      lotName: lot.name,
      area: lot.size.toFixed(),
      crop: lot.crop.locales.es,
      variety: lot.variety?.locales.es ?? '-',
      productionType: RICE_LOT.PRODUCTION_TYPE_NAME_MAP[lot.productionType],
      grainType: lot.variety?.grainType
        ? RICE_LOT.GRAIN_TYPE_NAME_MAP[lot.variety?.grainType]
        : '-',
      deliveryCondition: RICE_LOT.DELIVERY_CONDITION_NAME_MAP[lot.deliveryCondition],
      estimatedProduction: NumberUtils.roundedNumber(
        lot.riceLot.estimatedYield ? lot.riceLot.estimatedYield * UNITS.KG_TONS_RATIO : 0,
      ),
    }
  })

  return {
    tableData,
  }
}

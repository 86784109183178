import styled from 'styled-components'
import { Col, Collapse as AntCollapse, CollapseProps } from 'antd'

const Collapse = styled(AntCollapse)`
  .ant-collapse-header {
    font-size: 24px;
    font-weight: 700 !important;
    line-height: 30px;
    padding: 12px 0px 18px !important;
    color: ${({ theme }) => theme.colors.baseColor[900]} !important;
    align-items: center !important;
  }
  .ant-collapse-item {
    padding: 12px 16px !important;
  }
  .ant-collapse-header-text {
    display: contents;
  }
  .ant-collapse-content {
    background-color: ${({ theme }) => theme.colors.white} !important;
    border-radius: ${({ theme }) => theme.foundation.cardRadius} !important;
  }
  .ant-collapse-content-box {
    padding: 35px !important;
  }
` as typeof AntCollapse

interface Props {
  title: string
  children: React.ReactNode
  open?: boolean
}
export const CollapsibleCard: React.FC<Props> = ({ title, children, open }) => {
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: title,
      children,
    },
  ]

  return (
    <Col span={24}>
      <Collapse
        ghost
        defaultActiveKey={open ? '1' : undefined}
        expandIconPosition="end"
        items={items}
      ></Collapse>
    </Col>
  )
}

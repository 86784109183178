import { useContext, useState, createContext } from 'react'
import * as Sentry from '@sentry/react'

import { UserOrganizationRole } from 'types'
import { BROADCAST_CHANNEL } from 'consts'

interface AuthContextType {
  role?: UserOrganizationRole
  setRole: (auth: UserOrganizationRole) => void
  userId?: number
  login: (auth: { userId: number; userRole?: UserOrganizationRole }) => void
  logout: () => void
  authChannel?: BroadcastChannel
}

const Context = createContext<AuthContextType>({
  role: undefined,
  setRole: () => {
    throw new Error(
      'Attempted to use default setRole function for AuthContext, make sure to pass a custom function implementation',
    )
  },
  userId: undefined,
  login: () => {
    throw new Error(
      'Attempted to use default login function for AuthContext, make sure to pass a custom function implementation',
    )
  },
  logout: () => {
    throw new Error(
      'Attempted to use default logout function for AuthContext, make sure to pass a custom function implementation',
    )
  },
  authChannel: undefined,
})

const useValue = (): AuthContextType => {
  const [role, setRole] = useState<UserOrganizationRole>()
  const [userId, setUserId] = useState<AuthContextType['userId']>()

  const authChannel = new BroadcastChannel(BROADCAST_CHANNEL.AUTH.NAME)

  const login: AuthContextType['login'] = ({ userId: id, userRole }) => {
    setUserId(id)
    setRole(userRole)
    Sentry.setUser({ id: id.toString() })
  }

  const logout: AuthContextType['logout'] = () => {
    setUserId(undefined)
    setRole(undefined)
    Sentry.configureScope(scope => scope.setUser(null))
  }

  return { role, setRole, userId, login, logout, authChannel }
}

const useAuthContext = () => {
  return useContext(Context)
}

export const AuthContext = {
  Provider: Context.Provider,
  useValue,
  useAuthContext,
}

import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

interface Props {
  color?: string
}

export const Loader: React.FC<Props> = ({ color }) => (
  <Spin indicator={<LoadingOutlined style={{ color: color, fontSize: 24 }} spin />} />
)

import { Form, Input, InputProps } from 'antd'
import styled from 'styled-components'
import { Control, Controller } from 'react-hook-form'

import { ConversionUtils } from 'utils'

const FormItem = styled(Form.Item)`
  .ant-input-affix-wrapper {
    border-radius: 0px;
    border: 0px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.transparent};
  }
  .ant-input-affix-wrapper > .ant-input {
    padding: inherit;
    background: ${({ theme }) => theme.colors.transparent};
    color: ${({ theme }) => theme.colors.white};
  }
  .ant-form-item-explain {
    line-height: 14px;
    font-size: 12px;
    min-height: 14px;
  }
  .ant-input-password-icon {
    color: white;
  }
  .ant-input-password-icon:hover {
    color: white;
  }
`

interface Props extends InputProps {
  placeholder: string
  defaultValue?: string
  prefixIcon?: React.ReactNode
  type?: 'password'
  //TODO: control is not optional, change it when we integrate this component everywhere.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>
  name: string
  capitalize?: boolean
  onHandleBlur?: () => void
}

export const FormInput: React.FC<Props> = ({
  prefixIcon,
  placeholder,
  defaultValue,
  type,
  control,
  name,
  capitalize,
  onHandleBlur,
  ...restProps
}) => {
  const InputComponent = type === 'password' ? Input.Password : Input
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, ...restFieldProps },
        fieldState: { error: fieldError },
      }) => (
        <FormItem validateStatus={fieldError && 'error'} help={fieldError?.message}>
          <InputComponent
            defaultValue={defaultValue}
            placeholder={placeholder}
            prefix={prefixIcon}
            {...restFieldProps}
            {...restProps}
            onBlur={() => {
              onHandleBlur?.()
              onBlur()
            }}
            onChange={event =>
              capitalize
                ? onChange(ConversionUtils.autoCapitalize(event.target.value))
                : onChange(event.target.value)
            }
          />
        </FormItem>
      )}
    />
  )
}

import styled from 'styled-components'
import { Col, Form, Typography } from 'antd'

import { FormInput } from 'components'

export const StyledFormInput = styled(FormInput)`
  height: 40px;
  background: ${({ theme }) => theme.colors.baseColor[900]} !important;
  color: ${({ theme }) => theme.colors.white};
  max-width: 345px;
  width: 100%;
`

const { Text: AntText } = Typography

export const Text = styled(AntText)`
  font-size: 16px;
  font-weight: 400;
`
export const InputTitle = styled(AntText)`
  font-size: 13px;
`

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

import { useQuery } from '@apollo/client'

import { AuthContext } from 'contexts'

import { User, USER, UserVariables } from './api'

export const useUser = () => {
  const { userId } = AuthContext.useAuthContext()

  const { data } = useQuery<User, UserVariables>(USER, {
    variables: { id: userId! },
    skip: !userId,
  })

  const user = data?.user

  return user!
}

import { useMutation } from '@apollo/client'
import { useCallback, useState } from 'react'

import { GraphQLUtils } from 'utils'

import { RESEND_INVITATION, ResendInvitation, ResendInvitationVariables } from './api'

export const useResendInvitation = () => {
  const [updatedId, setUpdatedId] = useState<number>()

  const [resend] = useMutation<ResendInvitation, ResendInvitationVariables>(RESEND_INVITATION, {
    onError: error => {
      setUpdatedId(undefined)
      GraphQLUtils.errorHandler(error)
    },
    onCompleted: () => {
      setUpdatedId(undefined)
    },
  })

  const resendInvitation = useCallback(
    (invitationId: number) => {
      setUpdatedId(invitationId)
      resend({ variables: { id: invitationId } })
    },
    [resend],
  )

  return { resendInvitation, inviteLoadingId: updatedId }
}

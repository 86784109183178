import { useQuery } from '@apollo/client'

import {
  RiceLotStatus,
  AnalyticsTimeMode,
  DateRangeInput,
  GrainType,
  DeliveryCondition,
  ProductionType,
} from 'types'

import { HARVESTED_CALENDAR, HarvestedCalendar, HarvestedCalendarVariables } from './api'

export type { HarvestedCalendarLots } from './api'

interface Props {
  regionId?: number
  subregionId?: number
  commercialExecutiveId?: number
  timeMode?: AnalyticsTimeMode
  riceLotStatus: RiceLotStatus[]
  dateRange?: DateRangeInput
  companyId?: number
  cropId?: number
  varietyId?: number | null
  seasonStartRange?: string
  seasonEndRange?: string
  onCompleted?: (data: HarvestedCalendar) => void
  grainType?: GrainType
  deliveryCondition?: DeliveryCondition
  productionType?: ProductionType
  skip?: boolean
}

export const useHarvestedCalendar = ({
  regionId,
  subregionId,
  commercialExecutiveId,
  timeMode,
  riceLotStatus,
  dateRange,
  companyId,
  cropId,
  varietyId,
  seasonStartRange,
  seasonEndRange,
  onCompleted,
  deliveryCondition,
  grainType,
  productionType,
  skip,
}: Props) => {
  return useQuery<HarvestedCalendar, HarvestedCalendarVariables>(HARVESTED_CALENDAR, {
    variables: {
      regionId,
      subregionId,
      commercialExecutiveId,
      timeMode,
      status: riceLotStatus,
      dateRange,
      companyId,
      cropId: cropId!,
      varietyId,
      seasonStartRange,
      seasonEndRange,
      deliveryCondition,
      grainType,
      productionType,
    },
    skip: !cropId || skip,
    onCompleted,
  })
}

import { gql } from '@apollo/client'

export type {
  InviteOrganizationUser,
  InviteOrganizationUserVariables,
} from './types/InviteOrganizationUser'

export const INVITE_USER = gql`
  mutation InviteOrganizationUser($inviteOrgUserDTO: InviteOrganizationInput!) {
    inviteOrganizationUser(inviteOrgUserDTO: $inviteOrgUserDTO) {
      invitation {
        id
        email
        role
        regionId
      }
    }
  }
`

import { Col, Row } from 'antd'

import {
  CommercialExecutivesSelect,
  SubregionsSelect,
  CompaniesSelect,
  HistoricalSeasonSelect,
  VarietiesSelect,
} from 'components'
import { useCurrentUser, useFeatures } from 'hooks'
import { MapFiltersContext } from 'features/MainRouter/contexts'
import { UserOrganizationRole } from 'types'
import { CropsSelect, RegionsSelect } from 'features/MainRouter/components'
import {
  CropFilterContext,
  HistoricalSeasonFilterContext,
  RegionFilterContext,
  VarietyFilterContext,
} from 'contexts'

import { Container, DrawToolbar, FiltersContainer } from './components'
import { DrawingOption, ToolMode } from '../../types'

interface Props {
  toolMode: ToolMode
  drawingOption: DrawingOption
  isDrawingToolAvailable: boolean
  showDrawingToolDropdown: boolean
  onDrawingToolDropdownVisibleChange: () => void
  onDrawingOptionSelected: (option: DrawingOption) => void
  commercialExecutiveId?: number
}

export const LeftSideBar: React.FC<Props> = ({
  toolMode,
  drawingOption,
  isDrawingToolAvailable,
  showDrawingToolDropdown,
  onDrawingToolDropdownVisibleChange,
  onDrawingOptionSelected,
  commercialExecutiveId,
}) => {
  const { role } = useCurrentUser()
  const { featureFlags } = useFeatures()
  const { setCommercialExecutive, setSubregion, company, setCompany, subregion } =
    MapFiltersContext.useMapFiltersContext()
  const { selectedCrop, setSelectedCrop } = CropFilterContext.useCropFilterContext()
  const { setSelectedVarietyId, selectedVarietyId } = VarietyFilterContext.useVarietyFilterContext()
  const { setSelectedHistoricalSeason, selectedHistoricalSeason } =
    HistoricalSeasonFilterContext.useHistoricalSeasonFilterContext()
  const { region } = RegionFilterContext.useRegionFilterContext()

  return (
    <Container gutter={[15, 15]}>
      <FiltersContainer>
        <HistoricalSeasonSelect
          onChange={setSelectedHistoricalSeason}
          selectedHistoricalSeason={selectedHistoricalSeason}
        />
        <RegionsSelect
          onChange={() => {
            setCompany(undefined)
            setSelectedCrop(undefined)
            setSelectedVarietyId(undefined)
          }}
        />
        {role !== UserOrganizationRole.MILL && featureFlags.crm?.enabled && (
          <CommercialExecutivesSelect
            onChange={commercialExecutive => {
              setCommercialExecutive(commercialExecutive)
              setSelectedVarietyId(undefined)
            }}
            value={commercialExecutiveId}
          />
        )}

        {role === UserOrganizationRole.MILL && <SubregionsSelect onChange={setSubregion} />}

        {featureFlags.subFarmersManagement?.enabled && (
          <CompaniesSelect
            selectedCommercialExecutiveId={commercialExecutiveId}
            onChange={setCompany}
            value={company?.id}
          />
        )}
        <CropsSelect
          commercialExecutiveId={commercialExecutiveId}
          onChange={() => {
            setSelectedVarietyId(undefined)
          }}
          seasonStartRange={selectedHistoricalSeason?.startRange}
          seasonEndRange={selectedHistoricalSeason?.endRange}
          regionId={region?.id}
          subregionId={subregion?.id}
        />
        <VarietiesSelect
          cropId={selectedCrop?.id}
          onChange={setSelectedVarietyId}
          value={selectedVarietyId}
          commercialExecutiveId={commercialExecutiveId}
          seasonStartRange={selectedHistoricalSeason?.startRange}
          seasonEndRange={selectedHistoricalSeason?.endRange}
          regionId={region?.id}
          subregionId={subregion?.id}
        />
      </FiltersContainer>
      <Row>
        <Col>
          <DrawToolbar
            mode={toolMode}
            drawingOption={drawingOption}
            isDrawingToolAvailable={isDrawingToolAvailable}
            showDrawingToolDropdown={showDrawingToolDropdown}
            onDrawingToolDropdownVisibleChange={onDrawingToolDropdownVisibleChange}
            onDrawingOptionSelected={onDrawingOptionSelected}
          />
        </Col>
      </Row>
    </Container>
  )
}

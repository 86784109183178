import { Space } from 'antd'
import { Dayjs } from 'dayjs'

import { DatePicker } from '../DatePicker'

interface Props {
  isCurrentPickerDisabled?: boolean
  isFuturePickerDisabled?: boolean
  onChangeStartDate: (date: Dayjs | null) => void
  onChangeEndDate: (date: Dayjs | null) => void
  startDate: Dayjs | null
  endDate: Dayjs | null
  horizontalSpacing?: number
}

export const DateRangePicker: React.FC<Props> = ({
  onChangeEndDate,
  onChangeStartDate,
  endDate,
  startDate,
  isFuturePickerDisabled = false,
  isCurrentPickerDisabled = false,
  horizontalSpacing = 13,
}) => {
  return (
    <Space size={horizontalSpacing}>
      <DatePicker
        disabled={isCurrentPickerDisabled}
        onChange={onChangeStartDate}
        placeholder={'Desde'}
        value={startDate}
      />
      <DatePicker
        disabled={isFuturePickerDisabled}
        onChange={onChangeEndDate}
        placeholder={'Hasta'}
        value={endDate}
        startDate={startDate}
      />
    </Space>
  )
}

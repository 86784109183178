/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  Subregions,
  SubregionsVariables,
  Subregions_subregions_results as Subregion,
} from './types/Subregions'

export const SUBREGIONS = gql`
  query Subregions($regionId: Int!) {
    subregions(regionId: $regionId) {
      results {
        id
        name
        location {
          coordinates
          type
        }
      }
    }
  }
`

import { Typography } from 'antd'
import styled from 'styled-components'

const { Text: AntText } = Typography

export const TooltipText = styled(AntText)`
  color: ${({ theme }) => theme.colors.baseColor[700]} !important;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
`
export const TooltipTextContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 10px;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
`

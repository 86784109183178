import { gql } from '@apollo/client'

export type {
  UnassignedClients,
  UnassignedClients_unassignedClients_clients as UnassignedClient,
} from './types/UnassignedClients'

export const UNASSIGNED_CLIENTS = gql`
  query UnassignedClients {
    unassignedClients {
      clients {
        id
        name
        brand
        governmentId
        daysSinceVisit
      }
    }
  }
`

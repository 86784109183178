import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { VALIDATIONS } from 'consts'

export const useLoginForm = () => {
  const validationSchema = yup.object().shape({
    email: yup.string().email('Debe ser un formato de email válido').required('Campo requerido'),
    password: yup
      .string()
      .min(VALIDATIONS.PASSWORD_MIN_LENGTH, 'Mínimo 8 caracteres')
      .required('Campo requerido'),
  })

  return useForm({ resolver: yupResolver(validationSchema) })
}

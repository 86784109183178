import { Button, Card, Col, Row, Typography } from 'antd'
import styled from 'styled-components'

import { loginCardRiceImage } from 'assets/images'
import { MixinsStyles } from 'styles'

const { Text: AntText } = Typography

export { ContentHeader } from './ContentHeader'
export { FormEmailInput } from './FormEmailInput'
export { FormPasswordInput } from './FormPasswordInput'
export { LoginTextCta } from './LoginTextCTA'
export { FeedbackCard } from './FeedbackCard'

export const BackgroundImage = styled(Row)`
  ${MixinsStyles.coverImage}
  background-image: url(${loginCardRiceImage});
  background-position: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledCard = styled(Card)`
  width: 988px;
  height: 550px;
  border: none;
  background-color: transparent;
`

export const Overlay = styled(Row)`
  flex: 1;
  width: 100%;
  background-color: black;
  opacity: 0.3;
`

export const CardContainer = styled(Row)`
  width: 100%;
`

export const CardContent = styled(Row)`
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5), 5px 5px 50px rgba(0, 0, 0, 0.5);
`

export const CardImage = styled(Row)`
  flex: 1;
  ${MixinsStyles.coverImage}
  padding: 0 0 21px 40px;
  align-items: flex-end;
`

export const CardImageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CardImageTitle = styled(AntText)`
  color: ${props => props.theme.colors.white} !important;
  font-size: 37px !important;
  font-weight: 600 !important;
  line-height: 40px !important;
`

export const CardImageText = styled(AntText)`
  color: ${props => props.theme.colors.white} !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 19px;
`

export const ContentContainer = styled(Row)`
  width: 400px;
  padding: 50px 46px 20px;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.baseColor[900]};
`
interface TextProps extends React.ComponentProps<typeof AntText> {
  error?: boolean
}

const Text: React.FC<TextProps> = ({ error, ...restProps }) => {
  return <AntText {...restProps} />
}
export const CenteredText = styled(Text)`
  width: 100%;
  text-align: center;
  a {
    color: ${props => props.theme.colors.secondary[600]};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
`

export const Content = styled(Col)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  width: 100%;
  height: 44px;
`
export const VerificationText = styled(CenteredText)`
  color: ${props => props.theme.colors.white};
`

export const VerificationErrorText = styled(CenteredText)`
  margin-top: 10px;
  font-size: 14px;
  color: ${props => props.theme.colors.error};
`

export const WhiteVerificationErrorText = styled(VerificationErrorText)`
  color: ${props => props.theme.colors.white};
`

/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  Varieties,
  VarietiesVariables,
  Varieties_organization_crops_varieties_results as Variety,
} from './types/Varieties'

export const VARIETIES = gql`
  query Varieties(
    $commercialExecutiveId: Int
    $seasonStartRange: DateTime
    $seasonEndRange: DateTime
    $regionId: Int
    $subregionId: Int
  ) {
    organization {
      crops(
        commercialExecutiveId: $commercialExecutiveId
        seasonStartRange: $seasonStartRange
        seasonEndRange: $seasonEndRange
        regionId: $regionId
        subregionId: $subregionId
      ) {
        id
        varieties {
          results {
            id
            locales {
              es
            }
          }
          withNoVarietyOption
        }
      }
    }
  }
`

/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  OrganizationCompanies,
  OrganizationCompanies_organizationCompanies_results as OrganizationCompany,
} from './types/OrganizationCompanies'

export const ORGANIZATION_COMPANIES = gql`
  query OrganizationCompanies($regionId: Int) {
    organizationCompanies(regionId: $regionId) {
      results {
        id
        name
        subregionId
        seasons {
          id
          startDate
          commercialExecutive {
            id
            name
            lastName
          }
        }
      }
    }
  }
`

import { Dayjs } from 'dayjs'

const isWithinInterval = (date: Dayjs, startRange: Dayjs, endRange: Dayjs) => {
  const isSameOrAfter = date.isSameOrAfter(startRange)
  const isSameOrBefore = date.isSameOrBefore(endRange)

  return isSameOrAfter && isSameOrBefore
}

export const DateUtils = { isWithinInterval }

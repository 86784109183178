/* eslint-disable no-restricted-globals */
import { createUploadLink } from 'apollo-upload-client'
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

import { config } from 'config'
import { STORAGE_KEYS } from 'consts'

const buildApolloClient = () => {
  const UNAUTHORIZED_ERROR_MSG = 'Unauthorized'

  const logoutLink = onError(({ graphQLErrors }) => {
    // We don't have react router context so we need to use location directly
    if (graphQLErrors?.[0].message === UNAUTHORIZED_ERROR_MSG && location.pathname !== '/auth') {
      window.localStorage.removeItem(STORAGE_KEYS.USER_TOKEN)
      location.href = '/'
    }
  })

  const authLink = setContext((_, { headers }) => {
    const storedToken = localStorage.getItem('token')
    // Workaround to delete " character from the beginning and end of the token
    const token = storedToken && JSON.parse(storedToken)
    return {
      headers: {
        authorization: storedToken ? `Bearer ${token}` : '',
        ...headers,
      },
    }
  })

  const httpLink = createUploadLink({ uri: config.apiUrl }) as unknown as ApolloLink

  const apolloClient = new ApolloClient({
    uri: config.apiUrl,
    cache: new InMemoryCache(),
    link: logoutLink.concat(authLink).concat(httpLink),
  })

  return apolloClient
}

export const ApolloService = {
  buildApolloClient,
}

import { Select, Space } from 'antd'
import styled, { useTheme } from 'styled-components'

import { useUser } from 'hooks'
import { RiceLotStatus } from 'types'
import { RICE_LOT } from 'consts'

interface BallProps {
  color: string
}

export const Ball = styled.div<BallProps>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  border: 1px solid ${({ theme }) => theme.colors.white};
`

interface Props {
  open?: boolean
  labelWithBall?: boolean
  onChange: (lotStatus: RiceLotStatus[]) => void
  value?: RiceLotStatus[]
}

const { Option } = Select

export const LotStatusSelect: React.FC<Props> = ({ open, labelWithBall, onChange, value }) => {
  const { colors } = useTheme()

  const user = useUser()

  const organizationStatusMilestones = user?.organizationRole?.organization?.statusMilestones

  const lotStatusOptions =
    organizationStatusMilestones?.map(status => ({
      label: RICE_LOT.STATUS_NAME_MAP[status],
      value: status,
      ballColor: colors.lotStatus[status],
    })) ?? []

  return (
    <Select
      listHeight={300}
      open={open}
      mode={'multiple'}
      placeholder="Sin seleccionar..."
      maxTagCount="responsive"
      notFoundContent={'No hay coincidencia'}
      value={value}
      onChange={values => {
        const riceLotValues = (values.length ? values : []) as RiceLotStatus[]
        onChange(riceLotValues)
      }}
    >
      {lotStatusOptions.map(filter => (
        <Option key={filter.value} value={filter.value}>
          <Space size={16}>
            {labelWithBall && <Ball color={filter.ballColor} />}
            {filter.label}
          </Space>
        </Option>
      ))}
    </Select>
  )
}

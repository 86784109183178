import { Space as AntSpace } from 'antd'
import styled from 'styled-components'

export { FilteredLotsSummaryCard } from './FilteredLotsSummaryCard'
export { FiltersCard } from './FiltersCard'
export { ResultsCard } from './ResultsCard'
export { ExportButton } from './ExportButton'

export const CardContainer = styled.div`
  padding: 12px 16px;
`

export const CardsContainer = styled(AntSpace).attrs({
  size: 60,
  direction: 'vertical',
})`
  display: flex;
`

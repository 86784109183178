import { Body } from 'features/MainRouter/components'

import { ClientAssignmentCard, GoalsCard, Container } from './components'

export const Sales = () => {
  return (
    <Body headerTitle="Ventas">
      <Container>
        <ClientAssignmentCard />
        <GoalsCard />
      </Container>
    </Body>
  )
}

import { Space } from 'antd'

import { LogoKunaIcon } from 'assets/icons'
import { SquareAvatar, UserAvatar } from 'components'
import { EXTERNAL_LINKS } from 'consts'
import { useAuth } from 'hooks'

import { Container, Item, DescriptionText, UsernameText, Link, Button, Divider } from './components'

interface Props {
  name?: string
  lastName?: string
  profilePicture?: string | null
}
export const ProfilePopoverContent: React.FC<Props> = ({ name, lastName, profilePicture }) => {
  const { logout } = useAuth()
  return (
    <Container>
      <Item>
        <SquareAvatar src={<LogoKunaIcon height={30} />} />
        <DescriptionText>
          Ir al
          <Link target={'_blank'} href={EXTERNAL_LINKS.PORTAL}>
            {' portal '}
          </Link>
          de carga de información
        </DescriptionText>
      </Item>
      <Divider />
      <Item>
        <UserAvatar $borderSize={'4px'} src={profilePicture} />
        <Space direction="vertical" size={0}>
          <UsernameText>
            {name} {lastName}
          </UsernameText>
          <Button type="link" onClick={logout}>
            Cerrar sesión
          </Button>
        </Space>
      </Item>
    </Container>
  )
}

import { useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { UpdateOrganizationUser, UpdateOrganizationUserVariables, UPDATE_ORG_USER } from './api'

export const useUpdateOrganizationUser = () => {
  const [updateOrganizationUser] = useMutation<
    UpdateOrganizationUser,
    UpdateOrganizationUserVariables
  >(UPDATE_ORG_USER, {
    onError: GraphQLUtils.errorHandler,
  })
  return { updateOrganizationUser }
}

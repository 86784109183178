/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  OrganizationAnalyticsExport,
  OrganizationAnalyticsExportVariables,
} from './types/OrganizationAnalyticsExport'

export const ORGANIZATION_ANALYTICS_EXPORT = gql`
  query OrganizationAnalyticsExport(
    $regionId: Int
    $subregionId: Int
    $commercialExecutiveId: Int
    $timeMode: AnalyticsTimeMode
    $status: [RiceLotStatus!]
    $companyId: Int
    $dateRange: DateRangeInput
    $cropId: Int!
    $seasonStartRange: DateTime
    $seasonEndRange: DateTime
    $varietyId: Int
  ) {
    organizationAnalyticsExport(
      regionId: $regionId
      subregionId: $subregionId
      commercialExecutiveId: $commercialExecutiveId
      timeMode: $timeMode
      status: $status
      companyId: $companyId
      dateRange: $dateRange
      cropId: $cropId
      seasonStartRange: $seasonStartRange
      seasonEndRange: $seasonEndRange
      varietyId: $varietyId
    ) {
      url
    }
  }
`

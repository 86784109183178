import { Select as AntSelect } from 'antd'
import styled from 'styled-components'

import { SELECTS } from 'consts'

import { Subregion } from './api'
import { useSubregions } from './hooks'

interface SelectProps {
  width?: string
}

export const Select = styled(AntSelect)<SelectProps>`
  .ant-select-selector {
    width: ${({ width }) => width && width} !important;
  }
`

interface Props {
  onChange: (subregion?: Subregion) => void
  value?: number
  isDisabled?: boolean
  width?: string
}

export const SubregionsSelect: React.FC<Props> = ({ onChange, value, isDisabled, width }) => {
  const { loading, subregionsOptions, subregions, regionId } = useSubregions()

  return (
    <Select
      value={value}
      loading={loading}
      placeholder="Seleccionar sub zona"
      defaultValue={0}
      key={regionId}
      width={width}
      options={subregionsOptions}
      onChange={subregionId => {
        const selectedSubregion = subregions?.find(subregion => subregion.id === subregionId)
        onChange(subregionId === SELECTS.DEFAULT_OPTION_ID ? undefined : selectedSubregion)
      }}
      disabled={isDisabled}
    />
  )
}

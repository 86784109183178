import { useState } from 'react'

import { ToolMode } from '../types'

export const useTools = () => {
  const [toolMode, setToolMode] = useState<ToolMode>(null)

  return {
    toolMode,
    setToolMode,
  }
}

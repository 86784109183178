import { StoreObject, useMutation } from '@apollo/client'
import { useCallback, useState } from 'react'

import { GraphQLUtils } from 'utils'

import { DELETE_INVITATION, DeleteInvitation, DeleteInvitationVariables } from './api'

export const useDeleteInvitation = () => {
  const [deletedId, setDeletedId] = useState<number>()

  const [_deleteInvitation] = useMutation<DeleteInvitation, DeleteInvitationVariables>(
    DELETE_INVITATION,
    {
      onError: error => {
        setDeletedId(undefined)
        GraphQLUtils.errorHandler(error)
      },
      onCompleted: () => {
        setDeletedId(undefined)
      },
      update: (cache, { data: response }, { variables }) => {
        if (!variables || !response?.deleteInvitation.success) return

        const deletedInvitation: StoreObject = {
          id: variables.id,
          __typename: 'OrganizationInvitation',
        }

        cache.evict({ id: cache.identify(deletedInvitation) })
        cache.gc()
      },
    },
  )

  const deleteInvitation = useCallback(
    (id: number) => {
      setDeletedId(id)
      _deleteInvitation({ variables: { id } })
    },
    [_deleteInvitation],
  )

  return { deleteInvitation, deletedLoadingId: deletedId }
}

import styled from 'styled-components'

import { placeholderImage } from 'assets/images'

const Image = styled.img`
  background-position: center;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  width: 280px;
  height: 240px;
`

interface Props {
  src?: string
}

export const MapImage: React.FC<Props> = ({ src }) => {
  //TODO: implements a upload image solution
  return <Image src={src ?? placeholderImage} alt="Minimapa lote" />
}

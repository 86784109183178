import { Select } from 'antd'

import { ProductionType, SelectValueType, Selects } from 'types'
import { RICE_LOT } from 'consts'

interface Props {
  onChange: (productionType: SelectValueType<ProductionType>) => void
  value?: SelectValueType<ProductionType>
}

const { Option } = Select

const productionTypes = [ProductionType.CONVENTIONAL, ProductionType.ORGANIC]

export const ProductionTypeSelect: React.FC<Props> = ({ onChange, value }) => {
  const productionTypesOptions =
    productionTypes.map(productionType => ({
      label: RICE_LOT.PRODUCTION_TYPE_NAME_MAP[productionType],
      value: productionType,
    })) ?? []

  return (
    <Select
      listHeight={300}
      placeholder="Sin seleccionar..."
      notFoundContent={'No hay coincidencia'}
      value={value ?? Selects.ALL_OPTIONS}
      onChange={onChange}
    >
      <Option value={Selects.ALL_OPTIONS}>Total</Option>
      {productionTypesOptions.map(productionType => (
        <Option key={productionType.value} value={productionType.value}>
          {productionType.label}
        </Option>
      ))}
    </Select>
  )
}

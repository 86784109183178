import React from 'react'

import { CardSection } from 'components'

import { OrganizationStats } from '../../hooks/useHarvestStats/api'
import { Table } from './components'

interface Props {
  harvestStats?: OrganizationStats['organizationStats']
  isLoading?: boolean
}
export const HarvestCard: React.FC<Props> = ({ harvestStats, isLoading }) => {
  return (
    <CardSection title="Superficie y toneladas totales">
      <Table harvestStats={harvestStats} isLoading={isLoading} />
    </CardSection>
  )
}

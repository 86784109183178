import { Button as AntButton, Radio as AntRadio } from 'antd'
import styled from 'styled-components'

export { ColWithTitle } from './ColWithTitle'

export const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  justify-content: flex-start;
  @media (min-width: 1512px) {
    justify-content: space-around;
  }
`

export const DatePickerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

export const Radio = styled(AntRadio)`
  font-size: 16px;
  font-weight: 500;
` as typeof AntRadio

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: flex-end;
  @media (min-width: 1920px) {
    width: fit-content;
  }
`

export const Button = styled(AntButton)`
  width: 156px;
  height: 47px;
  font-size: 17px;
  font-weight: 500;
`

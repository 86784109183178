import { DefaultTheme } from 'styled-components'

import { Colors } from './colors'
import { Foundation } from './foundation'
import { Modal } from './modal'

export const theme: DefaultTheme = {
  colors: Colors,
  foundation: Foundation,
  modal: Modal,
}

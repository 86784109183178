import { useQuery } from '@apollo/client'

import { RegionFilterContext } from 'contexts'

import { CommercialExecutives, COMMERCIAL_EXECUTIVES, CommercialExecutivesVariables } from '../api'

export const useFetchCommercialExecutives = ({
  includeGoals = false,
}: {
  includeGoals?: boolean
} = {}) => {
  const { region } = RegionFilterContext.useRegionFilterContext()

  const { data, loading } = useQuery<CommercialExecutives, CommercialExecutivesVariables>(
    COMMERCIAL_EXECUTIVES,
    {
      variables: { regionId: region?.id, includeGoals },
    },
  )

  return {
    loading,
    commercialExecutives: data?.commercialExecutives.results,
  }
}

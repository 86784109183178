import { StoreObject, useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { ASSIGN_CLIENT, AssignClient, AssignClientVariables } from './api'

export const useAssignClient = () => {
  const [assignClient, { loading }] = useMutation<AssignClient, AssignClientVariables>(
    ASSIGN_CLIENT,
    {
      update: (cache, { data }, { variables }) => {
        if (!variables || !data?.assignClient.success) return

        const assignedClient: StoreObject = {
          id: variables.assignClientDTO.id,
          __typename: 'UnassignedClient',
        }

        cache.evict({ id: cache.identify(assignedClient) })
        cache.gc()
      },
      onError: GraphQLUtils.errorHandler,
    },
  )

  return { assignClient, loading }
}

import { gql } from '@apollo/client'

export type { SignUp, SignUpVariables } from './types/SignUp'

export const SIGNUP = gql`
  mutation SignUp($createUserDTO: CreateUserInput!, $token: String, $locale: String) {
    signup(createUserDTO: $createUserDTO, token: $token, locale: $locale) {
      success
      user {
        id
        isVerified
        organizationRole {
          role
        }
      }
      token
    }
  }
`

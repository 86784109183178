import React, { useState } from 'react'
import { Row, Space, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useTheme } from 'styled-components'

import { RegionsSelect, CardSection, Loader, UserAvatar, Table } from 'components'
import { OrganizationFeatures, UserOrganizationRole } from 'types'
import { ShareArrowIcon, TrashIcon } from 'assets/icons'
import { SELECTS } from 'consts'
import { useFeatures } from 'hooks'

import { CardContainer } from '..'
import { UserTableData } from '../../types'
import { IconContainer, RolesSelect, Text, InvitationModal, StyledButton } from './components'
import { useUpdateOrganizationUser, useDeleteInvitation, useResendInvitation } from './hooks'

interface Props {
  users: UserTableData[]
  loading: boolean
  organizationFeatures?: OrganizationFeatures[]
}

export const UsersCard: React.FC<Props> = ({ users, loading, organizationFeatures }) => {
  const { updateOrganizationUser } = useUpdateOrganizationUser()
  const { resendInvitation, inviteLoadingId } = useResendInvitation()
  const { deleteInvitation, deletedLoadingId } = useDeleteInvitation()
  const { colors } = useTheme()
  const { assignableRegionRoles, featureFlags } = useFeatures()

  const [showModal, setShowModal] = useState<boolean>(false)

  const columns: ColumnsType<UserTableData> = [
    {
      title: 'Usuario',
      render: (user: UserTableData) => (
        <Space size={20}>
          <UserAvatar src={user.profilePicture} />
          <Text>{user.fullname}</Text>
        </Space>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Rol',
      width: '140px',
      render: (user: UserTableData) => (
        <RolesSelect
          width="165px"
          organizationFeatures={organizationFeatures}
          disabled={
            user.currentUser ||
            user.isInvitation ||
            (assignableRegionRoles.includes(user.role) && !!user.regionId)
          }
          value={user.role}
          onChange={role => {
            updateOrganizationUser({ variables: { role, userId: user.id } })
          }}
          disabledRoles={assignableRegionRoles}
        />
      ),
    },

    {
      title: 'Zona asignada',
      render: (user: UserTableData) => (
        <RegionsSelect
          disabled={
            !!user.currentUser ||
            !!user.isInvitation ||
            !(user.role === UserOrganizationRole.ADMIN && featureFlags.crm?.enabled)
          }
          value={user.regionId ?? SELECTS.DEFAULT_OPTION_ID}
          onChange={regionId => {
            updateOrganizationUser({
              variables: { userId: user.id, regionId: regionId === 0 ? null : regionId },
            })
          }}
        />
      ),
    },
    {
      width: '140px',
      render: (user: UserTableData) => (
        <>
          {user.isInvitation && (
            <Space size={20}>
              <Tooltip title="Reenviar invitación">
                <IconContainer onClick={() => resendInvitation(user.id)}>
                  {inviteLoadingId === user.id ? (
                    <Loader color={colors.white} />
                  ) : (
                    <ShareArrowIcon color="white" />
                  )}
                </IconContainer>
              </Tooltip>
              <Tooltip title="Eliminar invitación">
                <IconContainer onClick={() => deleteInvitation(user.id)}>
                  {deletedLoadingId === user.id ? (
                    <Loader color={colors.white} />
                  ) : (
                    <TrashIcon color="white" />
                  )}
                </IconContainer>
              </Tooltip>
            </Space>
          )}
        </>
      ),
    },
  ]
  return (
    <CardContainer>
      <CardSection
        title="Usuarios"
        actionBar={<StyledButton onClick={() => setShowModal(true)}>Agregar usuario</StyledButton>}
      >
        {loading ? (
          <Row justify="center" align="middle" style={{ minHeight: '200px' }}>
            <Loader />
          </Row>
        ) : (
          <Table
            columns={columns}
            rowKey={'email'}
            dataSource={users}
            pagination={false}
            scroll={{ x: 1000 }}
          />
        )}

        {showModal && (
          <InvitationModal
            onSuccess={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            organizationFeatures={organizationFeatures}
          />
        )}
      </CardSection>
    </CardContainer>
  )
}

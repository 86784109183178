import styled from 'styled-components'
import { Card as AntCard, Col, Typography } from 'antd'

const { Title: AntTitle } = Typography

const Title = styled(AntTitle)`
  font-weight: 700 !important;
  margin-bottom: 30px !important;
  margin-top: 0px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.baseColor[900]} !important;
`

const Card = styled(AntCard)`
  border: 0px;
  flex: 1;
  padding: 35px;
`

const Container = styled(Col)`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

interface Props {
  title: string
  children: React.ReactNode
  actionBar?: JSX.Element
}
export const SimpleCard: React.FC<Props> = ({ title, children, actionBar }) => {
  return (
    <Container span={24}>
      <Header>
        <Title level={3}>{title}</Title>
        {actionBar && <>{actionBar}</>}
      </Header>
      <Card>{children}</Card>
    </Container>
  )
}

import { UserOrganizationRole } from 'types'
import { useCurrentUser } from 'hooks'

import { useFetchCommercialExecutives } from './hooks'

interface Props {
  showAllOption?: boolean
}
export const useCommercialExecutives = ({ showAllOption = true }: Props = {}) => {
  const { role, userId } = useCurrentUser()
  const { commercialExecutives, loading } = useFetchCommercialExecutives()

  const isLoggedUserACommercialUser = role === UserOrganizationRole.COMMERCIAL

  const mappedCommercialExecutives =
    commercialExecutives?.map(commercialExecutive => ({
      value: commercialExecutive.id,
      label: `${commercialExecutive.name} ${commercialExecutive.lastName}`,
    })) ?? []

  const commercialExecutivesOptions = showAllOption
    ? [{ value: 0, label: 'Todos los ejec. comerciales' }, ...mappedCommercialExecutives]
    : mappedCommercialExecutives

  const commercialUser = isLoggedUserACommercialUser
    ? commercialExecutives?.find(commercialExecutive => commercialExecutive.id === userId)
    : undefined

  const regionsByCommercialExecutive = commercialExecutives?.map(commercialExecutive => ({
    value: commercialExecutive.regions?.[0].id,
    commercialExecutiveId: commercialExecutive.id,
  }))

  const defaultRegionIdByCommercialExecutive = isLoggedUserACommercialUser
    ? regionsByCommercialExecutive?.find(region => region.commercialExecutiveId === userId)?.value
    : undefined

  return {
    loading,
    commercialExecutivesOptions,
    commercialUser,
    defaultRegionIdByCommercialExecutive,
    commercialExecutives,
  }
}

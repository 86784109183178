import { css } from 'styled-components'

const coverImage = css`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
`

export const MixinsStyles = {
  coverImage,
}

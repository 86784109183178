import {
  DeliveryCondition,
  GrainType,
  ProductionType,
  RiceLotStatus,
  SeasonMillDeliveryType,
} from 'types'

const STATUS_NAME_MAP: { [key in RiceLotStatus]: string } = {
  [RiceLotStatus.PREPARATION]: 'Preparación',
  [RiceLotStatus.BURNING]: 'Quema/s',
  [RiceLotStatus.PLANTING]: 'Siembra',
  [RiceLotStatus.EMERGENCE]: 'Emergencia',
  [RiceLotStatus.DIFFERENTIATION]: 'Diferenciación',
  [RiceLotStatus.FLOWERING]: 'Floración',
  [RiceLotStatus.EAR_APPEARANCE]: 'Ap. espiga',
  [RiceLotStatus.PHYSIOLOGICAL_MATURITY]: 'Madurez fisiológica',
  [RiceLotStatus.HARVEST]: 'Cosecha',
  [RiceLotStatus.INITIAL_FLOOD]: 'Inundación inicial',
  [RiceLotStatus.PRE_EAR_APPEARANCE]: 'Pre ap. espiga',
  [RiceLotStatus.START_IRRIGATION]: 'Inicio de riego',
  [RiceLotStatus.END_IRRIGATION]: 'Fin de riego',
  [RiceLotStatus.VEGETATIVE_STATE_THREE]: '2/3 Hojas',
}

const DELIVERY_STATUS_NAME_MAP: { [key in SeasonMillDeliveryType]: string } = {
  [SeasonMillDeliveryType.NO_DELIVERY]: 'No entregado',
  [SeasonMillDeliveryType.PARTIAL]: 'Parcial',
  [SeasonMillDeliveryType.TOTAL]: 'Total',
}

const PRODUCTION_TYPE_NAME_MAP: { [key in ProductionType]: string } = {
  [ProductionType.CONVENTIONAL]: 'Convencional',
  [ProductionType.ORGANIC]: 'Bio',
}

const GRAIN_TYPE_NAME_MAP: { [key in GrainType]: string } = {
  [GrainType.SHORT]: 'Corto',
  [GrainType.CRYSTAL_MEDIUM]: 'Medio cristalino',
  [GrainType.PEARL_MEDIUM]: 'Medio perlado',
  [GrainType.LONG]: 'Largo fino',
  [GrainType.NO_TYPE]: 'Sin variedad asignada',
}

const DELIVERY_CONDITION_NAME_MAP: { [key in DeliveryCondition]: string } = {
  [DeliveryCondition.WET]: 'Húmedo',
  [DeliveryCondition.DRY]: 'Seco',
}

export const RICE_LOT = {
  STATUS_NAME_MAP,
  DELIVERY_STATUS_NAME_MAP,
  PRODUCTION_TYPE_NAME_MAP,
  GRAIN_TYPE_NAME_MAP,
  DELIVERY_CONDITION_NAME_MAP,
}

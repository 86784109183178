/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useLazyQuery } from '@apollo/client'
import { Button } from 'antd'
import styled from 'styled-components'

import { CropFilterContext, HistoricalSeasonFilterContext, RegionFilterContext } from 'contexts'
import { DetailsFiltersContext } from 'features/MainRouter/contexts'
import { MiscUtils } from 'utils'

import {
  OrganizationAnalyticsExport,
  OrganizationAnalyticsExportVariables,
  ORGANIZATION_ANALYTICS_EXPORT,
} from './api'

const StyledButton = styled(Button)`
  width: 156px;
  height: 47px;
  font-size: 17px;
  font-weight: 500;
`

export const ExportButton = () => {
  const {
    commercialExecutive,
    timeMode,
    subregion,
    futureDateRange,
    lotStatus,
    company,
    varietyId,
  } = DetailsFiltersContext.useDetailsFiltersContext()
  const { region } = RegionFilterContext.useRegionFilterContext()
  const { selectedCrop } = CropFilterContext.useCropFilterContext()
  const { selectedHistoricalSeason } =
    HistoricalSeasonFilterContext.useHistoricalSeasonFilterContext()

  const [getOrganizationAnalyticsData, { loading }] = useLazyQuery<
    OrganizationAnalyticsExport,
    OrganizationAnalyticsExportVariables
  >(ORGANIZATION_ANALYTICS_EXPORT, {
    onError: error => console.log(error),
    onCompleted: response => window.open(response.organizationAnalyticsExport.url),
  })
  return (
    <StyledButton
      loading={loading}
      onClick={() =>
        getOrganizationAnalyticsData({
          variables: {
            regionId: region?.id,
            subregionId: subregion?.id,
            commercialExecutiveId: commercialExecutive?.id,
            timeMode,
            status: lotStatus?.length ? lotStatus : [],
            companyId: company?.id,
            dateRange: futureDateRange,
            cropId: selectedCrop?.id!,
            seasonStartRange: selectedHistoricalSeason?.startRange,
            seasonEndRange: selectedHistoricalSeason?.endRange,
            varietyId: MiscUtils.formatVarietyId(varietyId),
          },
        })
      }
    >
      Exportar
    </StyledButton>
  )
}

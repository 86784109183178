/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type { Crops, CropsVariables, Crops_organization_crops as Crop } from './types/Crops'

export const CROPS = gql`
  query Crops(
    $commercialExecutiveId: Int
    $seasonStartRange: DateTime
    $seasonEndRange: DateTime
    $regionId: Int
    $subregionId: Int
  ) {
    organization {
      crops(
        commercialExecutiveId: $commercialExecutiveId
        seasonStartRange: $seasonStartRange
        seasonEndRange: $seasonEndRange
        regionId: $regionId
        subregionId: $subregionId
      ) {
        id
        locales {
          es
        }
        varieties {
          results {
            id
            locales {
              es
            }
          }
          withNoVarietyOption
        }
      }
    }
  }
`

import { useState } from 'react'

import { UserAvatar } from 'components'
import { useFeatures, usePermissions, useUser } from 'hooks'
import { OrganizationCrmModuleFlag, OrganizationFeatures } from 'types'

import {
  Container,
  FlexEndCol,
  FlexStartCol,
  NavLink,
  NavLogoRow,
  NavRow,
  StyledDashboardIcon,
  StyledDetailsIcon,
  StyledMapIcon,
  Text,
  Popover,
  ProfilePopoverContent,
  SideNavbarLogo,
  StyledSettingsIcon,
  StyledHarvestIcon,
  StyledSalesIcon,
} from './components'

export const SideNavbar = () => {
  const user = useUser()
  const { permissions } = usePermissions()
  const { organizationFeatures, featureFlags } = useFeatures()

  const [showPopover, setShowPopover] = useState(false)
  return (
    <Container>
      <FlexStartCol>
        <NavLogoRow>
          <SideNavbarLogo
            organizationLogo={user?.organizationRole?.organization?.logo ?? undefined}
          />
        </NavLogoRow>
        <NavRow>
          <NavLink to="/dashboard">
            <StyledDashboardIcon />
            <Text>Resumen</Text>
          </NavLink>
        </NavRow>
        <NavRow>
          <NavLink to="/details">
            <StyledDetailsIcon />
            <Text>Detalle</Text>
          </NavLink>
        </NavRow>
        <NavRow>
          <NavLink to="/map">
            <StyledMapIcon />
            <Text>Mapa</Text>
          </NavLink>
        </NavRow>
        {organizationFeatures?.includes(OrganizationFeatures.SUB_FARMERS_MANAGEMENT) && (
          <NavRow>
            <NavLink to="/harvest">
              <StyledHarvestIcon />
              <Text>Cosecha</Text>
            </NavLink>
          </NavRow>
        )}
        {featureFlags.crm?.flags.includes(OrganizationCrmModuleFlag.STOCK) &&
          permissions.isOrganizationAdmin && (
            <NavRow>
              <NavLink to="/sales">
                <StyledSalesIcon />
                <Text>Ventas</Text>
              </NavLink>
            </NavRow>
          )}
      </FlexStartCol>
      <FlexEndCol>
        {permissions.isOrganizationAdmin && (
          <NavRow>
            <NavLink to="/settings">
              <StyledSettingsIcon />
              <Text>Ajustes</Text>
            </NavLink>
          </NavRow>
        )}
        <Popover
          placement="right"
          trigger="click"
          onOpenChange={setShowPopover}
          open={showPopover}
          content={
            <ProfilePopoverContent
              name={user?.name}
              lastName={user?.lastName}
              profilePicture={user?.profilePicture}
            />
          }
        >
          <UserAvatar src={user?.profilePicture} cursor="pointer" />
        </Popover>
      </FlexEndCol>
    </Container>
  )
}

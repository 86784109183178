import { Select as AntSelect } from 'antd'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useMemo } from 'react'

import { SELECTS } from 'consts'
import { DateUtils, MiscUtils } from 'utils'
import { HistoricalSeasonFilterContext } from 'contexts'

import { OrganizationCompany } from './api'
import { useFetchOrganizationCompanies } from './hooks'

interface Props {
  onChange: (organizationCompany?: OrganizationCompany) => void
  value?: number
  selectedCommercialExecutiveId?: number
}

export const Select = styled(AntSelect)`
  .ant-select-selector {
    width: 230px !important;
  }
  .ant-select-clear {
    color: white;
  }
  color: white;
` as typeof AntSelect

export const CompaniesSelect: React.FC<Props> = ({
  onChange,
  value,
  selectedCommercialExecutiveId,
}) => {
  const { selectedHistoricalSeason } =
    HistoricalSeasonFilterContext.useHistoricalSeasonFilterContext()

  const { organizationCompanies, loading } = useFetchOrganizationCompanies()

  const filteredCompaniesBySelectedSeason = useMemo(() => {
    if (!selectedHistoricalSeason?.startRange || !selectedHistoricalSeason?.endRange) {
      return organizationCompanies
    }

    const selectedHistoricalSeasonStartRangeDate = dayjs.utc(selectedHistoricalSeason.startRange)
    const selectedHistoricalSeasonEndRangeDate = dayjs.utc(selectedHistoricalSeason.endRange)

    const filteredCompanies = organizationCompanies?.filter(({ seasons }) =>
      seasons?.some(season => {
        const seasonStartDate = dayjs.utc(season.startDate)

        return DateUtils.isWithinInterval(
          seasonStartDate,
          selectedHistoricalSeasonStartRangeDate,
          selectedHistoricalSeasonEndRangeDate,
        )
      }),
    )

    const existSelectedCompanyInSeason = filteredCompanies?.find(company => company.id === value)
    if (!existSelectedCompanyInSeason) onChange(undefined)

    return filteredCompanies
  }, [organizationCompanies, selectedHistoricalSeason, onChange, value])

  const companiesOptions = filteredCompaniesBySelectedSeason?.map(organizationCompany => ({
    value: organizationCompany.id,
    label: organizationCompany.name,
    commercialExecutiveId: organizationCompany.seasons?.[0]?.commercialExecutive?.id,
  }))

  const companiesOptionsFilterByCommercialExecutiveId = companiesOptions?.filter(
    organizationCompany =>
      organizationCompany.commercialExecutiveId === selectedCommercialExecutiveId,
  )

  return (
    <Select
      loading={loading}
      value={value}
      allowClear
      showSearch
      filterOption={(input, option) => {
        if (!option) return false
        return MiscUtils.removeAccents(option.label.toLowerCase()).includes(
          input.toLocaleLowerCase(),
        )
      }}
      placeholder="Sin seleccionar"
      options={
        selectedCommercialExecutiveId
          ? companiesOptionsFilterByCommercialExecutiveId
          : companiesOptions
      }
      notFoundContent="No hay coincidencia"
      onChange={organizationCompanyId => {
        const selectedOrganizationCompany = filteredCompaniesBySelectedSeason?.find(
          organizationCompany => organizationCompany.id === organizationCompanyId,
        )
        onChange(
          organizationCompanyId === SELECTS.DEFAULT_OPTION_ID
            ? undefined
            : selectedOrganizationCompany,
        )
      }}
    />
  )
}

import { sumBy, forEach } from 'lodash'
import { useState } from 'react'

import { DashboardFiltersContext } from 'features/MainRouter/contexts'
import {
  CropFilterContext,
  HistoricalSeasonFilterContext,
  RegionFilterContext,
  VarietyFilterContext,
} from 'contexts'
import { useOrganizationAnalyticsLots, useUser } from 'hooks'
import { AnalyticsTimeMode } from 'types'
import { MiscUtils, NumberUtils } from 'utils'
import { RICE_LOT } from 'consts'

import { DataByStatus } from '../types'

export const useStatusStatsCard = () => {
  const user = useUser()
  const { commercialExecutive, subregion, company } =
    DashboardFiltersContext.useDashboardFiltersContext()
  const { selectedHistoricalSeason } =
    HistoricalSeasonFilterContext.useHistoricalSeasonFilterContext()

  const [timeMode, setTimeMode] = useState<AnalyticsTimeMode>(AnalyticsTimeMode.CURRENT)

  const { region } = RegionFilterContext.useRegionFilterContext()
  const { selectedCrop } = CropFilterContext.useCropFilterContext()
  const { selectedVarietyId } = VarietyFilterContext.useVarietyFilterContext()

  const { data, loading } = useOrganizationAnalyticsLots({
    regionId: region?.id,
    subregionId: subregion?.id,
    commercialExecutiveId: commercialExecutive?.id,
    timeMode,
    riceLotStatus: user?.organizationRole?.organization?.statusMilestones ?? [],
    companyId: company?.id,
    cropId: selectedCrop?.id,
    seasonStartRange: selectedHistoricalSeason?.startRange,
    seasonEndRange: selectedHistoricalSeason?.endRange,
    varietyId: MiscUtils.formatVarietyId(selectedVarietyId),
  })

  const lotsQty = data?.organizationAnalytics.lots.length ?? 0
  const haQty = NumberUtils.roundedNumber(sumBy(data?.organizationAnalytics.lots, 'lot.size')) ?? 0

  const organizationStatusMilestones = user?.organizationRole?.organization?.statusMilestones

  const haDataByStatus: DataByStatus[] =
    organizationStatusMilestones?.map(statusMilestone => {
      return {
        name: RICE_LOT.STATUS_NAME_MAP[statusMilestone],
        values: { AUTOMATIC_CONVERSION: 0, AUTOMATIC_SENTINEL1: 0, MANUAL: 0 },
      }
    }) ?? []

  const lotsDataByStatus: DataByStatus[] =
    organizationStatusMilestones?.map(statusMilestone => {
      return {
        name: RICE_LOT.STATUS_NAME_MAP[statusMilestone],
        values: { AUTOMATIC_CONVERSION: 0, AUTOMATIC_SENTINEL1: 0, MANUAL: 0 },
      }
    }) ?? []

  forEach(data?.organizationAnalytics.lots, lot => {
    if (!lot.status.estimated || !lot.status.estimated.updateType) return
    const key = RICE_LOT.STATUS_NAME_MAP[lot.status.estimated.status]
    const index = lotsDataByStatus.findIndex(lotStatus => lotStatus.name === key)
    haDataByStatus[index] = {
      ...haDataByStatus[index],
      values: {
        ...haDataByStatus[index].values,
        [lot.status.estimated.updateType]: haDataByStatus[index].values[
          lot.status.estimated.updateType
        ]
          ? haDataByStatus[index].values[lot.status.estimated.updateType]! +
            NumberUtils.roundedNumber(lot.lot.size)
          : NumberUtils.roundedNumber(lot.lot.size),
      },
    }
    lotsDataByStatus[index] = {
      ...lotsDataByStatus[index],
      values: {
        ...lotsDataByStatus[index].values,
        [lot.status.estimated.updateType]: lotsDataByStatus[index].values[
          lot.status.estimated.updateType
        ]
          ? lotsDataByStatus[index].values[lot.status.estimated.updateType]! + 1
          : 1,
      },
    }
  })

  return {
    lotsQty,
    haQty,
    lotsDataByStatus,
    haDataByStatus,
    setTimeMode,
    loading,
  }
}

import { Row, Space, Typography } from 'antd'
import styled from 'styled-components'

import { FormInput as BaseInput } from 'components'

const { Text } = Typography

export const InputTitle = styled(Text)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.baseColor[600]};
`

export const StyledRow = styled(Row)`
  display: flex;
  column-gap: 25px;
`

export const StyledCol = styled(Space).attrs({
  direction: 'vertical',
  size: 5,
})``

export const FormInput = styled(BaseInput)`
  height: 55px;
  background: ${({ theme }) => theme.colors.baseColor[900]} !important;
  color: ${({ theme }) => theme.colors.white};
  max-width: 600px;
`

import { Col, Layout, Row, Typography } from 'antd'
import styled from 'styled-components'

const { Header: AntHeader } = Layout
const { Title: AntTitle } = Typography

interface Props {
  items?: JSX.Element[]
  title: string
}

const StyledHeader = styled(AntHeader)`
  background: ${({ theme }) => theme.colors.baseColor[700]};
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 50px 0px 50px;
`

const ItemsContainer = styled(Row)`
  column-gap: 12px;
`

const Title = styled(AntTitle)`
  margin: 0 !important;
  color: ${({ theme }) => theme.colors.white} !important;
  font-weight: 700;
  line-height: 29px;
`
export const Header: React.FC<Props> = ({ items, title }) => {
  return (
    <StyledHeader>
      <Title level={3}>{title}</Title>
      <ItemsContainer>
        {items?.map((item, index) => (
          <Col key={index}>{item}</Col>
        ))}
      </ItemsContainer>
    </StyledHeader>
  )
}

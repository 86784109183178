export const Colors = {
  primaryGradient: 'linear-gradient(262.84deg, #FFA370 2.21%, #FF7C33 100.04%)',
  primary: {
    100: '#FFB085',
    200: '#FFA370',
    300: '#FF965C',
    400: '#FF8947',
    500: '#FF7C33',
    600: '#E6702E',
    700: '#CC6329',
    800: '#B35724',
    900: '#994A1F',
  },
  secondary: {
    100: '#80F8CD',
    200: '#66F7C3',
    300: '#4CF6B9',
    400: '#33F5AF',
    500: '#00F29B',
    600: '#00DA8C',
    700: '#00C27C',
    800: '#00A96D',
    900: '#00915D',
  },
  baseColor: {
    50: '#F4F4F5',
    100: '#E9EAEB',
    200: '#D3D4D6',
    300: '#BDBFC2',
    400: '#A7AAAE',
    500: '#7C7F85',
    600: '#666A71',
    700: '#50555D',
    800: '#3A3F48',
    850: '#2F353E',
    900: '#242A34',
  },
  white: 'white',
  black: 'black',
  transparent: 'transparent',
  bodyBackground: '#F5F5F5',
  iconColor: '#DDDFDD',
  error: '#EA5642',
  warning: '#F2AE00',
  success: '#00C27C',
  lotStatus: {
    PREPARATION: '#666A71',
    BURNING: '#FFA370',
    PLANTING: '#66F7C3',
    EMERGENCE: '#00C27C',
    DIFFERENTIATION: '#FFA370',
    FLOWERING: '#F2AE00',
    EAR_APPEARANCE: '#F2AE00',
    PHYSIOLOGICAL_MATURITY: '#994A1F',
    HARVEST: '#994A1F',
    INITIAL_FLOOD: '#1F2CDB',
    PRE_EAR_APPEARANCE: '#F2CB00',
    START_IRRIGATION: '#00A6F5',
    END_IRRIGATION: '#006EF5',
    VEGETATIVE_STATE_THREE: '#67F200',
  },
  milestoneUpdateType: {
    MANUAL: '#00DA8C',
    AUTOMATIC_CONVERSION: '#C684FA',
    AUTOMATIC_SENTINEL1: '#84CFFA',
  },
}

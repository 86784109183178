import { Col, Typography } from 'antd'
import styled from 'styled-components'

export { Chart } from './Chart'

export const LoaderContainer = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ChartContainer = styled(Col)`
  height: 300px;
  margin-top: 45px;
`

export const ManualMilestoneUpdateBall = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.colors.milestoneUpdateType.MANUAL};
  border-radius: 50%;
`

export const AutomaticMilestoneUpdateBall = styled(ManualMilestoneUpdateBall)`
  background-color: ${({ theme }) => theme.colors.milestoneUpdateType.AUTOMATIC_CONVERSION};
`

export const SatelliteMilestoneUpdateBall = styled(ManualMilestoneUpdateBall)`
  background-color: ${({ theme }) => theme.colors.milestoneUpdateType.AUTOMATIC_SENTINEL1};
`

export const LegendText = styled(Typography.Text)`
  font-size: 12px;
`

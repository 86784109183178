import { Layout } from 'antd'

import { Content, Header } from './components'

interface Props {
  headerItems?: JSX.Element[]
  headerTitle: string
  children: React.ReactNode
}

export const Body: React.FC<Props> = ({ headerTitle, headerItems, children }) => {
  return (
    <Layout>
      <Header title={headerTitle} items={headerItems} />
      <Content>{children}</Content>
    </Layout>
  )
}

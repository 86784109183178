import { Select as AntSelect } from 'antd'
import styled from 'styled-components'
import { useMemo } from 'react'

import { useRegions } from 'hooks'

const Select = styled(AntSelect)`
  .ant-select {
    width: 100% !important;
  }
  .ant-select-selector {
    width: 100% !important;
  }
` as typeof AntSelect

interface Props {
  onChange?: (regionId: number) => void
  value?: number
  disabled?: boolean
  width?: string
  maxWidth?: string
  includeDefaultValue?: boolean
}

// When region is not selected we can assign value 0 to show 'Todas las zonas' in placeholder
const defaultValue = { value: 0, label: 'Todas las zonas' }

export const RegionsSelect: React.FC<Props> = ({
  onChange,
  value,
  disabled,
  width,
  includeDefaultValue = true,
}) => {
  const { loading, regionsOptions } = useRegions()

  const options = useMemo(() => {
    if (includeDefaultValue) {
      return [defaultValue, ...(regionsOptions ?? [])]
    }
    return regionsOptions
  }, [includeDefaultValue, regionsOptions])

  return (
    <Select
      style={{ width: width ?? '230px' }}
      placeholder="Seleccionar zona"
      onChange={onChange}
      value={value}
      defaultValue={value}
      loading={loading}
      disabled={disabled}
      options={options}
    />
  )
}

import { Row } from 'antd'
import styled from 'styled-components'
export { DrawToolbar } from './DrawToolbar'

export const Container = styled(Row)`
  max-width: 500px;
  position: absolute;
  top: 40px;
  left: 150px;
  z-index: 10;
`

export const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
`

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export const useOrganizationForm = () => {
  const validationSchema = yup.object().shape({
    name: yup.string().required('Campo requerido'),
  })

  return useForm({ resolver: yupResolver(validationSchema) })
}

import styled from 'styled-components'
import { Col } from 'antd'

export { MeasureAreaDropdown } from './MeasureAreaDropdown'

interface Props {
  $isActive?: boolean
}

export const IconContainer = styled(Col)<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.colors.white : theme.colors.baseColor[400]};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  background-color: ${({ theme }) => theme.colors.baseColor[900]};
`

import { useContext, useState, createContext, useEffect } from 'react'

import { CommercialExecutive } from 'hooks/useCommercialExecutives/api'
import { AnalyticsTimeMode, RiceLotStatus } from 'types'
import { Subregion } from 'components/SubregionsSelect/api'
import { OrganizationCompany } from 'components/CompaniesSelect/api'
import { useUser } from 'hooks'

interface MapFiltersContextType {
  commercialExecutive?: CommercialExecutive
  setCommercialExecutive: (commercialExecutive?: CommercialExecutive) => void
  timeMode: AnalyticsTimeMode
  setTimeMode: (timeMode: AnalyticsTimeMode) => void
  lotStatus?: RiceLotStatus[]
  setLotStatus: (lotStatus: RiceLotStatus[]) => void
  subregion?: Subregion
  setSubregion: (subregion?: Subregion) => void
  company?: OrganizationCompany
  setCompany: (company?: OrganizationCompany) => void
}

const Context = createContext<MapFiltersContextType>({
  commercialExecutive: undefined,
  setCommercialExecutive: () => {
    throw new Error(
      'Attempted to use default setCommercialExecutive function for MapFiltersContext, make sure to pass a custom function implementation',
    )
  },
  timeMode: AnalyticsTimeMode.CURRENT,
  setTimeMode: () => {
    throw new Error(
      'Attempted to use default setTime function for MapFiltersContext, make sure to pass a custom function implementation',
    )
  },
  lotStatus: undefined,
  setLotStatus: () => {
    throw new Error(
      'Attempted to use default setLotStatus function for MapFiltersContext, make sure to pass a custom function implementation',
    )
  },
  subregion: undefined,
  setSubregion: () => {
    throw new Error(
      'Attempted to use default setSubregion function for MapFiltersContext, make sure to pass a custom function implementation',
    )
  },
  company: undefined,
  setCompany: () => {
    throw new Error(
      'Attempted to use default setCompany function for MapFiltersContext, make sure to pass a custom function implementation',
    )
  },
})

const useValue = (): MapFiltersContextType => {
  const [subregion, setSubregion] = useState<Subregion>()
  const [commercialExecutive, setCommercialExecutive] = useState<CommercialExecutive | undefined>()
  const [timeMode, setTimeMode] = useState<AnalyticsTimeMode>(AnalyticsTimeMode.CURRENT)
  const [lotStatus, setLotStatus] = useState<RiceLotStatus[]>([])
  const [company, setCompany] = useState<OrganizationCompany>()

  const user = useUser()

  useEffect(() => {
    if (!user) return
    const organizationStatusMilestones = user?.organizationRole?.organization?.statusMilestones
    if (organizationStatusMilestones) {
      setLotStatus(organizationStatusMilestones)
    }
  }, [user])

  return {
    commercialExecutive,
    setCommercialExecutive,
    timeMode,
    setTimeMode,
    lotStatus,
    setLotStatus,
    subregion,
    setSubregion,
    company,
    setCompany,
  }
}

const useMapFiltersContext = () => {
  return useContext(Context)
}

export const MapFiltersContext = {
  Provider: Context.Provider,
  useValue,
  useMapFiltersContext,
}

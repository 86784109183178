const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

export const getMonths = (numberOfMonths: number) => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()

  return Array.from({ length: numberOfMonths + 1 }, (_, index) => {
    const monthIndex = (currentMonth - 1 + index + 12) % 12
    const year = currentYear + Math.floor((currentMonth - 1 + index) / 12)
    const monthValue = (monthIndex + 1).toString().padStart(2, '0')

    return {
      title: months[monthIndex],
      dataIndex: `${year}-${monthValue}`,
    }
  })
}

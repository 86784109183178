import ReactMapGL, { Layer, Source, ViewStateChangeEvent } from 'react-map-gl'
import React, { useCallback } from 'react'
import { useTheme } from 'styled-components'
import { polygon } from '@turf/helpers'

import { MAP } from 'consts'

import {
  Container,
  Control,
  DrawnAreasMarker,
  LeftSideBar,
  LotPopup,
  RightSideBar,
  LotsLoader,
} from './components'
import { useClusters, useDrawingTool, useMap, useTools } from './hooks'

export const Map = () => {
  const { colors } = useTheme()
  const { toolMode, setToolMode } = useTools()
  const {
    showDropdown,
    drawingOption,
    drawMode,
    drawnAreas,
    drawnAreasFeatures,
    toggleDropdownVisibility,
    onCreateFeature,
    onUpdateFeature,
    onDeleteFeature,
    onDrawingOptionSelected,
  } = useDrawingTool(toolMode, setToolMode)

  const {
    onHandleClickMap,
    cursor,
    popupInfo,
    onMouseEnter,
    onMouseLeave,
    lots,
    interactiveLayerIds,
    showPopupInfo,
    coordinates,
    loading,
    setCoordinates,
    onCenterLots,
    commercialExecutiveId,
    lotsWithoutArea,
  } = useMap(drawingOption)

  const { clusterCountLayer, clusterLayer, clusters, unclusteredPointLayer } = useClusters(lots)

  const statusColor: {
    [key: string]: string
  } = colors.lotStatus

  const onMove = useCallback(
    (evt: ViewStateChangeEvent) =>
      setCoordinates(prevState => {
        return {
          ...prevState,
          latitude: evt.viewState.latitude,
          longitude: evt.viewState.longitude,
          zoom: evt.viewState.zoom,
        }
      }),
    [setCoordinates],
  )

  return (
    <Container>
      {loading && <LotsLoader />}
      <LeftSideBar
        toolMode={toolMode}
        drawingOption={drawingOption}
        isDrawingToolAvailable={!drawnAreas.length}
        showDrawingToolDropdown={showDropdown}
        onDrawingToolDropdownVisibleChange={toggleDropdownVisibility}
        onDrawingOptionSelected={onDrawingOptionSelected}
        commercialExecutiveId={commercialExecutiveId}
      />
      <RightSideBar
        lotsQuantity={lots.length}
        lotsWithoutAreaQuantity={lotsWithoutArea.length}
        onCenterLots={onCenterLots}
      />
      <ReactMapGL
        id="lotsMap"
        onMove={onMove}
        latitude={coordinates.latitude}
        longitude={coordinates.longitude}
        zoom={coordinates.zoom}
        onClick={onHandleClickMap}
        cursor={drawMode === 'draw_polygon' ? 'crosshair' : cursor}
        mapStyle={MAP.STYLES.SATELLITE_STREET}
        interactiveLayerIds={
          popupInfo ? undefined : [...interactiveLayerIds, MAP.CLUSTERS.UNCLUSTERED_POINT_ID]
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Control
          features={drawnAreasFeatures}
          mode={drawMode}
          drawingOption={drawingOption}
          onCreate={onCreateFeature}
          onUpdate={onUpdateFeature}
          onDeleteFeature={onDeleteFeature}
        />

        {lots.map(({ lot, status }) => {
          return (
            <React.Fragment key={lot.id}>
              <Source type="geojson" id={lot.id?.toString()} data={polygon(lot.area!.coordinates)}>
                <Layer
                  id={lot.id!.toString()}
                  type="fill"
                  paint={{
                    'fill-color': status.estimated
                      ? statusColor[status.estimated.status]
                      : undefined,
                    'fill-opacity': 0.5,
                  }}
                  minzoom={MAP.ZOOM.LIMIT}
                />
                <Layer
                  id={`lot-${lot.id}`}
                  type="line"
                  paint={{
                    'line-color': colors.black,
                    'line-width': 2,
                  }}
                  minzoom={MAP.ZOOM.LIMIT}
                />
              </Source>
              <Source
                id={MAP.CLUSTERS.SOURCE_ID}
                type="geojson"
                data={clusters}
                cluster
                clusterRadius={20}
                maxzoom={MAP.ZOOM.LIMIT}
              >
                <Layer {...clusterLayer} maxzoom={MAP.ZOOM.LIMIT} />
                <Layer {...clusterCountLayer} maxzoom={MAP.ZOOM.LIMIT} />
                <Layer {...unclusteredPointLayer} maxzoom={MAP.ZOOM.LIMIT} />
              </Source>

              {showPopupInfo && popupInfo && <LotPopup {...popupInfo} />}
            </React.Fragment>
          )
        })}
        <DrawnAreasMarker drawingOption={drawingOption} drawnAreas={drawnAreas} />
      </ReactMapGL>
    </Container>
  )
}

/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export type {
  HarvestedCalendar,
  HarvestedCalendarVariables,
  HarvestedCalendar_organizationAnalytics_lots as HarvestedCalendarLots,
} from './types/HarvestedCalendar'

export const HARVESTED_CALENDAR = gql`
  query HarvestedCalendar(
    $regionId: Int
    $subregionId: Int
    $commercialExecutiveId: Int
    $timeMode: AnalyticsTimeMode
    $status: [RiceLotStatus!]
    $dateRange: DateRangeInput
    $companyId: Int
    $cropId: Int!
    $varietyId: Int
    $seasonStartRange: DateTime
    $seasonEndRange: DateTime
    $grainType: GrainType
    $deliveryCondition: DeliveryCondition
    $productionType: ProductionType
  ) {
    organizationAnalytics(
      regionId: $regionId
      subregionId: $subregionId
      commercialExecutiveId: $commercialExecutiveId
      timeMode: $timeMode
      status: $status
      dateRange: $dateRange
      companyId: $companyId
      cropId: $cropId
      varietyId: $varietyId
      seasonStartRange: $seasonStartRange
      seasonEndRange: $seasonEndRange
      grainType: $grainType
      deliveryCondition: $deliveryCondition
      productionType: $productionType
    ) {
      lots {
        lot {
          id
          name
          productionType
          deliveryCondition
          riceLot {
            estimatedYield
            harvestDate
          }
          size
          variety {
            id
            grainType
            locales {
              es
            }
          }
          crop {
            id
            locales {
              es
            }
          }
          seasonField {
            field {
              id
              name
            }
            season {
              company {
                name
              }
            }
          }
        }
      }
    }
  }
`

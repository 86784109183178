import { Layout as AntLayout } from 'antd'
import styled from 'styled-components'

export { Header } from './Header'

export const Content = styled(AntLayout.Content)`
  background-color: ${({ theme }) => theme.colors.bodyBackground};
  overflow: auto;
  padding: ${({ theme }) => `calc(${theme.foundation.mainPadding} / 2)`};
  @media (min-width: 1280px) {
    padding: ${({ theme }) => theme.foundation.mainPadding};
  }
  @media (min-width: 1600px) {
    padding: ${({ theme }) => `55px calc(${theme.foundation.mainPadding} * 2)`};
  }
`

import styled from 'styled-components'

interface BallProps {
  $background: string
}
export const StyledBall = styled.div<BallProps>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ $background }) => $background};
`

const BallContainer = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`

interface Props {
  background: string
}

export const Ball: React.FC<Props> = ({ background }) => {
  return (
    <BallContainer>
      <StyledBall $background={background} />
    </BallContainer>
  )
}

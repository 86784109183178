import { Select } from 'antd'

import { DeliveryCondition, SelectValueType, Selects } from 'types'
import { RICE_LOT } from 'consts'

interface Props {
  onChange: (deliveryCondition: SelectValueType<DeliveryCondition>) => void
  value?: SelectValueType<DeliveryCondition>
}

const { Option } = Select

const deliveryConditions = [DeliveryCondition.DRY, DeliveryCondition.WET]

export const DeliveryConditionSelect: React.FC<Props> = ({ onChange, value }) => {
  const deliveryConditionOptions =
    deliveryConditions.map(deliveryCondition => ({
      label: RICE_LOT.DELIVERY_CONDITION_NAME_MAP[deliveryCondition],
      value: deliveryCondition,
    })) ?? []

  return (
    <Select
      listHeight={300}
      placeholder="Sin seleccionar..."
      notFoundContent={'No hay coincidencia'}
      value={value ?? Selects.ALL_OPTIONS}
      onChange={onChange}
    >
      <Option value={Selects.ALL_OPTIONS}>Total</Option>
      {deliveryConditionOptions.map(deliveryCondition => (
        <Option key={deliveryCondition.value} value={deliveryCondition.value}>
          {deliveryCondition.label}
        </Option>
      ))}
    </Select>
  )
}

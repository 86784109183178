import { orderBy } from 'lodash'

import { NumberUtils } from 'utils'

import { OrganizationAnalyticsSummaryVariety } from './api'
import { useFetchOrganizationAnalyticsSummary } from './hooks'
import { DataObject } from '../../types'

const MAX_VARIETIES_IN_CHART = 6

export const useOrganizationAnalyticsSummary = () => {
  const {
    commercialExecutives,
    companies,
    varieties,
    totals,
    loading,
    totalLotsWithEmergenceDate,
    totalSizeWithEmergenceDate,
  } = useFetchOrganizationAnalyticsSummary()

  const lotsQty = totals?.lots ?? 0
  const haQty = totals?.size ? NumberUtils.roundedNumber(totals.size) : 0

  const handleChartData = (
    organizationAnalyticsSummaryVarieties?: OrganizationAnalyticsSummaryVariety[],
  ): DataObject[] => {
    if (!organizationAnalyticsSummaryVarieties) return []

    if (organizationAnalyticsSummaryVarieties.length <= MAX_VARIETIES_IN_CHART) {
      return (
        varieties?.map(variety => {
          return {
            name: variety.variety?.locales.es ?? 'Otros',
            lots: variety.totals.lots,
            size: NumberUtils.roundedNumber(variety.totals.size),
          }
        }) ?? []
      )
    }

    const orderedVarieties = orderBy(
      organizationAnalyticsSummaryVarieties,
      ['totals.size'],
      ['desc'],
    )

    const reduced = orderedVarieties?.reduce(
      (accumulator: DataObject, { variety, totals: totalsVariety }, index) => {
        // We assign 0 when the lot hasn't variety, assuming the ids of the varieties always start at 1
        const key = variety?.id && index < MAX_VARIETIES_IN_CHART ? variety?.id : 0
        // eslint-disable-next-line no-param-reassign
        accumulator[key] = accumulator[key] ?? {
          name:
            variety?.locales.es && index < MAX_VARIETIES_IN_CHART ? variety.locales.es : 'Otros',
          lots: 0,
          size: 0,
        }
        // eslint-disable-next-line no-param-reassign
        accumulator[key].lots += totalsVariety.lots
        // eslint-disable-next-line no-param-reassign
        accumulator[key].size += NumberUtils.roundedNumber(totalsVariety.size)
        return accumulator
      },
      {},
    )
    const result = Object.values(reduced ?? '')

    const orderedResult = orderBy(result, ['size'], ['desc'])

    return orderedResult
  }

  return {
    loading,
    varieties,
    commercialExecutives: commercialExecutives ?? [],
    companies: companies ?? [],
    lotsQty,
    haQty,
    varietiesChartData: handleChartData(varieties),
    emergenceDatesChartData: [
      { value: totalLotsWithEmergenceDate },
      { value: lotsQty - totalLotsWithEmergenceDate },
    ],
    emergenceSizeDatesChartData: [
      { value: totalSizeWithEmergenceDate },
      { value: haQty - totalSizeWithEmergenceDate },
    ],
  }
}

import dayjs from 'dayjs'

import { DATE_FORMATS, RICE_LOT, UNITS } from 'consts'
import { NumberUtils } from 'utils'

import { Props } from '../../../types'

export const useResultsCard = ({ data }: Props) => {
  const tableData = data?.organizationAnalytics.lots.map(({ lot, status }) => {
    const governmentId = lot.seasonField?.season?.company.governmentId
    return {
      id: lot.id,
      governmentId: governmentId ? NumberUtils.localize(Number(governmentId)) : '-',
      companyName: lot.seasonField?.season?.company.name ?? '-',
      lotName: lot.name,
      fieldName: lot.seasonField?.field?.name ?? '-',
      commercialName: lot.commercialName,
      variety: lot.variety?.locales.es,
      crop: lot.crop.locales.es,
      area: lot.size.toFixed(),
      status: RICE_LOT.STATUS_NAME_MAP[status.current.status],
      date: dayjs(status.current.date).format(DATE_FORMATS.FULL_DATE),
      commercialExecutive: `${lot.seasonField?.season?.commercialExecutive?.name} ${lot.seasonField?.season?.commercialExecutive?.lastName}`,

      estimatedProduction: NumberUtils.roundedNumber(
        lot.riceLot.estimatedYield ? lot.riceLot.estimatedYield * UNITS.KG_TONS_RATIO : 0,
      ),
      harvestedProduction: NumberUtils.roundedNumber(
        lot.riceLot.yield ? lot.riceLot.yield * UNITS.KG_TONS_RATIO : 0,
      ),
      deliveryType: lot.seasonField?.season?.millDeliveryType
        ? RICE_LOT.DELIVERY_STATUS_NAME_MAP[lot.seasonField.season.millDeliveryType]
        : '-',
    }
  })

  return {
    tableData,
  }
}

import { gql } from '@apollo/client'

export type { User, UserVariables } from './types/User'

export const USER = gql`
  query User($id: Int!) {
    user(id: $id) {
      id
      name
      lastName
      email
      profilePicture
      organizationRole {
        role
        organization {
          id
          name
          logo
          featureSetId
          allZonesImage
          statusMilestones
          featureFlags {
            general {
              flags
            }
            crm {
              enabled
              flags
            }
            subFarmersManagement {
              enabled
            }
            supply {
              enabled
            }
          }
          isCommercialNameEnabled
          country {
            id
          }
        }
      }
    }
  }
`

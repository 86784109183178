/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useQuery } from '@apollo/client'

import {
  CropFilterContext,
  HistoricalSeasonFilterContext,
  RegionFilterContext,
  VarietyFilterContext,
} from 'contexts'
import { DashboardFiltersContext } from 'features/MainRouter/contexts'
import { MiscUtils } from 'utils'

import {
  ORGANIZATION_CURRENT_ANALYTICS_SUMMARY,
  OrganizationCurrentAnalyticsSummary,
  OrganizationCurrentAnalyticsSummaryVariables,
} from '../api'

export const useFetchOrganizationAnalyticsSummary = () => {
  const { region } = RegionFilterContext.useRegionFilterContext()
  const { selectedCrop } = CropFilterContext.useCropFilterContext()
  const { subregion, commercialExecutive, company } =
    DashboardFiltersContext.useDashboardFiltersContext()
  const { selectedHistoricalSeason } =
    HistoricalSeasonFilterContext.useHistoricalSeasonFilterContext()
  const { selectedVarietyId } = VarietyFilterContext.useVarietyFilterContext()

  const { data, loading } = useQuery<
    OrganizationCurrentAnalyticsSummary,
    OrganizationCurrentAnalyticsSummaryVariables
  >(ORGANIZATION_CURRENT_ANALYTICS_SUMMARY, {
    variables: {
      regionId: region?.id,
      subregionId: subregion?.id,
      commercialExecutiveId: commercialExecutive?.id,
      companyId: company?.id,
      cropId: selectedCrop?.id!,
      seasonStartRange: selectedHistoricalSeason?.startRange,
      seasonEndRange: selectedHistoricalSeason?.endRange,
      varietyId: MiscUtils.formatVarietyId(selectedVarietyId),
    },
    skip: !selectedCrop?.id,
  })

  return {
    loading,
    varieties: data?.organizationCurrentAnalyticsSummary.varieties,
    commercialExecutives: data?.organizationCurrentAnalyticsSummary.commercialExecutives,
    companies: data?.organizationCurrentAnalyticsSummary.companies,
    totals: data?.organizationCurrentAnalyticsSummary.totals,
    totalLotsWithEmergenceDate:
      data?.organizationCurrentAnalyticsSummary.totalLotsWithEmergenceDate ?? 0,
    totalSizeWithEmergenceDate:
      data?.organizationCurrentAnalyticsSummary.totalSizeWithEmergenceDate ?? 0,
  }
}

import { Col, Row, Space } from 'antd'

import { CardSection, TimeSelect, Loader } from 'components'
import { ItemInfo } from 'features/MainRouter/components'
import { NumberUtils } from 'utils'

import {
  Chart,
  ChartContainer,
  LoaderContainer,
  ManualMilestoneUpdateBall,
  SatelliteMilestoneUpdateBall,
  AutomaticMilestoneUpdateBall,
  LegendText,
} from './components'
import { useStatusStatsCard } from './hooks'

const updateTypes = [
  { text: 'Fecha manual', ball: <ManualMilestoneUpdateBall /> },
  { text: 'Fecha por satélite', ball: <SatelliteMilestoneUpdateBall /> },
  { text: 'Fecha estimada', ball: <AutomaticMilestoneUpdateBall /> },
]

export const StatusStatsCard = () => {
  const { lotsQty, haQty, lotsDataByStatus, haDataByStatus, setTimeMode, loading } =
    useStatusStatsCard()

  return (
    <CardSection title="Lotes y hectáreas por estado">
      <Row gutter={[0, 45]}>
        <Col span={24}>
          <Row justify="space-between">
            <TimeSelect onChange={setTimeMode} />
            <Space size={24}>
              {updateTypes.map(({ text, ball }) => (
                <Row gutter={8} align="middle" key={text}>
                  <Col>{ball}</Col>
                  <Col>
                    <LegendText>{text}</LegendText>
                  </Col>
                </Row>
              ))}
            </Space>
          </Row>
        </Col>
        <Col flex={1}>
          {loading ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <Row gutter={[16, 16]}>
              <Col xs={24} xl={12}>
                <Row align="middle" justify="center">
                  <ItemInfo
                    title="Total lotes"
                    data={NumberUtils.localize(lotsQty)}
                    verticalSpacing={7}
                    horizontalSpacing={16}
                  />
                </Row>
                <ChartContainer flex="auto">
                  <Chart data={lotsDataByStatus} />
                </ChartContainer>
              </Col>
              <Col xs={24} xl={12}>
                <Row align="middle" justify="center">
                  <ItemInfo
                    title="Total hectáreas"
                    data={NumberUtils.localize(haQty)}
                    verticalSpacing={7}
                    horizontalSpacing={16}
                  />
                </Row>
                <ChartContainer>
                  <Chart data={haDataByStatus} />
                </ChartContainer>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </CardSection>
  )
}

import MapboxDraw from '@mapbox/mapbox-gl-draw'
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect'
import { useControl } from 'react-map-gl'
import { useCallback, useEffect } from 'react'

import { useEditorStyles } from './hooks'
import { DrawingOption, DrawMode, Features } from '../../types'

interface Props {
  mode: Exclude<DrawMode, 'direct_select'>
  features: Features
  drawingOption: DrawingOption
  onCreate: (evt: { features: Features }) => void
  onUpdate: (evt: { features: Features }) => void
  onDeleteFeature: (featureId: string) => void
}

export const Control: React.FC<Props> = ({
  mode,
  features,
  drawingOption,
  onCreate,
  onUpdate,
  onDeleteFeature,
}) => {
  const styles = useEditorStyles()

  const onSelectionChange = useCallback(
    ({ features: selectionFeatures }: { features: Features }) => {
      if (selectionFeatures?.length === 1 && selectionFeatures[0].id) {
        onDeleteFeature(selectionFeatures[0].id as string)
      }
    },
    [onDeleteFeature],
  )

  const control = useControl(
    ({ map }) => {
      map.on('draw.create', (evt: { features: Features }) => onCreate(evt))
      map.on('draw.update', (evt: { features: Features }) => onUpdate(evt))
      map.on('draw.selectionchange', onSelectionChange)
      return new MapboxDraw({ displayControlsDefault: false, styles })
    },
    ({ map }) => {
      map.off('draw.create', (evt: { features: Features }) => onCreate(evt))
      map.off('draw.update', (evt: { features: Features }) => onUpdate(evt))
      map.off('draw.selectionchange', onSelectionChange)
    },
  )
  const drawControl = control as MapboxDraw

  useDeepCompareEffect(() => {
    // This is a workaraound to be able to delete a feature when selecting that option after
    // the feature is already selected
    if (drawingOption === 'delete') {
      drawControl.changeMode('simple_select')
    }

    if (drawingOption === 'hide') {
      drawControl.deleteAll()
      return
    }
    if (!features?.length) {
      drawControl.deleteAll()
      return
    }
    drawControl.set({
      type: 'FeatureCollection',
      features: features ?? [],
    })
  }, [features, drawingOption])

  useEffect(() => {
    switch (mode) {
      case 'simple_select': {
        drawControl.changeMode(mode)
        break
      }
      default: {
        drawControl.changeMode(mode)
      }
    }
  }, [drawControl, mode])

  return null
}

import { useMutation } from '@apollo/client'
import { Space as AntSpace } from 'antd'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { useQueryParams } from 'hooks'

import {
  Content,
  FormPasswordInput,
  VerificationErrorText,
  LoginTextCta,
  StyledButton,
  WhiteVerificationErrorText,
} from '../../components'
import { ChangePassword, ChangePasswordVariables, CHANGE_PASSWORD } from './api'
import { useChangePasswordForm } from './hooks'

const Space = styled(AntSpace)`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`

export const PasswordChange: React.FC = () => {
  const navigation = useNavigate()
  const query = useQueryParams()

  const token = query.get('token') ?? ''

  const { control, handleSubmit } = useChangePasswordForm()

  const [changePassword, { loading, error }] = useMutation<ChangePassword, ChangePasswordVariables>(
    CHANGE_PASSWORD,
    {
      onCompleted: () => navigation('/dashboard'),
      context: {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    },
  )
  return (
    <Content>
      <Space direction="vertical" size="small">
        <FormPasswordInput control={control} />
        <StyledButton
          type="primary"
          loading={loading}
          onClick={handleSubmit(({ password }) =>
            changePassword({ variables: { token, password } }),
          )}
        >
          Crear nueva contraseña
        </StyledButton>
        {error && (
          <VerificationErrorText error>
            No se ha podido cambiar la contraseña. <br />
            <WhiteVerificationErrorText>
              Recuerda que puedes volver a solicitar un recupero de contraseña
            </WhiteVerificationErrorText>
          </VerificationErrorText>
        )}
      </Space>
      <LoginTextCta />
    </Content>
  )
}

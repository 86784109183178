import { Row } from 'antd'
import styled from 'styled-components'

import { Loader } from '../Loader'

const LoaderContainer = styled(Row)`
  height: 100%;
`

export const FullScreenLoader: React.FC = () => {
  return (
    <LoaderContainer align="middle" justify="center">
      <Loader />
    </LoaderContainer>
  )
}

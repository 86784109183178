import { Avatar } from 'antd'
import styled from 'styled-components'

export const SquareAvatar = styled(Avatar).attrs({
  size: 48,
  shape: 'square',
})`
  border: 4px solid ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.foundation.borderRadius} !important;
  svg {
    margin-top: 5px;
  }
`

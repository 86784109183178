import { Select } from 'antd'

import { GrainType, SelectValueType, Selects } from 'types'
import { RICE_LOT } from 'consts'

interface Props {
  onChange: (grainType: SelectValueType<GrainType>) => void
  value?: SelectValueType<GrainType>
}

const { Option } = Select

const grainTypes = [
  GrainType.SHORT,
  GrainType.CRYSTAL_MEDIUM,
  GrainType.PEARL_MEDIUM,
  GrainType.LONG,
  GrainType.NO_TYPE,
]

export const GrainTypeSelect: React.FC<Props> = ({ onChange, value }) => {
  const grainTypesOptions =
    grainTypes.map(grainType => ({
      label: RICE_LOT.GRAIN_TYPE_NAME_MAP[grainType],
      value: grainType,
    })) ?? []

  return (
    <Select
      listHeight={300}
      placeholder="Sin seleccionar..."
      notFoundContent={'No hay coincidencia'}
      value={value ?? Selects.ALL_OPTIONS}
      onChange={onChange}
    >
      <Option value={Selects.ALL_OPTIONS}>Todos los tipos</Option>
      {grainTypesOptions.map(grainType => (
        <Option key={grainType.value} value={grainType.value}>
          {grainType.label}
        </Option>
      ))}
    </Select>
  )
}

import { gql } from '@apollo/client'

export type { DeleteInvitation, DeleteInvitationVariables } from './types/DeleteInvitation'

export const DELETE_INVITATION = gql`
  mutation DeleteInvitation($id: Int!) {
    deleteInvitation(id: $id) {
      success
    }
  }
`

import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Bar,
  BarChart,
  LabelList,
} from 'recharts'
import { useTheme } from 'styled-components'

interface Props {
  data: {
    date: string
    lotValue?: number
    areaValue?: number
    futureAreaValue?: number
    futureLotValue?: number
  }[]
  showLotsData: boolean
  showAreaData: boolean
}

export const Chart: React.FC<Props> = ({ showAreaData, showLotsData, data }) => {
  const { colors } = useTheme()

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="0" vertical={false} />
        <XAxis dataKey="date" tickLine={true} xAxisId={0} dy={0} dx={-0} tick={{ fontSize: 9 }} />
        <XAxis
          dataKey="days"
          xAxisId={1}
          dy={-15}
          tickLine={false}
          axisLine={false}
          tick={{ fontSize: 9 }}
        />

        {showAreaData && (
          <>
            <Legend
              payload={[
                {
                  value: 'Real',
                  type: 'square',
                  id: 'ID01',
                  color: colors.primary[500],
                  payload: { strokeDasharray: '0 0' },
                },
                {
                  value: 'Estimado',
                  type: 'square',
                  id: 'ID02',
                  color: colors.primary[100],
                  payload: { strokeDasharray: '0 0' },
                },
              ]}
            />
            <YAxis yAxisId="area" orientation="left" stroke={colors.primary[500]} />
            <Bar
              yAxisId="area"
              dataKey="areaValue"
              fill={colors.primary[500]}
              isAnimationActive={false}
            >
              <LabelList
                dataKey="areaValue"
                position="center"
                formatter={(value: number) => (value > 0 ? value.toFixed() : undefined)}
                fill={colors.white}
              />
            </Bar>
            <Bar
              yAxisId="area"
              dataKey="futureAreaValue"
              fill={colors.primary[100]}
              strokeWidth={2}
              strokeDasharray="5 5"
              stroke={colors.primary[500]}
              isAnimationActive={false}
            >
              <LabelList
                dataKey="futureAreaValue"
                position="center"
                formatter={(value: number) => (value > 0 ? value.toFixed() : undefined)}
              />
            </Bar>
          </>
        )}
        {showLotsData && (
          <>
            <YAxis yAxisId="lot" orientation="left" stroke={colors.primary[500]} />
            <Bar
              yAxisId="lot"
              dataKey="lotValue"
              fill={colors.primary[500]}
              isAnimationActive={false}
            >
              <LabelList
                dataKey="lotValue"
                position="center"
                formatter={(value: number) => (value > 0 ? value.toFixed() : undefined)}
                fill={colors.white}
              />
            </Bar>
            <Bar
              yAxisId="lot"
              dataKey="futureLotValue"
              strokeWidth={2}
              strokeDasharray="5 5"
              stroke={colors.primary[500]}
              fill={colors.primary[200]}
              isAnimationActive={false}
            >
              <LabelList
                dataKey="futureLotValue"
                position="center"
                formatter={(value: number) => (value > 0 ? value.toFixed() : undefined)}
              />
            </Bar>
            <Legend
              payload={[
                {
                  value: 'Real',
                  type: 'square',
                  id: 'ID01',
                  color: colors.primary[500],
                  payload: { strokeDasharray: '0 0' },
                },
                {
                  value: 'Estimado',
                  type: 'square',
                  id: 'ID02',
                  color: colors.primary[100],
                  payload: { strokeDasharray: '0 0' },
                },
              ]}
            />
          </>
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}

import {
  ApolloCache,
  ApolloError,
  BaseMutationOptions,
  DefaultContext,
  OperationVariables,
} from '@apollo/client'
import { notification } from 'antd'
import * as Sentry from '@sentry/react'

type ErrorClientOptions = BaseMutationOptions<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  OperationVariables,
  DefaultContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ApolloCache<any>
>

const errorHandler = (
  error: ApolloError,
  _clientOptions?: ErrorClientOptions,
  message?: string,
) => {
  console.log(error)
  Sentry.captureException(error)
  notification.error({
    message: message ?? 'Ocurrió un error, intente de nuevo',
  })
}

export const GraphQLUtils = {
  errorHandler,
}

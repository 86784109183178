import styled from 'styled-components'
import { Button as AntButton } from 'antd'

export { Chart } from './Chart'

interface SquareProps {
  $background: string
}
export const Square = styled.div<SquareProps>`
  width: 30px;
  height: 47px;
  background-color: ${({ $background }) => $background};
`

export const Button = styled(AntButton)`
  width: 113px;
  height: 38px;
`

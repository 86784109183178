import styled from 'styled-components'

export { Chart } from './Chart'

export const ChartContainer = styled.div`
  width: 200px;
  height: 200px;
`

export const SubTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.baseColor[900]};
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
`

export const ChartsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  row-gap: 20px;
  flex-wrap: wrap;
  @media (min-width: 1280px) {
    min-width: 80%;
  }
`
export const EstimatedYieldContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 30px;
  @media (min-width: 1280px) {
    flex-direction: column;
  }
`

export const YieldStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

import { createGlobalStyle } from 'styled-components'

import { theme } from './theme'

export const GlobalStyles = createGlobalStyle`
    html, body {
        margin: 0px;
        height: 100%;
    }
    
    body #root {
        height: 100%;
        overflow: hidden;
    }

    ::-webkit-scrollbar {
        background-color: ${theme.colors.baseColor[100]};
        width: 12px;
        height: 12px;
        border-radius: ${theme.foundation.borderRadius};
    }
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }
    ::-webkit-scrollbar-track:hover {
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.baseColor[900]};
        border-radius: 16px;
        border: 3px solid transparent;
        background-clip: padding-box;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a5;
    }
    ::-webkit-scrollbar-button {
        display: none;
    }

    .ant-card-body {
        padding: 35px;
    }
    .ant-card-bordered {
        border-radius: ${theme.foundation.cardRadius};
    }

    .ant-row {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .ant-col:first-child {
        padding-left: 0 !important;
    }

    .ant-col:last-child {
        padding-right: 0 !important;
    }
    .ant-select-selector,.ant-select-selector:hover, .ant-select-selector:focus, .ant-select-selector:active,.ant-select-focused, .ant-select-open, .ant-picker {
        border: none !important;
        box-shadow: none !important;
    }
    .ant-select-arrow {
        color: white;
    }
    .ant-select-selection-item {
        color: white;
    }
    .ant-select-item-option-content { 
        color: white;
        display: flex;
        font-weight: 600 !important;
    }
    .ant-select-item-option-selected {
        background-color: ${theme.colors.baseColor[700]} !important;
        font-weight: 600 !important;
    }
    .ant-select-item {
        border-radius: 8px !important;
        padding: 9px 20px !important;
     }
    .ant-select-item .anticon-check {
        color: white !important;
    } 
    .ant-select-selector {
        width: 230px !important;
        height: 40px !important;
        border-radius: ${theme.foundation.borderRadius} !important;
        display: flex;
        align-items: center;
        color: white !important;
    } 
    .ant-select-selector input{
        height: 100% !important;
    } 
    .ant-select-item-empty {
        color: white !important;
    }

    .ant-table-thead > tr > th {
        &:before {
            width: 0px !important
        }
    }
    .custom-table-secondary-columns {
        background-color: ${theme.colors.secondary[100]} !important;
        text-align: center !important;
    }
    .custom-table-primary-columns {
        background-color: ${theme.colors.primary[100]} !important;
        text-align: center !important;
    }
    .custom-table-gray-columns {
        background-color: ${theme.colors.baseColor[100]} !important;
        text-align: center !important;
    }

    .ant-select-dropdown {
        border-radius: ${theme.foundation.borderRadius} !important;
        padding: 3px 5px !important;
    }
    .ant-select-multiple .ant-select-selection-item {
        background-color: ${theme.colors.baseColor[900]};
        border: none;
    }
    .ant-select-selection-item-remove > * {
        display: none;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: ${theme.colors.baseColor[800]};
    }
    .ant-select-disabled .ant-select-arrow{
        display: none
    }

    /* Styles for calendar date picker */
    .ant-picker {
        border-radius: ${theme.foundation.borderRadius};
        font-family: 'Inter';
    }
     .ant-picker-suffix {
        margin: 0;
    }
    .ant-picker-input {
        flex-direction: row-reverse;
    }
    .ant-picker input {
        color: ${theme.colors.white} !important;
        font-size: 13px;
        text-align: center;
    }
    .ant-picker-header {
        border: none;
    }
    .ant-picker-header button {
        color: white !important;
        font-size: 20px !important;
        font-weight: 500 !important;
        line-height: 32px !important;
    }
    .ant-picker-header-view {
        padding-top: 10px;
    }
    .ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn {
        display: none;
    }
    .ant-picker-cell-inner {
        color: white !important;
        font-size: 12px;
    }
    .ant-picker-cell-disabled  div {
        color: ${theme.colors.baseColor[500]} !important;
    }
    .ant-picker-content th {
        color: white !important; 
        font-size: 12px;
    }
    .ant-picker-footer {
        display: none;
    }
    .ant-picker-panel {
        border: none;
    }
    .ant-picker-panel-container {
        border-radius: ${theme.foundation.cardRadius} !important;
    }
    .ant-picker svg  {
        color: ${theme.colors.baseColor[300]} !important;
    }
    .ant-picker-disabled {
        background-color: ${theme.colors.baseColor[900]} !important
    }
    .ant-picker-disabled svg {
        color: ${theme.colors.baseColor[700]} !important;
    }
    .ant-picker-disabled input  {
        color: ${theme.colors.baseColor[700]} !important;
    }
    .ant-picker-disabled input::placeholder  {
        color: ${theme.colors.baseColor[700]} !important;
    }
     
    /* Radio button */
    .ant-radio-inner {
        width: 16px;
        height: 16px;
        border-color: ${theme.colors.baseColor[100]} !important;
    }

    /* Button */

    .ant-btn {
        border: none;
    }
    
    .ant-btn-default {
        border: ${theme.foundation.btnBorderWidth} solid ${theme.colors.primary[500]}
    }

    .ant-btn-primary {
        background: ${theme.colors.primaryGradient};
    }

    .ant-btn-default:hover, .ant-btn-default:focus {
        color: ${theme.colors.primary[500]};
        background: white;
        border: ${theme.foundation.btnBorderWidth} solid ${theme.colors.primary[500]}
    }

    /* Input */

    .ant-input-affix-wrapper-focused {
        border-color: ${theme.colors.primary[500]} !important;
        box-shadow: none !important;
    }

    .ant-input-affix-wrapper-status-error {
        background: ${theme.colors.transparent} !important;
    }
    
    .ant-input:hover, .ant-input:focus {
        border-color: ${theme.colors.primary[500]} !important;
        box-shadow: none !important;
    }

    /* Modal */

    .ant-modal-content {
        padding: 0px !important;
    }

    .ant-modal-header {
        padding: ${theme.modal.header.padding.vertical} ${theme.modal.header.padding.horizontal} ${theme.modal.header.padding.bottom};
    }

    .ant-modal-body {
        padding: 0px ${theme.modal.body.padding.horizontal} ${theme.modal.body.padding.vertical};
    }

    
`

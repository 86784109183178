import { Col } from 'antd'
import styled from 'styled-components'

export { Chart } from './Chart'

export const Title = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.baseColor[900]};
`
export const ChartContainer = styled(Col)`
  height: 370px;
  margin-top: 25px;
`

import { gql } from '@apollo/client'

export type { OrganizationStats, OrganizationStatsVariables } from './types/OrganizationStats'
export type {
  HarvestOrganizationStats,
  HarvestOrganizationStatsVariables,
} from './types/HarvestOrganizationStats'
export type {
  OrganizationStatsByStatus,
  OrganizationStatsByStatusVariables,
} from './types/OrganizationStatsByStatus'

export const ORGANIZATION_STATS = gql`
  query OrganizationStats(
    $regionId: Int
    $subregionId: Int
    $companyId: Int
    $cropId: Int!
    $varietyId: Int
    $seasonStartRange: DateTime
    $seasonEndRange: DateTime
    $status: RiceLotStatus!
  ) {
    organizationStats(
      regionId: $regionId
      subregionId: $subregionId
      companyId: $companyId
      cropId: $cropId
      varietyId: $varietyId
      seasonStartRange: $seasonStartRange
      seasonEndRange: $seasonEndRange
      status: $status
    ) {
      totals {
        area
        plantedArea
        emergedArea
        notEmergedYield
        notEmergedArea
        yield
        fortnightsStats {
          byArea {
            key
            value
          }
          byYield {
            key
            value
          }
        }
        yieldByDeliveryCondition {
          deliveryCondition
          value
        }
        subTotalYieldByDeliveryCondition
      }
      statsByProduction {
        productionType
        totals {
          area
          plantedArea
          emergedArea
          notEmergedYield
          notEmergedArea
          yield
          fortnightsStats {
            byArea {
              key
              value
            }
            byYield {
              key
              value
            }
          }
          yieldByDeliveryCondition {
            deliveryCondition
            value
          }
          subTotalYieldByDeliveryCondition
        }
        statsByGrainType {
          grainType
          stats {
            area
            plantedArea
            emergedArea
            notEmergedYield
            notEmergedArea
            yield
            subTotalYieldByDeliveryCondition
            fortnightsStats {
              byArea {
                key
                value
              }
              byYield {
                key
                value
              }
            }
            yieldByDeliveryCondition {
              deliveryCondition
              value
            }
          }
        }
      }
    }
  }
`

export const ORGANIZATION_STATS_BY_STATUS = gql`
  query OrganizationStatsByStatus(
    $regionId: Int
    $subregionId: Int
    $companyId: Int
    $cropId: Int!
    $varietyId: Int
    $seasonStartRange: DateTime
    $seasonEndRange: DateTime
    $status: RiceLotStatus!
    $grainType: GrainType
    $deliveryCondition: DeliveryCondition
    $productionType: ProductionType
  ) {
    organizationStats(
      regionId: $regionId
      subregionId: $subregionId
      companyId: $companyId
      cropId: $cropId
      varietyId: $varietyId
      seasonStartRange: $seasonStartRange
      seasonEndRange: $seasonEndRange
      status: $status
      deliveryCondition: $deliveryCondition
      grainType: $grainType
      productionType: $productionType
    ) {
      totals {
        fortnightsStats {
          byArea {
            key
            value
          }
        }
      }
    }
  }
`

export const HARVEST_ORGANIZATION_STATS = gql`
  query HarvestOrganizationStats(
    $regionId: Int
    $subregionId: Int
    $companyId: Int
    $cropId: Int!
    $varietyId: Int
    $seasonStartRange: DateTime
    $seasonEndRange: DateTime
    $status: RiceLotStatus!
    $grainType: GrainType
    $deliveryCondition: DeliveryCondition
    $productionType: ProductionType
  ) {
    organizationStats(
      regionId: $regionId
      subregionId: $subregionId
      companyId: $companyId
      cropId: $cropId
      varietyId: $varietyId
      seasonStartRange: $seasonStartRange
      seasonEndRange: $seasonEndRange
      status: $status
      deliveryCondition: $deliveryCondition
      grainType: $grainType
      productionType: $productionType
    ) {
      totals {
        area
        plantedArea
        emergedArea
        notEmergedYield
        fortnightsStats {
          byYield {
            key
            value
          }
        }
      }
      fortnights {
        name
        dateRange {
          startDate
          endDate
        }
      }
    }
  }
`

import { BBox, Feature } from '@turf/helpers'
import area from '@turf/area'

import { Lots } from 'hooks/useOrganizationAnalyticsLots'

const SQUARE_METERS_HECTARES_CONVERSION_RATE = 1 / 10000

/**
 * Converts a bbox to coordinates
 * TopLeft, TopRight, BottomRight, BottomLeft
 * @param {turf.BBox} bbox
 */
const bboxCoords = (bbox: BBox) => {
  const minX = Number(bbox[0])
  const minY = Number(bbox[1])
  const maxX = Number(bbox[2])
  const maxY = Number(bbox[3])

  return [
    [minX, maxY],
    [maxX, maxY],
    [maxX, minY],
    [minX, minY],
  ]
}

/**
 * Returns the size of a geometry in hectares
 */
const size = (geometry: Feature) => {
  return area(geometry) * SQUARE_METERS_HECTARES_CONVERSION_RATE
}

/**
 * Returns a FeatureCollection of lots' centers
 */
const featureCollection = (geometryBuilder: (lot: Lots) => GeoJSON.Geometry, lots?: Lots[]) => {
  if (!lots) return

  const lotsFeatureCollection: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: 'FeatureCollection',
    features: lots.map(lot => {
      return {
        type: 'Feature',
        properties: {
          id: lot.lot.id.toString(),
        },
        geometry: geometryBuilder(lot),
      }
    }),
  }

  return lotsFeatureCollection
}

export const GeoUtils = {
  bboxCoords,
  size,
  featureCollection,
}

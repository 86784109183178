import { useMemo } from 'react'

import { Body, CropsSelect, RegionsSelect } from 'features/MainRouter/components'
import {
  CompaniesSelect,
  HistoricalSeasonSelect,
  SubregionsSelect,
  VarietiesSelect,
} from 'components'
import { useFeatures, useUser } from 'hooks'
import { UserOrganizationRole } from 'types'

import { CardsContainer, HarvestCard, ChartsCard } from './components'
import { useHarvestStats } from './hooks'

export const HarvestStats = () => {
  const { featureFlags } = useFeatures()
  const user = useUser()
  const {
    setSelectedHistoricalSeason,
    selectedHistoricalSeason,
    region,
    subregion,
    selectedVarietyId,
    setSelectedVarietyId,
    cropId,
    setCompany,
    setSubregion,
    company,
    harvestStats,
    loading,
    harvestChartData,
    areaChartData,
    harvestByStatus,
    selectedStatus,
    setSelectedStatus,
    selectedGrainType,
    selectedDeliveryCondition,
    selectedProductionType,
    setSelectedGrainType,
    setSelectedDeliveryCondition,
    setSelectedProductionType,
    harvestOrganizationStatsLoading,
    organizationStatsByStatusLoading,
    fortnightSelectOptions,
    selectedFortnight,
    setSelectedFortnight,
    harvestedCalendarData,
    isHarvestedCalendarLoading,
  } = useHarvestStats()
  const allHeaderItems = useMemo(
    () => ({
      seasonsSelect: (
        <HistoricalSeasonSelect
          onChange={setSelectedHistoricalSeason}
          selectedHistoricalSeason={selectedHistoricalSeason}
          width="170px"
        />
      ),
      crop: (
        <CropsSelect
          seasonStartRange={selectedHistoricalSeason?.startRange}
          seasonEndRange={selectedHistoricalSeason?.endRange}
          width="170px"
          regionId={region?.id}
          subregionId={subregion?.id}
        />
      ),
      variety: (
        <VarietiesSelect
          onChange={setSelectedVarietyId}
          value={selectedVarietyId}
          seasonStartRange={selectedHistoricalSeason?.startRange}
          seasonEndRange={selectedHistoricalSeason?.endRange}
          regionId={region?.id}
          subregionId={subregion?.id}
          cropId={cropId}
        />
      ),
      region: <RegionsSelect onChange={() => setCompany(undefined)} width="150px" />,
      subregion: <SubregionsSelect onChange={setSubregion} width="150px" />,
      companies: <CompaniesSelect onChange={setCompany} value={company?.id} />,
    }),
    [
      company?.id,
      region?.id,
      selectedHistoricalSeason,
      selectedVarietyId,
      setCompany,
      setSelectedHistoricalSeason,
      setSelectedVarietyId,
      setSubregion,
      subregion?.id,
      cropId,
    ],
  )

  const selectedHeaderItems = useMemo(() => {
    const isMill = user?.organizationRole?.role === UserOrganizationRole.MILL
    const addSubregionSelect = isMill
    const addCompaniesSelect = featureFlags.subFarmersManagement?.enabled

    const items = [
      allHeaderItems.seasonsSelect,
      allHeaderItems.crop,
      allHeaderItems.variety,
      allHeaderItems.region,
    ]
    if (addSubregionSelect) items.push(allHeaderItems.subregion)

    if (addCompaniesSelect) items.push(allHeaderItems.companies)
    return items
  }, [allHeaderItems, featureFlags.subFarmersManagement?.enabled, user?.organizationRole?.role])

  return (
    <Body headerTitle="Cosecha" headerItems={selectedHeaderItems}>
      <CardsContainer>
        <ChartsCard
          harvestChartData={harvestChartData}
          areaChartData={areaChartData}
          harvestByStatus={harvestByStatus}
          selectedStatus={selectedStatus}
          onStatusChange={setSelectedStatus}
          selectedGrainType={selectedGrainType}
          selectedDeliveryCondition={selectedDeliveryCondition}
          selectedProductionType={selectedProductionType}
          onGrainTypeChange={setSelectedGrainType}
          onDeliveryConditionChange={setSelectedDeliveryCondition}
          onProductionTypeChange={setSelectedProductionType}
          harvestOrganizationStatsLoading={harvestOrganizationStatsLoading}
          organizationStatsByStatusLoading={organizationStatsByStatusLoading}
          fortnightSelectOptions={fortnightSelectOptions}
          selectedFortnight={selectedFortnight}
          onFortnightChange={setSelectedFortnight}
          harvestedCalendarData={harvestedCalendarData}
          isHarvestedCalendarLoading={isHarvestedCalendarLoading}
        />
        <HarvestCard harvestStats={harvestStats} isLoading={loading || !harvestStats} />
      </CardsContainer>
    </Body>
  )
}

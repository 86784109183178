import { Dropdown } from 'antd'

import { PolygonIcon } from 'assets/icons'
import { DrawingOption, ToolMode } from 'features/MainRouter/features/Map/types'

import { IconContainer, MeasureAreaDropdown } from './components'

interface Props {
  mode: ToolMode
  drawingOption: DrawingOption
  isDrawingToolAvailable: boolean
  showDrawingToolDropdown: boolean
  onDrawingToolDropdownVisibleChange: () => void
  onDrawingOptionSelected: (option: DrawingOption) => void
}

export const DrawToolbar: React.FC<Props> = ({
  mode,
  drawingOption,
  isDrawingToolAvailable,
  showDrawingToolDropdown,
  onDrawingToolDropdownVisibleChange,
  onDrawingOptionSelected,
}) => {
  return (
    <Dropdown
      dropdownRender={() => (
        <MeasureAreaDropdown
          selectedItem={drawingOption}
          isDrawingToolAvailable={isDrawingToolAvailable}
          onDrawingOptionSelected={onDrawingOptionSelected}
        />
      )}
      trigger={['click']}
      open={showDrawingToolDropdown}
      onOpenChange={onDrawingToolDropdownVisibleChange}
    >
      <IconContainer
        $isActive={mode === 'draw' || drawingOption === 'hide'}
        onClick={() => onDrawingOptionSelected('reset')}
      >
        <PolygonIcon />
      </IconContainer>
    </Dropdown>
  )
}

import { Col, Row } from 'antd'

import { LogoKunaLoginIcon } from 'assets/icons'

export const ContentHeader: React.FC = () => (
  <Row justify="center">
    <Col>
      <LogoKunaLoginIcon />
    </Col>
  </Row>
)

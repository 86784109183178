import { useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { UPDATE_ORGANIZATION, UpdateOrganization, UpdateOrganizationVariables } from './api'

export const useUpdateOrganization = () => {
  const [updateOrganization] = useMutation<UpdateOrganization, UpdateOrganizationVariables>(
    UPDATE_ORGANIZATION,
    {
      onError: GraphQLUtils.errorHandler,
    },
  )

  return { updateOrganization }
}

import { gql, useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'
import { CommercialExecutive, CommercialExecutiveGoal } from 'hooks/useCommercialExecutives/api'

import {
  ASSIGN_COMMERCIAL_EXECUTIVE_GOAL,
  AssignCommercialExecutiveGoal,
  AssignCommercialExecutiveGoalVariables,
  AssignedGoal,
} from './api'

const UserGoalsFragment = gql`
  fragment UserGoals on User {
    id
    organizationRole {
      goals {
        id
        yearMonth
        goal
      }
    }
  }
`

const computeCommercialExecutiveGoals = (
  goals: CommercialExecutiveGoal[],
  updatedYearMonth: string,
  assignedGoal?: AssignedGoal,
) => {
  const baseGoals = [...goals]
  const goalIndex = baseGoals.findIndex(goal => goal.yearMonth === updatedYearMonth)

  if (!assignedGoal) {
    if (goalIndex === -1) return baseGoals

    baseGoals.splice(goalIndex, 1)
    return baseGoals
  }

  if (goalIndex === -1) {
    return [...baseGoals, assignedGoal]
  }

  baseGoals[goalIndex] = { ...baseGoals[goalIndex], ...assignedGoal }

  return baseGoals
}

export const useAssignCommercialExecutiveGoal = () => {
  const [assignGoal, { loading }] = useMutation<
    AssignCommercialExecutiveGoal,
    AssignCommercialExecutiveGoalVariables
  >(ASSIGN_COMMERCIAL_EXECUTIVE_GOAL, {
    update: (cache, { data }, { variables }) => {
      if (!variables || !data?.assignCommercialExecutiveGoal.success) return

      const cachedCommercialExecutiveId = cache.identify({
        __typename: 'User',
        id: variables.assignGoalDTO.commercialExecutiveId,
      })

      if (!cachedCommercialExecutiveId) return

      const commercialExecutive = cache.readFragment({
        id: cachedCommercialExecutiveId,
        fragment: UserGoalsFragment,
      }) as CommercialExecutive | undefined

      if (!commercialExecutive) return

      const { assignedGoal } = data.assignCommercialExecutiveGoal

      const computedGoals = computeCommercialExecutiveGoals(
        commercialExecutive.organizationRole?.goals ?? [],
        variables.assignGoalDTO.yearMonth,
        assignedGoal ?? undefined,
      )

      cache.writeFragment({
        id: cachedCommercialExecutiveId,
        fragment: UserGoalsFragment,
        data: {
          ...commercialExecutive,
          organizationRole: {
            ...commercialExecutive.organizationRole,
            goals: computedGoals,
          },
        },
      })
    },
    onError: GraphQLUtils.errorHandler,
  })

  return { assignGoal, loading }
}

export const MAP = {
  DEFAULT_CENTER: { LATITUDE: 4.15, LONGITUDE: -74.883 },
  DEFAULT_TRANSITION: { transitionDuration: 2000 },
  ZOOM: {
    DEFAULT: 8,
    CLOSE: 14,
    LIMIT: 10,
  },
  STYLES: {
    SATELLITE_STREET: 'mapbox://styles/mapbox/satellite-streets-v11?optimize=true',
  },
  CLUSTERS: {
    SOURCE_ID: 'clusters-lots',
    CLUSTER_COUNT_ID: 'cluster-count',
    UNCLUSTERED_POINT_ID: 'unclustered-point',
    CLUSTERS_ID: 'clusters',
  },
}

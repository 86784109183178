import { Col, Row, Space } from 'antd'

import { EllipsisText } from 'components'
import { MapFiltersContext } from 'features/MainRouter/contexts'
import { useFeatures } from 'hooks'

import { Container, Header, Body, Text, ColorText, EmergenceDaysContainer } from './components'

interface Props {
  name: string
  emergenceDays: string
  size: number
  variety: string
  companyName: string
  comercialExecutive: string
}

export const PopupCard: React.FC<Props> = ({
  name,
  emergenceDays,
  size,
  variety,
  companyName,
  comercialExecutive,
}) => {
  const { commercialExecutive: selectedCommercialExecutive } =
    MapFiltersContext.useMapFiltersContext()
  const { featureFlags } = useFeatures()
  return (
    <Container>
      <Header>
        <EllipsisText
          text={name}
          overlayInnerStyle={{
            fontSize: '13px',
          }}
        />
      </Header>
      <Body gutter={[0, 16]}>
        <EmergenceDaysContainer span={24}>
          <Text>{emergenceDays} DDE</Text>
        </EmergenceDaysContainer>
        <Row gutter={[0, 8]}>
          <Col span={24}>
            <Space size={12}>
              <Text>Tamaño</Text>
              <ColorText>{size} ha</ColorText>
            </Space>
          </Col>
          <Col span={24}>
            <Space size={12}>
              <Text>Variedad</Text>
              <ColorText>{variety}</ColorText>
            </Space>
          </Col>
          <Col span={24}>
            <Space size={12} align="start">
              <Text>Productor</Text>
              <ColorText>{companyName}</ColorText>
            </Space>
          </Col>
          {featureFlags.crm?.enabled && !selectedCommercialExecutive && (
            <Col span={24}>
              <Space size={12} align="start">
                <Text>Ejec. Comercial</Text>
                <ColorText>{comercialExecutive}</ColorText>
              </Space>
            </Col>
          )}
        </Row>
      </Body>
    </Container>
  )
}

import { useContext, useState, createContext } from 'react'

import { Crop } from 'features/MainRouter/components/CropsSelect'

interface CropFilterContextType {
  selectedCrop?: Crop
  setSelectedCrop: (crop?: Crop) => void
}

const Context = createContext<CropFilterContextType>({
  selectedCrop: undefined,
  setSelectedCrop: () => {
    throw new Error(
      'Attempted to use default setSelectedCrop function for CropFilterContext, make sure to pass a custom function implementation',
    )
  },
})

const useValue = (): CropFilterContextType => {
  const [selectedCrop, setSelectedCrop] = useState<Crop>()

  return {
    selectedCrop,
    setSelectedCrop,
  }
}

const useCropFilterContext = () => {
  return useContext(Context)
}

export const CropFilterContext = {
  Provider: Context.Provider,
  useValue,
  useCropFilterContext,
}

import { useContext, useState, createContext } from 'react'

import { HistoricalSeason } from 'components/HistoricalSeasonSelect/types'

interface HistoricalSeasonFilterContextType {
  selectedHistoricalSeason?: HistoricalSeason
  setSelectedHistoricalSeason: (historicalSeason?: HistoricalSeason) => void
}

const Context = createContext<HistoricalSeasonFilterContextType>({
  selectedHistoricalSeason: undefined,
  setSelectedHistoricalSeason: () => {
    throw new Error(
      'Attempted to use default setSelectedHistoricalSeason function for HistoricalSeasonFilterContextType, make sure to pass a custom function implementation',
    )
  },
})

const useValue = (): HistoricalSeasonFilterContextType => {
  const [selectedHistoricalSeason, setSelectedHistoricalSeason] = useState<HistoricalSeason>()

  return {
    selectedHistoricalSeason,
    setSelectedHistoricalSeason,
  }
}

const useHistoricalSeasonFilterContext = () => {
  return useContext(Context)
}

export const HistoricalSeasonFilterContext = {
  Provider: Context.Provider,
  useValue,
  useHistoricalSeasonFilterContext,
}

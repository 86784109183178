import { Row, Button, Space, Modal as AntModal } from 'antd'
import styled from 'styled-components'

const Modal = styled(AntModal)`
  width: 550px !important;
`

const Description = styled(Row)`
  padding-right: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`

const ButtonsContainer = styled(Space).attrs({
  size: 20,
})`
  width: 100%;
  justify-content: flex-end;
  margin-top: 22px;
  & button {
    width: 143px;
    height: 38px;
  }
`

interface Props {
  title?: string
  description?: string
  onConfirm: () => void
  onCancel: () => void
  loading: boolean
}

export const ConfirmationModal: React.FC<Props> = ({
  title,
  description,
  onConfirm,
  onCancel,
  loading,
}) => {
  return (
    <Modal title={title ?? ' '} open centered onCancel={onCancel} footer={null} closable={false}>
      <Description>{description}</Description>
      <ButtonsContainer>
        <Button onClick={onCancel}>Cancelar</Button>
        <Button type="primary" onClick={onConfirm} loading={loading}>
          Confirmar
        </Button>
      </ButtonsContainer>
    </Modal>
  )
}

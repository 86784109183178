import { Col, Row } from 'antd'

import { RegionFilterContext } from 'contexts'
import { useFeatures, useUser } from 'hooks'

import {
  DetailsCard,
  CommercialExecutivesCard,
  VarietyStatsCard,
  PlaceholderCard,
  Container,
  CompaniesCard,
  EmergenceDatesCard,
} from './components'
import { useOrganizationAnalyticsSummary } from './hooks'

export const OrganizationAnalyticsSummary = () => {
  const { region } = RegionFilterContext.useRegionFilterContext()
  const { featureFlags } = useFeatures()
  const user = useUser()

  const {
    lotsQty,
    haQty,
    commercialExecutives,
    companies,
    varietiesChartData,
    loading,
    emergenceDatesChartData,
    emergenceSizeDatesChartData,
  } = useOrganizationAnalyticsSummary()

  return (
    <Container direction="vertical" size={60}>
      <Row gutter={30}>
        <Col flex="350px">
          <DetailsCard
            lotsQty={lotsQty}
            haQty={haQty}
            // TODO: Remove this when image upload is implemented
            image={
              !region?.id
                ? user?.organizationRole?.organization?.allZonesImage ?? undefined
                : region.mapPictureId ?? undefined
            }
            isLoading={loading}
          />
        </Col>
        {featureFlags.crm?.enabled && (
          <Col flex="395px">
            <CommercialExecutivesCard
              commercialExecutives={commercialExecutives}
              isLoading={loading}
            />
          </Col>
        )}
        {featureFlags.subFarmersManagement?.enabled ? (
          <>
            <Col flex="395px">
              <CompaniesCard companies={companies} isLoading={loading} />
            </Col>
            <Col flex={1}>
              <EmergenceDatesCard
                lotsQtyData={emergenceDatesChartData}
                sizeData={emergenceSizeDatesChartData}
                isLoading={loading}
              />
            </Col>
          </>
        ) : (
          <Col flex={1}>
            <PlaceholderCard />
          </Col>
        )}
      </Row>
      <Row>
        <Col flex={1}>
          <VarietyStatsCard chartData={varietiesChartData} isLoading={loading} />
        </Col>
      </Row>
    </Container>
  )
}

import { gql } from '@apollo/client'

export type { OrganizationUsers } from './types/OrganizationUsers'

export const ORGANIZATION_USERS = gql`
  query OrganizationUsers {
    organization {
      id
      invitations {
        id
        email
        role
        regionId
      }
      usersRoles {
        id
        role
        regionId
        user {
          id
          name
          lastName
          email
          profilePicture
        }
      }
    }
  }
`

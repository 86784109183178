import { UserOutlined } from '@ant-design/icons'

import { FormInput } from 'components'
import { useQueryParams } from 'hooks'

import {
  LoginTextCta,
  Content,
  FormEmailInput,
  FormPasswordInput,
  StyledButton,
} from '../../components'
import { useFormIconsStyle } from '../hooks'
import { Space } from './components'
import { useSignup, useSignupForm } from './hooks'

export const Signup: React.FC = () => {
  const iconStyle = useFormIconsStyle()
  const query = useQueryParams()
  const token = query.get('token')
  const email = query.get('email')

  const { control, handleSubmit } = useSignupForm({ email: email ?? '' })
  const [signup, { loading }] = useSignup()

  //TODO: We must change locale

  return (
    <Content>
      <Space direction="vertical" size={13}>
        <FormInput
          name="name"
          control={control}
          placeholder="Nombre"
          prefixIcon={<UserOutlined style={iconStyle} />}
          capitalize
        />
        <FormInput
          name="lastName"
          control={control}
          placeholder={'Apellido'}
          prefixIcon={<UserOutlined style={iconStyle} />}
          capitalize
        />
        <FormEmailInput control={control} isReadOnly={!!email} />
        <FormPasswordInput control={control} />
        <StyledButton
          type="primary"
          loading={loading}
          onClick={handleSubmit(createUserDTO =>
            signup({ variables: { createUserDTO, token, locale: 'es-ES' } }),
          )}
        >
          Crear cuenta
        </StyledButton>
      </Space>
      <LoginTextCta />
    </Content>
  )
}

import { Control } from 'react-hook-form'

import { FormInput } from 'components'
import { PasswordIcon } from 'assets/icons'

interface Props {
  size?: 'small' | 'middle' | 'large'
  //TODO: control is not optional, change it when we integrate this component everywhere.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export const FormPasswordInput: React.FC<Props> = ({ control, onKeyUp, ...props }) => {
  return (
    <FormInput
      {...props}
      placeholder={'Contraseña'}
      prefixIcon={<PasswordIcon />}
      type="password"
      name="password"
      control={control}
      onKeyUp={onKeyUp}
    />
  )
}

import { Space } from 'antd'
import { useTheme } from 'styled-components'

import { UserIcon, BusinessStoreIcon } from 'assets/icons'
import { ItemInfo } from 'features/MainRouter/components'
import { NumberUtils } from 'utils'
import { Loader } from 'components'
import { useFeatures } from 'hooks'

import {
  SummaryCardContainer,
  ManagersDetails,
  Chart,
  StyledCol,
  LoaderContainer,
  ChartsContainer,
} from './components'
import { useFilteredLotsSummary } from './hooks'
import { Props } from '../../types'

export const FilteredLotsSummaryCard: React.FC<Props> = ({ data, loading }) => {
  const { colors } = useTheme()
  const { lotsStats, totalCommercialExecutives, totalCompanies } = useFilteredLotsSummary({ data })
  const { featureFlags } = useFeatures()

  const dataHa = [
    { value: lotsStats.ha.filter },
    { value: lotsStats.ha.total - lotsStats.ha.filter },
  ]
  const dataUnits = [
    { value: lotsStats.units.filter },
    { value: lotsStats.units.total - lotsStats.units.filter },
  ]

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <SummaryCardContainer>
          <ManagersDetails>
            <ItemInfo
              title={typeof totalCompanies === 'number' ? 'Productores' : 'Productor'}
              data={totalCompanies?.toString() ?? '-'}
              Icon={<UserIcon />}
            />
            {featureFlags.crm?.enabled && (
              <ItemInfo
                title={
                  typeof totalCommercialExecutives === 'number'
                    ? 'Ejec. Comerciales'
                    : 'Ejec. Comercial'
                }
                data={totalCommercialExecutives?.toString() ?? '-'}
                Icon={<BusinessStoreIcon />}
              />
            )}
          </ManagersDetails>
          <ChartsContainer>
            <StyledCol>
              <Chart text="Lotes" data={dataUnits} fillColor={colors.primary[500]} />
              <Space direction="vertical" size={16}>
                <ItemInfo
                  title={'Filtrados'}
                  data={lotsStats.units.filter ? NumberUtils.localize(lotsStats.units.filter) : '0'}
                />
                <ItemInfo title={'Totales'} data={NumberUtils.localize(lotsStats.units.total)} />
              </Space>
            </StyledCol>
            <StyledCol>
              <Chart text="Hectáreas" data={dataHa} fillColor={colors.primary[500]} />
              <Space direction="vertical" size={16}>
                <ItemInfo
                  title={'Filtradas'}
                  data={
                    lotsStats.ha.filter ? `${NumberUtils.localize(lotsStats.ha.filter)} ha` : '0'
                  }
                />
                <ItemInfo
                  title={'Totales'}
                  data={`${NumberUtils.localize(lotsStats.ha.total)} ha`}
                />
              </Space>
            </StyledCol>
          </ChartsContainer>
        </SummaryCardContainer>
      )}
    </>
  )
}

import { Table as AntTable } from 'antd'
import styled from 'styled-components'

import { OrganizationStats } from 'features/MainRouter/features/HarvestStats/hooks/useHarvestStats/api'

import { useTableData } from './useTableData'
import { Footer } from './components'

const StyledTable = styled(AntTable)`
  .ant-table-thead {
    text-align: center;

    :first-child {
      :nth-child(2) {
        background: ${({ theme }) => theme.colors.secondary[100]};
      }
      :nth-child(3) {
        background: ${({ theme }) => theme.colors.primary[100]};
      }
    }
  }
  .ant-table-tbody > tr > td {
    background-color: transparent !important;
  }
  .ant-table-tbody > tr {
    :nth-child(13) {
      background: ${({ theme }) => theme.colors.primary[100]} !important;
      font-weight: bold;
    }
    :nth-child(14) {
      background: ${({ theme }) => theme.colors.baseColor[100]} !important;
      font-weight: bold;
    }
    :nth-child(7) {
      background: ${({ theme }) => theme.colors.secondary[100]} !important;
      font-weight: bold;
    }
  }

  .ant-table-tbody {
    text-align: center;
    :last-child {
      font-weight: bold;
    }
  }
` as unknown as typeof AntTable

interface Props {
  harvestStats?: OrganizationStats['organizationStats']
  isLoading?: boolean
}

export const Table: React.FC<Props> = ({ harvestStats, isLoading }) => {
  const { columns, dataSource } = useTableData({ harvestStats })
  return (
    <StyledTable
      columns={columns}
      dataSource={dataSource}
      bordered
      pagination={false}
      loading={isLoading}
      footer={Footer}
      rowKey={'statKey'}
    />
  )
}

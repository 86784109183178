import { Space as AntSpace, Button } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { CenteredText } from 'features/LoginRouter/components'

export const LoginButton = styled(Button)`
  width: 100%;
  height: 45px;
`

export const Space = styled(AntSpace)`
  & .ant-form-item {
    margin-bottom: 0 !important;
  }
`

export const MainText = styled(CenteredText)`
  margin-bottom: 8px;
  color: ${props => props.theme.colors.white};
`

export const StyledLink = styled(Link)`
  font-size: 20px !important;
  font-weight: 700 !important;
  color: ${props => props.theme.colors.secondary[600]} !important;
`

import { useContext, useState, createContext } from 'react'

import { Subregion } from 'components/SubregionsSelect/api'
import { OrganizationCompany } from 'components/CompaniesSelect/api'

interface HarvestFiltersContextType {
  subregion?: Subregion
  setSubregion: (subregion?: Subregion) => void
  company?: OrganizationCompany
  setCompany: (company?: OrganizationCompany) => void
}

const Context = createContext<HarvestFiltersContextType>({
  subregion: undefined,
  setSubregion: () => {
    throw new Error(
      'Attempted to use default setSubregion function for HarvestFiltersContext, make sure to pass a custom function implementation',
    )
  },
  company: undefined,
  setCompany: () => {
    throw new Error(
      'Attempted to use default setCompany function for HarvestFiltersContext, make sure to pass a custom function implementation',
    )
  },
})

const useValue = (): HarvestFiltersContextType => {
  const [subregion, setSubregion] = useState<Subregion>()
  const [company, setCompany] = useState<OrganizationCompany>()

  return {
    subregion,
    setSubregion,
    company,
    setCompany,
  }
}

const useHarvestFiltersContext = () => {
  return useContext(Context)
}

export const HarvestFiltersContext = {
  Provider: Context.Provider,
  useValue,
  useHarvestFiltersContext,
}

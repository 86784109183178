import { BarChart, Bar, XAxis, CartesianGrid, LabelList, ResponsiveContainer } from 'recharts'
import { useTheme } from 'styled-components'

import { NumberUtils } from 'utils'

import { DataByStatus } from '../../types'

interface Props {
  data: DataByStatus[]
}

export const Chart: React.FC<Props> = ({ data }) => {
  const { colors } = useTheme()

  const valueFormatter = (value: number) => {
    const formattedValue = NumberUtils.localize(value)

    return formattedValue === '0' ? undefined : formattedValue
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          left: 20,
          right: 20,
          top: 20,
        }}
        barSize={45}
      >
        <XAxis dataKey="name" scale="point" tickLine={false} padding={{ left: 20, right: 20 }} />
        <CartesianGrid strokeDasharray="0 0" vertical={false} />
        <Bar
          stackId={'stackA'}
          dataKey="values.MANUAL"
          fill={colors.milestoneUpdateType.MANUAL}
          background={{ fill: 'transparent' }}
          isAnimationActive={false}
        >
          <LabelList
            dataKey="values.MANUAL"
            position="center"
            fill={colors.white}
            formatter={valueFormatter}
          />
        </Bar>
        <Bar
          stackId={'stackA'}
          dataKey="values.AUTOMATIC_CONVERSION"
          fill={colors.milestoneUpdateType.AUTOMATIC_CONVERSION}
          background={{ fill: 'transparent' }}
          isAnimationActive={false}
        >
          <LabelList
            dataKey="values.AUTOMATIC_CONVERSION"
            position="center"
            fill={colors.white}
            formatter={valueFormatter}
          />
        </Bar>
        <Bar
          stackId={'stackA'}
          dataKey="values.AUTOMATIC_SENTINEL1"
          fill={colors.milestoneUpdateType.AUTOMATIC_SENTINEL1}
          background={{ fill: 'transparent' }}
          isAnimationActive={false}
        >
          <LabelList position="top" fill={colors.black} formatter={valueFormatter} />
          <LabelList
            dataKey="values.AUTOMATIC_SENTINEL1"
            position="center"
            fill={colors.white}
            formatter={valueFormatter}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

import { gql } from '@apollo/client'

export type { ChangePassword, ChangePasswordVariables } from './types/ChangePassword'

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($token: String!, $password: String!) {
    validatePasswordChange(token: $token, password: $password) {
      success
      token
      user {
        id
        email
        name
        lastName
      }
    }
  }
`

import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const { Text } = Typography

const CenteredText = styled(Text)`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.secondary[600]} !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`

export const LoginTextCta: React.FC = () => {
  return (
    <CenteredText>
      {`¿Ya tienes cuenta? `}
      <StyledLink to="/auth">Iniciar sesión</StyledLink>
    </CenteredText>
  )
}

import { Table as AntTable } from 'antd'
import styled from 'styled-components'

export const Table = styled(AntTable)`
  th {
    background: white !important;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.baseColor[200]}`} !important;
    font-size: 16px;
    text-align: center;
    font-weight: 600 !important;
  }
  td {
    font-size: 13px;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.baseColor[200]}`} !important;
  }
  .ant-table-body {
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.baseColor[900]};
      background-clip: padding-box;
    }
  }
  .ant-table-header {
    border-radius: ${({ theme }) => theme.foundation.borderRadius} !important;
  }
` as typeof AntTable

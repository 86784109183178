import { Col, Row } from 'antd'
import styled from 'styled-components'

export { LotsFilteredCounter } from './LotsFilteredCounter'

export const Container = styled(Row)`
  position: absolute;
  top: 40px;
  right: 50px;
  z-index: 10;
  max-width: 240px;
`

export const ContainerSelect = styled(Col)`
  height: 330px;
  margin-top: -40px;
  .ant-select {
    visibility: hidden;
  }
`

export const TimeSelectContainer = styled(Col)`
  z-index: 1;
`

export const ToolsContainer = styled(Row)`
  width: 230px;
`

export const CenterToolContainer = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  background-color: ${({ theme }) => theme.colors.baseColor[900]};
`

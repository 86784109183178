import { Table as AntTable, Empty } from 'antd'
import styled from 'styled-components'

import { CardSection, Loader } from 'components'
import { NumberUtils } from 'utils'

import { OrganizationAnalyticsSummaryCommercialExecutive } from '../../hooks/useOrganizationAnalyticsSummary/api'
import { LoaderContainer } from '..'

const Table = styled(AntTable)<OrganizationAnalyticsSummaryCommercialExecutive>`
  table {
    border-collapse: collapse !important;
  }
  .ant-table-cell {
    border-bottom: unset !important;
  }
  .ant-table-body {
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.baseColor[900]};
      background-clip: padding-box;
    }
  }
  th {
    background: transparent !important;
    border: 0px !important;
    font-family: Inter;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
  }
` as unknown as typeof AntTable

interface Props {
  commercialExecutives: OrganizationAnalyticsSummaryCommercialExecutive[]
  isLoading: boolean
}
export const CommercialExecutivesCard: React.FC<Props> = ({ commercialExecutives, isLoading }) => {
  const columns = [
    {
      title: 'Ejecutivo',
      width: 120,
      render: ({ commercialExecutive }: OrganizationAnalyticsSummaryCommercialExecutive) =>
        commercialExecutive ? `${commercialExecutive.name} ${commercialExecutive.lastName}` : '',
    },
    {
      title: 'Lotes',
      render: ({ totals }: OrganizationAnalyticsSummaryCommercialExecutive) =>
        NumberUtils.localize(totals.lots),
    },
    {
      title: 'Ha',
      render: ({ totals }: OrganizationAnalyticsSummaryCommercialExecutive) =>
        NumberUtils.localize(NumberUtils.roundedNumber(totals.size)),
    },
  ]

  return (
    <CardSection title="Ejecutivos comerciales">
      {isLoading ? (
        <LoaderContainer $minHeight="352px" align="middle" justify="center">
          <Loader />
        </LoaderContainer>
      ) : (
        <Table
          pagination={false}
          columns={columns}
          dataSource={commercialExecutives}
          scroll={{ y: 291 }}
          {...{ rowKey: ({ commercialExecutive }) => commercialExecutive?.id }}
          locale={{
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sin información" />,
          }}
        />
      )}
    </CardSection>
  )
}

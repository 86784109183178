import { Space } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useEffect, useState } from 'react'
import { isNumber } from 'lodash'

import { CardSection, UserAvatar, Table } from 'components'
import { useFetchCommercialExecutives } from 'hooks/useCommercialExecutives/hooks'
import { NumberUtils } from 'utils'

import { getMonths } from './utils'
import { InputContainer, InputTitle, Text, Input } from './components'
import { useAssignCommercialExecutiveGoal } from './hooks'
import { CardContainer } from '..'

type YearMonthKey = `${number}-${number}`

type UserGoals = { goal?: number; contributionGoal?: number }

type UserMonthData = {
  id: number
  profilePicture?: string
  fullName: string
  [key: YearMonthKey]: UserGoals
}

// With this function we will have an array of months data with the last month in the position 0, the current
// month in the position 1 and the following months according to the number passed by parameter
const monthsData = getMonths(5)

export const GoalsCard = () => {
  const { commercialExecutives } = useFetchCommercialExecutives({ includeGoals: true })
  const { assignGoal } = useAssignCommercialExecutiveGoal()
  const [usersMonthData, setUsersMonthData] = useState<UserMonthData[]>([])

  useEffect(() => {
    if (!commercialExecutives) return

    const mappedCommercialExecutives =
      commercialExecutives?.map(({ id, name, lastName, profilePicture, organizationRole }) => {
        const goals = organizationRole?.goals ?? []

        const goalsOptions = goals.reduce(
          (acc: { [key: YearMonthKey]: UserGoals }, { yearMonth, goal, contributionGoal }) => {
            const yearMonthIndex = yearMonth as YearMonthKey

            // eslint-disable-next-line no-param-reassign
            acc[yearMonthIndex] = {
              goal: goal ?? undefined,
              contributionGoal: contributionGoal ?? undefined,
            }

            return acc
          },
          {},
        )

        return {
          id,
          fullName: `${name} ${lastName}`,
          profilePicture: profilePicture ?? undefined,
          ...goalsOptions,
        }
      }) ?? []

    setUsersMonthData(mappedCommercialExecutives)
  }, [commercialExecutives])

  const goalsValueParser = (userData: UserMonthData, yearMonth: string) => {
    const yearMonthIndex = yearMonth as YearMonthKey
    const { goal, contributionGoal } = userData[yearMonthIndex] ?? {}

    return {
      goal: isNumber(goal) ? NumberUtils.localize(goal) : '',
      contributionGoal: isNumber(contributionGoal) ? NumberUtils.localize(contributionGoal) : '',
    }
  }

  const isFirstColumn = (index: number) => index === 0

  const columns: ColumnsType<UserMonthData> = [
    {
      title: 'Usuario',
      render: (user: UserMonthData) => (
        <Space size={20}>
          <UserAvatar src={user.profilePicture} />
          <Text>{user.fullName}</Text>
        </Space>
      ),
      width: '200px',
      fixed: true,
    },
    ...monthsData.map((value, index) => ({
      ...value,
      width: '370px',
      render: (_: UserGoals, userData: UserMonthData) => {
        const parsedGoals = goalsValueParser(userData, value.dataIndex)
        return (
          <Space size={10}>
            <InputContainer>
              <InputTitle>Ventas:</InputTitle>
              <Input
                isDisabled={isFirstColumn(index)}
                placeholder={isFirstColumn(index) ? 'Sin objetivo' : 'Objetivo'}
                defaultValue={parsedGoals.goal}
                addonBefore={isFirstColumn(index) ? undefined : '$'}
                onValueChange={newValue => {
                  assignGoal({
                    variables: {
                      assignGoalDTO: {
                        commercialExecutiveId: userData.id,
                        yearMonth: value.dataIndex,
                        goal: Number(NumberUtils.keepOnlyNumbers(newValue)) || null,
                      },
                    },
                  })
                }}
              />
            </InputContainer>
            <InputContainer>
              <InputTitle>Contribuciones:</InputTitle>
              <Input
                isDisabled={isFirstColumn(index)}
                placeholder={isFirstColumn(index) ? 'Sin objetivo' : 'Objetivo'}
                defaultValue={parsedGoals.contributionGoal}
                addonBefore={isFirstColumn(index) ? undefined : '$'}
                onValueChange={newValue => {
                  assignGoal({
                    variables: {
                      assignGoalDTO: {
                        commercialExecutiveId: userData.id,
                        yearMonth: value.dataIndex,
                        contributionGoal: Number(NumberUtils.keepOnlyNumbers(newValue)) || null,
                      },
                    },
                  })
                }}
              />
            </InputContainer>
          </Space>
        )
      },
    })),
  ]

  return (
    <CardContainer>
      <CardSection title="Objetivo mensual">
        <Table
          columns={columns}
          dataSource={usersMonthData}
          pagination={false}
          scroll={{ x: 1000, y: '50vh' }}
          rowKey={'id'}
        />
      </CardSection>
    </CardContainer>
  )
}

import { Table as AntTable } from 'antd'
import styled from 'styled-components'

import { NumberUtils } from 'utils'
import { HarvestedCalendarLots } from 'features/MainRouter/features/HarvestStats/hooks/useHarvestedCalendar'

import { useTableResults } from './hooks'

interface ResultData {
  id: number
  date: string
  companyName: string
  fieldName: string
  lotName: string
  area: number
  crop: string
  variety: string
  productionType: string
  grainType: string
  deliveryType: string
  estimatedProduction: number
}

const Table = styled(AntTable)<ResultData>`
  th {
    background: transparent !important;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.baseColor[200]}`} !important;
    font-size: 16px;
    text-align: center;
    font-weight: 700 !important;
  }
  td {
    font-size: 13px;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.baseColor[200]}`} !important;
  }
  .ant-table-body {
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.baseColor[900]};
      background-clip: padding-box;
    }
  }
` as unknown as typeof AntTable

interface Props {
  data: HarvestedCalendarLots[]
}

export const DayTable: React.FC<Props> = ({ data }) => {
  const { tableData } = useTableResults(data)

  const columns = [
    {
      title: 'Productor',
      dataIndex: 'companyName',
      width: 200,
    },
    {
      title: 'Campo',
      dataIndex: 'fieldName',
      width: 150,
    },
    {
      title: 'Lote',
      dataIndex: 'lotName',
      width: 120,
    },
    {
      title: 'Superficie',
      dataIndex: 'area',
      render: (value: ResultData['area']) => `${NumberUtils.localize(value)} ha`,
      width: 115,
    },

    {
      title: 'Cultivo',
      dataIndex: 'crop',
      width: 110,
    },
    {
      title: 'Variedad',
      dataIndex: 'variety',
      width: 115,
    },

    {
      title: 'Tipo de producción',
      dataIndex: 'productionType',
      width: 170,
    },
    {
      title: 'Tipo de grano',
      dataIndex: 'grainType',
      width: 150,
    },
    {
      title: 'Tipo de entrega',
      dataIndex: 'deliveryCondition',
      width: 160,
    },
    {
      title: 'T. Est.',
      dataIndex: 'estimatedProduction',
      width: 100,
      render: (value: ResultData['estimatedProduction']) =>
        `${NumberUtils.localize(value, { noFractionDigits: true })} t`,
    },
  ]

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={tableData}
      pagination={false}
      scroll={{ x: 1500, y: '60vh' }}
    />
  )
}

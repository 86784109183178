import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth, useCurrentUser } from 'hooks'
import { UserOrganizationRole } from 'types'

interface Props {
  children: React.ReactElement
  roles?: UserOrganizationRole[]
}
export const PrivateRoute: React.FC<Props> = ({ children, roles }) => {
  const { userId } = useAuth()
  const { role } = useCurrentUser()

  if (!userId) return <Navigate to={'/auth'} replace />

  if (roles?.length && (!role || !roles.includes(role))) {
    return <Navigate to={'/dashboard'} />
  }

  return children
}

import { useQuery } from '@apollo/client'

import { ORGANIZATION_USERS, OrganizationUsers } from './api'

export const useOrganizationUsers = () => {
  const { data, loading, refetch } = useQuery<OrganizationUsers>(ORGANIZATION_USERS)

  return {
    loading,
    users: data?.organization.usersRoles,
    invitations: data?.organization.invitations,
    refetch,
  }
}

import { Col } from 'antd'
import styled from 'styled-components'

export { Chart } from './Chart'

export const SummaryCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`

export const ManagersDetails = styled(Col)`
  display: flex;
  justify-content: space-around;
  gap: 20px;
  padding: 0px 5px;
  @media (min-width: 1280px) {
    width: 250px;
    flex-direction: column;
    border-right: ${({ theme }) => `2px solid ${theme.colors.baseColor[100]}`};
  }
  @media (min-width: 1600px) {
    min-width: 300px;
  }
`
export const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 350px;
`

export const ChartsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 20px;
  @media (min-width: 1280px) {
    min-width: 70%;
  }
`

export const LoaderContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`

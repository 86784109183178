import { Typography, Row, Col } from 'antd'
import styled, { useTheme } from 'styled-components'

import { satelliteImage } from 'assets/images'
import { Loader } from 'components'

const { Text: AntText } = Typography

const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.black} !important;
  font-size: 14px !important;
  font-weight: 500;
`

const Image = styled.img`
  width: 55px;
  height: 55px;
`

const StyledCol = styled(Col)`
  padding: 0 5px;
`

const Container = styled(Row)`
  background-color: ${({ theme }) => theme.colors.iconColor};
  width: calc(35% - 81px);
  position: absolute;
  padding: 15px;
  top: 40px;
  left: 60%;
  transform: translate(-40%);
  z-index: 10;
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
`

export const LotsLoader: React.FC = () => {
  const { colors } = useTheme()
  return (
    <Container justify="space-around" align="middle">
      <Col>
        <Image src={satelliteImage} alt="Satélite" />
      </Col>
      <StyledCol span={12}>
        <Text>Estamos cargando la información de los lotes de tus clientes</Text>
      </StyledCol>
      <Col>
        <Loader color={colors.baseColor[600]} />
      </Col>
    </Container>
  )
}

import { Row, Space } from 'antd'
import styled from 'styled-components'

export { DetailsCard } from './DetailsCard'
export { CommercialExecutivesCard } from './CommercialExecutivesCard'
export { VarietyStatsCard } from './VarietyStatsCard'
export { PlaceholderCard } from './PlaceholderCard'
export { CompaniesCard } from './CompaniesCard'
export { EmergenceDatesCard } from './EmergenceDatesCard'

interface Props {
  $minHeight: string
}
export const LoaderContainer = styled(Row)<Props>`
  height: 100%;
  min-height: ${({ $minHeight }) => $minHeight};
`
export const Container = styled(Space)`
  width: 100%;
`

import { isNumber, sortBy } from 'lodash'
import dayjs from 'dayjs'

import { OrganizationStats } from 'features/MainRouter/features/HarvestStats/hooks/useHarvestStats/api'
import { useFormatNumbersWithScale } from 'hooks'

interface Props {
  harvestStats?: OrganizationStats['organizationStats']
}

export const useTableData = ({ harvestStats }: Props) => {
  const titles = ['harvested', 'tonnes']
  const keyInTonnes = ['subTotalYieldByDeliveryCondition', 'notEmergedYield', 'yield']
  const { formatNumberToLocale } = useFormatNumbersWithScale()

  const getTableStatsKeys = (harvestData?: OrganizationStats['organizationStats']): string[] => {
    const yieldDeliveredConditionKeys = new Set<string>()

    const fortnightsKeys = new Set<string>()

    harvestData?.statsByProduction.forEach(({ statsByGrainType }) => {
      statsByGrainType.forEach(({ stats }) => {
        if (stats.fortnightsStats) {
          stats.fortnightsStats.byArea.forEach(item => fortnightsKeys.add(item.key))

          stats.fortnightsStats.byYield.forEach(item => fortnightsKeys.add(item.key))
        }
        if (stats.yieldByDeliveryCondition) {
          const yieldKeys = stats.yieldByDeliveryCondition.map(
            ({ deliveryCondition }) => `deliveryCondition-${deliveryCondition}`,
          )
          if (yieldKeys.length)
            yieldKeys.forEach(yieldKey => yieldDeliveredConditionKeys.add(yieldKey))
        }
      })
    })

    const sortedFortnights = sortBy(Array.from(fortnightsKeys), (item: string) => {
      const [year, month, fortnight] = item.split('_')
      return [parseInt(year), parseInt(month), fortnight === 'FIRST' ? 1 : 2]
    })

    const yieldFortnights = sortedFortnights.map(fortnights => `fortnights-${fortnights}_YIELD`)
    const areaFortnights = sortedFortnights.map(fortnights => `fortnights-${fortnights}_AREA`)

    return [
      'area',
      'plantedArea',
      'emergedArea',
      'harvested',
      ...areaFortnights,
      'notEmergedArea',
      'tonnes',
      ...Array.from(yieldDeliveredConditionKeys),
      'subTotalYieldByDeliveryCondition',
      ...yieldFortnights,
      'notEmergedYield',
      'yield',
    ]
  }

  const statsKeys = getTableStatsKeys(harvestStats)

  interface StatsObject {
    statKey: string
    [grainAndProductionType: string]: string
  }

  const dataSource = [] as StatsObject[]
  statsKeys.forEach(key => {
    const obj: StatsObject = { statKey: key }
    const isKeyInTonnes = keyInTonnes.includes(key)
    const [statType, statKey] = key.split('-')
    const lastSeparator = statKey?.lastIndexOf('_')
    const fortnight = statKey?.slice(0, lastSeparator)
    const fortnightStat = statKey?.slice(lastSeparator + 1)
    harvestStats?.statsByProduction.forEach(production => {
      const totalProductionTypeKey = `${production.productionType}_TOTAL`

      if (statType === 'fortnights') {
        if (fortnightStat === 'AREA') {
          const area = production.totals.fortnightsStats.byArea.find(stat => stat.key === fortnight)
          obj[totalProductionTypeKey] = area ? area.value.toString() : '-'
        }
        if (fortnightStat === 'YIELD') {
          const yieldStat = production.totals.fortnightsStats.byYield.find(
            stat => stat.key === fortnight,
          )
          obj[totalProductionTypeKey] = yieldStat ? (yieldStat.value / 1000).toFixed() : '-'
        }
      } else if (statType === 'deliveryCondition') {
        const deliveryCondition = production.totals.yieldByDeliveryCondition.find(
          stat => stat.deliveryCondition === statKey,
        )
        obj[totalProductionTypeKey] = deliveryCondition
          ? (deliveryCondition.value / 1000).toFixed()
          : '-'
      } else {
        /* eslint-disable @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        const totalValue = production.totals[key]
        obj[totalProductionTypeKey] =
          isKeyInTonnes && isNumber(totalValue) ? (totalValue / 1000).toFixed() : totalValue || '-'
      }

      production.statsByGrainType.forEach(grain => {
        const { grainType, stats } = grain
        const productionTypeKey = `${production.productionType}_${grainType}`

        if (titles.includes(key)) return

        if (statType === 'fortnights') {
          if (fortnightStat === 'AREA') {
            const area = stats.fortnightsStats.byArea.find(stat => stat.key === fortnight)
            obj[productionTypeKey] = area ? area.value.toString() : '-'
          }
          if (fortnightStat === 'YIELD') {
            const yieldStat = stats.fortnightsStats.byYield.find(stat => stat.key === fortnight)
            obj[productionTypeKey] = yieldStat ? (yieldStat.value / 1000).toFixed() : '-'
          }
        } else if (statType === 'deliveryCondition') {
          const deliveryCondition = stats.yieldByDeliveryCondition.find(
            stat => stat.deliveryCondition === statKey,
          )
          obj[productionTypeKey] = deliveryCondition
            ? (deliveryCondition.value / 1000).toFixed()
            : '-'
        } else {
          /* eslint-disable @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          const value = stats[key]
          obj[productionTypeKey] =
            isKeyInTonnes && isNumber(value) ? (value / 1000).toFixed() : value || '-'
        }
      })
    })

    const totalKey = 'TOTALS'

    if (statType === 'fortnights') {
      if (fortnightStat === 'AREA') {
        const area = harvestStats?.totals.fortnightsStats.byArea.find(
          stat => stat.key === fortnight,
        )
        obj[totalKey] = area ? area.value.toString() : '-'
      }
      if (fortnightStat === 'YIELD') {
        const yieldStat = harvestStats?.totals.fortnightsStats.byYield.find(
          stat => stat.key === fortnight,
        )
        obj[totalKey] = yieldStat ? (yieldStat.value / 1000).toFixed() : '-'
      }
    } else if (statType === 'deliveryCondition') {
      const deliveryCondition = harvestStats?.totals.yieldByDeliveryCondition.find(
        stat => stat.deliveryCondition === statKey,
      )
      obj[totalKey] = deliveryCondition ? (deliveryCondition.value / 1000).toFixed() : '-'
    } else {
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      const totalValue = harvestStats?.totals[key]
      obj[totalKey] =
        isKeyInTonnes && isNumber(totalValue) ? (totalValue / 1000).toFixed() : totalValue || '-'
    }

    dataSource.push(obj)
  })

  const NAMES_MAP: { [key: string]: string } = {
    area: 'Sup. total (ha)',
    plantedArea: 'Sup. sembrado (ha)',
    emergedArea: 'Sup. emergido (ha)',
    notEmergedYield: 'No emergido (t)',
    yield: 'Total (t)',
    tonnes: 'Volumen estimado',
    harvested: 'Superficie según fecha estimada de cosecha',
    subTotalYieldByDeliveryCondition: 'Sub total (t)',
    notEmergedArea: 'No emergido (ha)',
  }

  const parseFortnightName = (name: string): string => {
    const [, month, fortnight] = name.split('_')
    const fortnightName = fortnight === 'FIRST' ? '1ra' : '2da'
    const date = dayjs().month(Number(month))
    const monthName = date.locale('es-ES').format('MMM')
    return `${fortnightName} Q ${monthName}`
  }

  const parseName = (name: string) => {
    const [statType, statKey] = name.split('-')

    if (statType === 'fortnights') {
      const lastSeparator = statKey.lastIndexOf('_')
      const fortnight = statKey.slice(0, lastSeparator)
      const fortnightStat = statKey.slice(lastSeparator + 1)
      const formattedFortnight = parseFortnightName(fortnight)
      if (fortnightStat === 'AREA') {
        return `${formattedFortnight} (ha)`
      }
      if (fortnightStat === 'YIELD') {
        return `${formattedFortnight} (t)`
      }
    } else if (statType === 'deliveryCondition') {
      return statKey === 'WET' ? 'Arroz húmedo (t)' : 'Arroz seco (t)'
    }

    return NAMES_MAP[name]
  }

  const sharedOnCell = (columnData: StatsObject) => {
    if (titles.includes(columnData.statKey)) {
      return { colSpan: 0 }
    }

    return {}
  }

  const columns = [
    {
      dataIndex: 'statKey',
      render: parseName,
      onCell: ({ statKey }: StatsObject) => ({ colSpan: titles.includes(statKey) ? 14 : 1 }),
    },
    {
      title: 'Bio',
      children: [
        {
          title: 'C',
          dataIndex: 'ORGANIC_SHORT',
          onCell: sharedOnCell,
          className: 'custom-table-secondary-columns',
          render: (value: string) => formatNumberToLocale(Number(value)),
        },
        {
          title: 'MC',
          dataIndex: 'ORGANIC_CRYSTAL_MEDIUM',
          onCell: sharedOnCell,
          className: 'custom-table-secondary-columns',
          render: (value: string) => formatNumberToLocale(Number(value)),
        },
        {
          title: 'MP',
          dataIndex: 'ORGANIC_PEARL_MEDIUM',
          onCell: sharedOnCell,
          className: 'custom-table-secondary-columns',
          render: (value: string) => formatNumberToLocale(Number(value)),
        },
        {
          title: 'LF',
          dataIndex: 'ORGANIC_LONG',
          onCell: sharedOnCell,
          className: 'custom-table-secondary-columns',
          render: (value: string) => formatNumberToLocale(Number(value)),
        },
        {
          title: 'SV',
          dataIndex: 'ORGANIC_NO_TYPE',
          onCell: sharedOnCell,
          className: 'custom-table-secondary-columns',
          render: (value: string) => formatNumberToLocale(Number(value)),
        },
        {
          title: 'Total',
          dataIndex: 'ORGANIC_TOTAL',
          onCell: sharedOnCell,
          className: 'custom-table-secondary-columns',
          render: (value: string) => formatNumberToLocale(Number(value)),
        },
      ],
    },
    {
      title: 'Convencional',
      children: [
        {
          title: 'C',
          dataIndex: 'CONVENTIONAL_SHORT',
          onCell: sharedOnCell,
          className: 'custom-table-primary-columns',
          render: (value: string) => formatNumberToLocale(Number(value)),
        },
        {
          title: 'MC',
          dataIndex: 'CONVENTIONAL_CRYSTAL_MEDIUM',
          onCell: sharedOnCell,
          className: 'custom-table-primary-columns',
          render: (value: string) => formatNumberToLocale(Number(value)),
        },
        {
          title: 'MP',
          dataIndex: 'CONVENTIONAL_PEARL_MEDIUM',
          onCell: sharedOnCell,
          className: 'custom-table-primary-columns',
          render: (value: string) => formatNumberToLocale(Number(value)),
        },
        {
          title: 'LF',
          dataIndex: 'CONVENTIONAL_LONG',
          onCell: sharedOnCell,
          className: 'custom-table-primary-columns',
          render: (value: string) => formatNumberToLocale(Number(value)),
        },
        {
          title: 'SV',
          dataIndex: 'CONVENTIONAL_NO_TYPE',
          onCell: sharedOnCell,
          className: 'custom-table-primary-columns',
          render: (value: string) => formatNumberToLocale(Number(value)),
        },
        {
          title: 'Total',
          dataIndex: 'CONVENTIONAL_TOTAL',
          onCell: sharedOnCell,
          className: 'custom-table-primary-columns',
          render: (value: string) => formatNumberToLocale(Number(value)),
        },
      ],
    },
    {
      dataIndex: 'TOTALS',
      title: 'Total',
      onCell: sharedOnCell,
      className: 'custom-table-gray-columns',
      render: (value: string) => formatNumberToLocale(Number(value)),
    },
  ]

  return { columns, dataSource }
}

import { useContext, useState, createContext, useEffect, useMemo } from 'react'

import { Subregion } from 'components/SubregionsSelect/api'
import { AnalyticsTimeMode, DateRangeInput, RiceLotStatus, Selects } from 'types'
import { CommercialExecutive } from 'hooks/useCommercialExecutives/api'
import { OrganizationCompany } from 'components/CompaniesSelect/api'
import { useUser } from 'hooks'

interface DetailsFiltersContextType {
  company?: OrganizationCompany
  setCompany: (company?: OrganizationCompany) => void
  futureDateRange?: DateRangeInput
  setFutureDateRange: (futureDateRange?: DateRangeInput) => void
  commercialExecutive?: CommercialExecutive
  setCommercialExecutive: (commercialExecutive?: CommercialExecutive) => void
  timeMode?: AnalyticsTimeMode.CURRENT
  setTimeMode: (timeMode?: AnalyticsTimeMode.CURRENT) => void
  subregion?: Subregion
  setSubregion: (subregion?: Subregion) => void
  lotStatus?: RiceLotStatus[]
  setLotStatus: (lotStatus: RiceLotStatus[]) => void
  organizationStatusMilestones: RiceLotStatus[]
  varietyId?: number | Selects
  setVarietyId: (varietyId?: number | Selects) => void
}

const Context = createContext<DetailsFiltersContextType>({
  commercialExecutive: undefined,
  setCommercialExecutive: () => {
    throw new Error(
      'Attempted to use default setCommercialExecutive function for DetailsFiltersContext, make sure to pass a custom function implementation',
    )
  },
  timeMode: AnalyticsTimeMode.CURRENT,
  setTimeMode: () => {
    throw new Error(
      'Attempted to use default setTimeMode function for DetailsFiltersContext, make sure to pass a custom function implementation',
    )
  },

  subregion: undefined,
  setSubregion: () => {
    throw new Error(
      'Attempted to use default setSubregion function for DetailsFiltersContext, make sure to pass a custom function implementation',
    )
  },
  futureDateRange: undefined,
  setFutureDateRange: () => {
    throw new Error(
      'Attempted to use default setFutureDateRange function for DetailsFiltersContext, make sure to pass a custom function implementation',
    )
  },
  lotStatus: undefined,
  setLotStatus: () => {
    throw new Error(
      'Attempted to use default setLotStatus function for DetailsFiltersContext, make sure to pass a custom function implementation',
    )
  },
  company: undefined,
  setCompany: () => {
    throw new Error(
      'Attempted to use default setCompany function for DetailsFiltersContext, make sure to pass a custom function implementation',
    )
  },
  organizationStatusMilestones: [],
  varietyId: undefined,
  setVarietyId: () => {
    throw new Error(
      'Attempted to use default setVarietyId function for DetailsFiltersContext, make sure to pass a custom function implementation',
    )
  },
})

const useValue = (): DetailsFiltersContextType => {
  const [company, setCompany] = useState<OrganizationCompany>()
  const [futureDateRange, setFutureDateRange] = useState<DateRangeInput>()
  const [commercialExecutive, setCommercialExecutive] = useState<CommercialExecutive | undefined>()
  const [timeMode, setTimeMode] = useState<AnalyticsTimeMode.CURRENT | undefined>(
    AnalyticsTimeMode.CURRENT,
  )
  const [subregion, setSubregion] = useState<Subregion>()
  const [lotStatus, setLotStatus] = useState<RiceLotStatus[]>([])
  const [varietyId, setVarietyId] = useState<number | Selects>()

  const user = useUser()

  const organizationStatusMilestones = useMemo(
    () => user?.organizationRole?.organization?.statusMilestones ?? [],
    [user],
  )

  useEffect(() => {
    if (organizationStatusMilestones?.length) {
      setLotStatus(organizationStatusMilestones)
    }
  }, [organizationStatusMilestones])

  return {
    commercialExecutive,
    setCommercialExecutive,
    timeMode,
    setTimeMode,
    subregion,
    setSubregion,
    futureDateRange,
    setFutureDateRange,
    lotStatus,
    setLotStatus,
    company,
    setCompany,
    organizationStatusMilestones,
    varietyId,
    setVarietyId,
  }
}

const useDetailsFiltersContext = () => {
  return useContext(Context)
}

export const DetailsFiltersContext = {
  Provider: Context.Provider,
  useValue,
  useDetailsFiltersContext,
}

import centroid from '@turf/centroid'
import { polygon } from '@turf/helpers'
import { useCallback, useRef, useState, Dispatch, SetStateAction, useEffect } from 'react'

import { DrawArea, DrawingOption, DrawMode as BaseDrawMode, Features, ToolMode } from '../types'
import { GeoUtils } from '../utils'

type DrawMode = Exclude<BaseDrawMode, 'direct_select'>

const drawModeMap: { [key in Exclude<DrawingOption, null>]: DrawMode } = {
  draw: 'draw_polygon',
  hide: 'simple_select',
  delete: 'simple_select',
  'delete-all': 'simple_select',
  reset: 'simple_select',
}

export const useDrawingTool = (
  toolMode: ToolMode,
  setToolMode: Dispatch<SetStateAction<ToolMode>>,
) => {
  const [drawnAreas, setDrawnAreas] = useState<DrawArea[]>([])
  const [drawingOption, _setDrawingOption] = useState<DrawingOption | null>(null)

  const drawingOptionRef = useRef(drawingOption)

  const setDrawingOption = (
    option: DrawingOption | null | ((prevState: DrawingOption | null) => DrawingOption | null),
  ) => {
    if (typeof option === 'function') {
      const newState = option(drawingOptionRef.current)
      drawingOptionRef.current = newState
      _setDrawingOption(newState)
    } else {
      drawingOptionRef.current = option
      _setDrawingOption(option)
    }
  }

  const onCreateFeature = useCallback(
    ({ features }: { features: Features }) => {
      if (!features?.length) return
      const createdFeature = features[0]
      const createdFeatureId = createdFeature.id
      if (!createdFeatureId) return

      setDrawnAreas(prevDrawnAreas => {
        if (prevDrawnAreas.find(drawArea => drawArea.feature?.id === createdFeatureId)) {
          return prevDrawnAreas
        }
        const polygonArea = polygon(features[0].geometry.coordinates)
        const polygonCenter = centroid(polygonArea)
        const area = Math.round(GeoUtils.size(polygonArea))
        return [...prevDrawnAreas, { feature: features[0], polygonCenter, area }]
      })
      setDrawingOption(null)
      setToolMode(null)
    },
    [setToolMode],
  )

  const onUpdateFeature = useCallback(({ features }: { features: Features }) => {
    if (!features?.length) return
    const updatedFeature = features[0]

    setDrawnAreas(prevDrawnAreas => {
      const newAreas = [...prevDrawnAreas]
      const updatedAreaIndex = newAreas.findIndex(
        newArea => newArea.feature.id === updatedFeature.id,
      )
      const polygonArea = polygon(updatedFeature.geometry.coordinates)
      const polygonCenter = centroid(polygonArea)
      const area = Math.round(GeoUtils.size(polygonArea))

      newAreas[updatedAreaIndex] = {
        ...newAreas[updatedAreaIndex],
        feature: updatedFeature,
        polygonCenter,
        area,
      }
      return newAreas
    })
  }, [])

  const onDeleteFeature = useCallback((featureId: string) => {
    if (drawingOptionRef.current === 'delete') {
      setDrawnAreas(prevDrawnAreas =>
        prevDrawnAreas.filter(prevDrawnArea => prevDrawnArea.feature.id !== featureId),
      )
      setDrawingOption(null)
    }
  }, [])

  const onDeleteAllFeatures = useCallback(() => {
    setDrawnAreas([])
    setDrawingOption(null)
    setToolMode(null)
  }, [setToolMode])

  const onDraw = useCallback(() => {
    setDrawingOption(prevState => (prevState === 'draw' ? null : 'draw'))
  }, [])

  const onHide = () => {
    setDrawingOption(prevState => (prevState === 'hide' ? null : 'hide'))
    setToolMode(null)
  }

  const onDelete = () => setDrawingOption(prevState => (prevState !== 'delete' ? 'delete' : null))

  const onReset = useCallback(() => {
    setDrawingOption(prevState => {
      if (prevState === 'draw' || prevState === 'delete') {
        setToolMode(null)
        return null
      }
      return prevState
    })
  }, [setToolMode])

  const drawingActionMap: { [key in Exclude<DrawingOption, null>]: () => void } = {
    draw: onDraw,
    hide: onHide,
    delete: onDelete,
    'delete-all': onDeleteAllFeatures,
    reset: onReset,
  }

  const onDrawingOptionSelected = (option: DrawingOption) => {
    if (!option) return
    const action = drawingActionMap[option]
    action()
  }

  const toggleDropdownVisibility = () => {
    if (drawingOption === 'draw' || drawingOption === 'delete') return
    setToolMode(prevMode => (prevMode === 'draw' ? null : 'draw'))
  }

  const drawnAreasFeatures = drawnAreas.map(({ feature }) => feature)
  const drawMode: DrawMode = drawingOption ? drawModeMap[drawingOption] : 'simple_select'

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (drawMode !== 'draw_polygon') return
      if (event.key === 'Backspace' || event.key === 'Delete') {
        setDrawingOption(null)
        onDraw()
      }
      if (event.key === 'Esc' || event.key === 'Escape') {
        setDrawingOption(null)
      }
    },
    [drawMode, onDraw],
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  return {
    drawnAreas,
    drawMode,
    drawnAreasFeatures,
    drawingOption,
    showDropdown: toolMode === 'draw',
    toggleDropdownVisibility,
    onDrawingOptionSelected,
    onCreateFeature,
    onUpdateFeature,
    onDeleteFeature,
  }
}

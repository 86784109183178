import styled from 'styled-components'
import { useCallback } from 'react'
import { Dayjs } from 'dayjs'
import spanishLanguage from 'antd/es/date-picker/locale/es_ES'
import { DatePicker as BaseDatePicker } from 'antd'
import 'dayjs/locale/es'

import { CalendarIcon, CalendarNextArrowIcon, CalendarPrevArrowIcon } from 'assets/icons'
import { DATE_FORMATS } from 'consts'

const locale = { ...spanishLanguage, lang: { ...spanishLanguage.lang, monthFormat: 'MMMM' } }

const StyledDatePicker = styled(BaseDatePicker)`
  width: 115px;
  height: 40px;
`

interface Props {
  placeholder: string
  disabled: boolean
  value: Dayjs | null
  onChange: (date: Dayjs | null) => void
  startDate?: Dayjs | null
}

export const DatePicker: React.FC<Props> = ({
  placeholder,
  disabled,
  value,
  onChange,
  startDate,
}) => {
  const disabledDate = useCallback(
    (date: Dayjs) => {
      if (!startDate) return false
      return startDate && date.isBefore(startDate)
    },
    [startDate],
  )

  return (
    <StyledDatePicker
      nextIcon={<CalendarNextArrowIcon />}
      prevIcon={<CalendarPrevArrowIcon />}
      clearIcon={false}
      allowClear={false}
      suffixIcon={<CalendarIcon />}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={onChange}
      disabledDate={disabledDate}
      format={DATE_FORMATS.SHORT_YEAR_SLASH_DATE}
      locale={locale}
    />
  )
}

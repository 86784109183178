import { gql } from '@apollo/client'

export type { AssignClient, AssignClientVariables } from './types/AssignClient'

export const ASSIGN_CLIENT = gql`
  mutation AssignClient($assignClientDTO: AssignClientInput!) {
    assignClient(assignClientDTO: $assignClientDTO) {
      success
    }
  }
`

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { VALIDATIONS } from 'consts'

export const useForgotPasswordForm = () => {
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(VALIDATIONS.LOCALES.VALID_EMAIL)
      .required(VALIDATIONS.LOCALES.REQUIRED),
  })

  return useForm({ resolver: yupResolver(validationSchema) })
}

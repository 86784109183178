import { Marker } from 'react-map-gl'

import { DrawArea, DrawingOption } from '../../types'
import { TooltipText, TooltipTextContainer } from './components'

interface Props {
  drawnAreas: DrawArea[]
  drawingOption: DrawingOption
}

export const DrawnAreasMarker: React.FC<Props> = ({ drawnAreas, drawingOption }) => {
  return (
    <>
      {drawingOption !== 'hide' &&
        drawnAreas?.map(({ area, feature, polygonCenter }) => (
          <Marker
            key={feature.id}
            latitude={polygonCenter.geometry.coordinates[1]}
            longitude={polygonCenter.geometry.coordinates[0]}
            draggable={false}
          >
            <TooltipTextContainer>
              <TooltipText>{`${area} ha`}</TooltipText>
            </TooltipTextContainer>
          </Marker>
        ))}
    </>
  )
}

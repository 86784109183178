import { Collapse as AntCollapse } from 'antd'
import { groupBy, orderBy } from 'lodash'
import dayjs from 'dayjs'

import { DATE_FORMATS } from 'consts'
import { HarvestedCalendarLots } from 'features/MainRouter/features/HarvestStats/hooks/useHarvestedCalendar'

import { DayTable } from './components'

interface Props {
  data?: HarvestedCalendarLots[]
}

export const Collapse: React.FC<Props> = ({ data }) => {
  const orderedDates = orderBy(data, ['lot.riceLot.harvestDate'], ['asc'])
  const lots = groupBy(orderedDates, 'lot.riceLot.harvestDate')

  const items = Object.entries(lots).map(([date, lotsData], index) => {
    return {
      key: index,
      label: dayjs(date).format(DATE_FORMATS.FULL_DATE),
      children: <DayTable data={lotsData} />,
    }
  })

  return <AntCollapse items={items} defaultActiveKey={[0]} />
}

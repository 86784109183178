import React from 'react'
import { Row, Upload as AntUpload, notification } from 'antd'
import ImgCrop from 'antd-img-crop'

import { ImageViewer } from './components'

const MAX_UPLOAD_SIZE = 4 // In MB

interface Props {
  image?: string
  onChange: (image: string, file: File) => void
  allowCrop?: boolean
}

export const Upload: React.FC<Props> = ({ image, onChange, allowCrop = false }) => {
  const onUploadChange = (file: File) => {
    if (!isValidUpload(file)) return
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      onChange(reader.result as string, file)
    })
    reader.readAsDataURL(file)
  }

  const isValidUpload = (file: File) => {
    if (!file.size) return false
    const isLowerThanMaxMB = file.size / 1024 / 1024 < MAX_UPLOAD_SIZE
    if (!isLowerThanMaxMB) {
      notification.error({
        message: `La imagen cargada es muy pesada. Sólo puedes subir imágenes de menos de ${MAX_UPLOAD_SIZE} MB`,
      })
    }
    return isLowerThanMaxMB
  }

  const upload = (
    <AntUpload
      name="logo"
      accept="image/*"
      showUploadList={false}
      maxCount={1}
      customRequest={({ file }) => onUploadChange(file as File)}
    >
      <ImageViewer image={image} />
    </AntUpload>
  )

  return <Row>{allowCrop ? <ImgCrop>{upload}</ImgCrop> : upload}</Row>
}

import { OrganizationFeatures } from 'types'

const DOCUMENT_TITLE_BY_FEATURES: {
  [key in OrganizationFeatures]: string
} = {
  [OrganizationFeatures.CRM]: 'Kuna - Tablero comercial',
  [OrganizationFeatures.SUB_FARMERS_MANAGEMENT]: 'Kuna - Gestión de sub productores',
  [OrganizationFeatures.SUPPLY]: 'Kuna',
}

export const WEB = {
  DOCUMENT_TITLE_BY_FEATURES,
}

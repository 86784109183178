import { useMemo } from 'react'

import { OrganizationFeatures, UserOrganizationRole } from 'types'

import { useUser } from './useUser'

const ASSIGNABLE_REGION_ROLES = {
  [OrganizationFeatures.CRM]: [
    UserOrganizationRole.ADMIN,
    UserOrganizationRole.COMMERCIAL,
    UserOrganizationRole.SALES_REP,
  ],
  [OrganizationFeatures.SUB_FARMERS_MANAGEMENT]: [
    UserOrganizationRole.COMMERCIAL,
    UserOrganizationRole.SALES_REP,
  ],
  [OrganizationFeatures.SUPPLY]: [UserOrganizationRole.COMMERCIAL, UserOrganizationRole.SALES_REP],
}

export const useFeatures = () => {
  const user = useUser()

  const featureFlags = user?.organizationRole?.organization?.featureFlags

  const featureSetId = user?.organizationRole?.organization?.featureSetId

  const organizationFeatures = useMemo(() => {
    const features: OrganizationFeatures[] = []

    const { crm, subFarmersManagement, supply } = featureFlags ?? {}

    if (crm?.enabled) features.push(OrganizationFeatures.CRM)
    if (subFarmersManagement?.enabled) features.push(OrganizationFeatures.SUB_FARMERS_MANAGEMENT)
    if (supply?.enabled) features.push(OrganizationFeatures.SUPPLY)

    return features
  }, [featureFlags])

  const assignableRegionRoles = useMemo(() => {
    if (!organizationFeatures.length) return []

    return ASSIGNABLE_REGION_ROLES[organizationFeatures[0]]
  }, [organizationFeatures])

  return {
    featureFlags: {
      general: featureFlags?.general,
      crm: featureFlags?.crm,
      subFarmersManagement: featureFlags?.subFarmersManagement,
      supply: featureFlags?.supply,
    },
    featureSetId,
    organizationFeatures,
    isCommercialNameEnabled: user?.organizationRole?.organization.isCommercialNameEnabled,
    assignableRegionRoles,
  }
}

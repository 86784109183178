import { Button, Col, Space } from 'antd'
import styled, { useTheme } from 'styled-components'
import { useState } from 'react'

import { CardSection, Loader } from 'components'
import { ItemInfo } from 'features/MainRouter/components'
import { Ball } from 'components'

import { LoaderContainer as Container } from '..'
import { Chart } from './components'

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  min-height: 350px;
`

const StyledButton = styled(Button)`
  background-color: transparent !important;
  & :hover {
    background-color: transparent !important;
  }
`

const MarginCol = styled(Col)`
  margin-top: 10px;
`

type ChartDataObject = {
  value: number
}

interface Props {
  lotsQtyData: ChartDataObject[]
  sizeData: ChartDataObject[]
  isLoading: boolean
}
type ViewActive = 'lots' | 'area'

export const EmergenceDatesCard: React.FC<Props> = ({ lotsQtyData, isLoading, sizeData }) => {
  const [viewActive, setViewActive] = useState<ViewActive>('lots')
  const isLotsViewActive = viewActive === 'lots'
  const isAreaViewActive = viewActive === 'area'
  const { colors } = useTheme()

  const data = isLotsViewActive ? lotsQtyData : sizeData
  return (
    <CardSection title="Fechas de emergencia">
      {isLoading ? (
        <Container $minHeight="352px" align="middle" justify="center">
          <Loader />
        </Container>
      ) : (
        <StyledCol>
          <Chart data={data} />
          <MarginCol>
            <ItemInfo
              Icon={<Ball background={colors.primary[500]} />}
              title={'Con fecha de emergencia'}
              data={data[0].value.toFixed()}
            />
          </MarginCol>
          <MarginCol>
            <ItemInfo
              Icon={<Ball background={colors.baseColor[700]} />}
              title={'Sin fecha de emergencia'}
              data={data[1].value.toFixed()}
            />
          </MarginCol>
          <MarginCol>
            <Space size={16}>
              <StyledButton
                type={isLotsViewActive ? 'default' : 'text'}
                onClick={() => setViewActive('lots')}
              >
                Lotes
              </StyledButton>
              <StyledButton
                type={isAreaViewActive ? 'default' : 'text'}
                onClick={() => setViewActive('area')}
              >
                Hectáreas
              </StyledButton>
            </Space>
          </MarginCol>
        </StyledCol>
      )}
    </CardSection>
  )
}

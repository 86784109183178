import styled from 'styled-components'

const List = styled.ul`
  display: flex;
  padding: 5;
  justify-content: center;
  font-weight: bold;
`

const Element = styled.li`
  margin: 0 10px;
`

export const Footer = () => {
  const list = [
    'C: Corto',
    'MC: Medio Cristalino',
    'MP: Medio Perlado',
    'LF: Largo Fino',
    'SV: Sin variedad asignada',
  ]

  return (
    <List>
      {list.map(listData => (
        <Element key={listData}>{listData}</Element>
      ))}
    </List>
  )
}

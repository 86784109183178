import { Control } from 'react-hook-form'

import { FormInput } from 'components'
import { EmailIcon } from 'assets/icons'

interface Props {
  //TODO: control is not optional, change it when we integrate this component everywhere.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>
  iconStyle?: React.CSSProperties
  defaultValue?: string
  size?: 'small' | 'middle' | 'large'
  isReadOnly?: boolean
}

export const FormEmailInput: React.FC<Props> = ({
  iconStyle,
  defaultValue,
  isReadOnly = false,
  control,
  ...restProps
}) => {
  return (
    <FormInput
      {...restProps}
      disabled={isReadOnly}
      placeholder={'Email'}
      defaultValue={defaultValue}
      prefixIcon={<EmailIcon style={iconStyle} />}
      name="email"
      control={control}
    />
  )
}

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { VALIDATIONS } from 'consts'

export const useChangePasswordForm = () => {
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(
        VALIDATIONS.PASSWORD_MIN_LENGTH,
        VALIDATIONS.LOCALES.MIN_LENGTH(VALIDATIONS.PASSWORD_MIN_LENGTH),
      )
      .required(VALIDATIONS.LOCALES.REQUIRED),
  })

  return useForm({ resolver: yupResolver(validationSchema) })
}

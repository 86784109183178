import { RadioChangeEvent } from 'antd'
import { Dayjs } from 'dayjs'
import { uniqBy } from 'lodash'

import {
  CommercialExecutivesSelect,
  SubregionsSelect,
  DateRangePicker,
  LotStatusSelect,
  CompaniesSelect,
  VarietiesSelect,
} from 'components'
import { useCurrentUser, useFeatures } from 'hooks'
import { RiceLotStatus, Selects, UserOrganizationRole } from 'types'
import { CommercialExecutive } from 'hooks/useCommercialExecutives/api'
import { Subregion } from 'components/SubregionsSelect/api'
import { OrganizationCompany } from 'components/CompaniesSelect/api'
import { CropFilterContext, HistoricalSeasonFilterContext } from 'contexts'
import { Region } from 'hooks/useRegions/api'

import { DateType } from '../../types'
import {
  ColWithTitle,
  ButtonContainer,
  Button,
  Radio,
  FiltersContainer,
  DatePickerContainer,
} from './components'

interface Props {
  radioValue: DateType
  onChangeRadio: (e: RadioChangeEvent) => void
  onChangeStartDate: (date: Dayjs | null) => void
  onChangeEndDate: (date: Dayjs | null) => void
  startDate: Dayjs | null
  endDate: Dayjs | null
  onChangeCompany: (value?: OrganizationCompany) => void
  onChangeCommercialExecutive: (value?: CommercialExecutive) => void
  selectedLotStatus: RiceLotStatus[]
  selectedCompany?: OrganizationCompany
  selectedSubregion?: Subregion
  selectedCommercialExecutive?: CommercialExecutive
  onChangeLotStatus: (value: RiceLotStatus[]) => void
  onApplyFilters: () => void
  onDeleteFilters: () => void
  onSubregionChange: (subregion?: Subregion) => void
  selectedVarietyId?: number | Selects
  onVarietyChange: (varietyId?: number | Selects) => void
  region?: Region
  subregion?: Subregion
}

const ONLY_ONE_COMMERCIAL_EXECUTIVE = 1

export const FiltersCard: React.FC<Props> = ({
  onChangeRadio,
  radioValue,
  onChangeEndDate,
  onChangeStartDate,
  endDate,
  startDate,
  onChangeCompany,
  onApplyFilters,
  onDeleteFilters,
  selectedLotStatus,
  selectedCompany,
  selectedCommercialExecutive,
  onChangeLotStatus,
  onChangeCommercialExecutive,
  selectedSubregion,
  onSubregionChange,
  selectedVarietyId,
  onVarietyChange,
  region,
  subregion,
}) => {
  const { role } = useCurrentUser()
  const { featureFlags } = useFeatures()
  const { selectedCrop } = CropFilterContext.useCropFilterContext()
  const { selectedHistoricalSeason } =
    HistoricalSeasonFilterContext.useHistoricalSeasonFilterContext()

  const commercialExecutiveOptionsWithDuplicates = selectedCompany?.seasons
    ?.filter(season => {
      return season.commercialExecutive
    })
    .map(({ commercialExecutive }) => {
      return {
        value: commercialExecutive!.id,
        label: `${commercialExecutive?.name ?? ''} ${commercialExecutive?.lastName ?? ''}`,
      }
    })

  const commercialExecutiveOptionsBySelectedCompany = uniqBy(
    commercialExecutiveOptionsWithDuplicates,
    'value',
  )

  const hasSelectedCompanyOnlyOneCommercialExecutive =
    commercialExecutiveOptionsBySelectedCompany?.length === ONLY_ONE_COMMERCIAL_EXECUTIVE
  const selectedCommercialExecutiveValue = hasSelectedCompanyOnlyOneCommercialExecutive
    ? commercialExecutiveOptionsBySelectedCompany[0].value
    : selectedCommercialExecutive?.id

  return (
    <>
      <FiltersContainer>
        <ColWithTitle title="Estado">
          <LotStatusSelect onChange={onChangeLotStatus} value={selectedLotStatus} />
        </ColWithTitle>

        <ColWithTitle title="Fecha">
          <DatePickerContainer>
            <Radio.Group onChange={onChangeRadio} value={radioValue} size="large">
              <Radio value={DateType.CURRENT}>Actual</Radio>
              <Radio value={DateType.NEXT}>Otra</Radio>
            </Radio.Group>
            <DateRangePicker
              isCurrentPickerDisabled={radioValue === DateType.CURRENT}
              isFuturePickerDisabled={radioValue === DateType.CURRENT}
              onChangeStartDate={onChangeStartDate}
              onChangeEndDate={onChangeEndDate}
              startDate={startDate}
              endDate={endDate}
              horizontalSpacing={5}
            />
          </DatePickerContainer>
        </ColWithTitle>

        <ColWithTitle title="Productor">
          <CompaniesSelect
            onChange={onChangeCompany}
            value={selectedCompany?.id}
            selectedCommercialExecutiveId={selectedCommercialExecutive?.id}
          />
        </ColWithTitle>
        {featureFlags.crm?.enabled && role !== UserOrganizationRole.MILL && (
          <ColWithTitle title={'Ejec. Comercial'}>
            <CommercialExecutivesSelect
              value={selectedCommercialExecutiveValue}
              isDisabled={hasSelectedCompanyOnlyOneCommercialExecutive}
              commercialExecutiveOptionsBySelectedCompany={
                commercialExecutiveOptionsBySelectedCompany.length
                  ? commercialExecutiveOptionsBySelectedCompany
                  : undefined
              }
              onChange={onChangeCommercialExecutive}
            />
          </ColWithTitle>
        )}
        <ColWithTitle title="Variedad">
          <VarietiesSelect
            onChange={onVarietyChange}
            value={selectedVarietyId}
            cropId={selectedCrop?.id}
            commercialExecutiveId={selectedCommercialExecutive?.id}
            seasonStartRange={selectedHistoricalSeason?.startRange}
            seasonEndRange={selectedHistoricalSeason?.endRange}
            regionId={region?.id}
            subregionId={subregion?.id}
          />
        </ColWithTitle>
        {role === UserOrganizationRole.MILL && (
          <ColWithTitle title={'Sub-zona'}>
            <SubregionsSelect
              onChange={onSubregionChange}
              value={selectedCompany?.subregionId ?? selectedSubregion?.id}
              isDisabled={!!selectedCompany?.subregionId}
            />
          </ColWithTitle>
        )}
        <ButtonContainer>
          <Button type="primary" onClick={onApplyFilters}>
            Aplicar filtros
          </Button>
          <Button onClick={onDeleteFilters}>Borrar filtros</Button>
        </ButtonContainer>
      </FiltersContainer>
    </>
  )
}

import { useQuery } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { HISTORICAL_SEASONS, HistoricalSeasons } from './api'

export const useHistoricalSeasons = () => {
  const { data, loading } = useQuery<HistoricalSeasons>(HISTORICAL_SEASONS, {
    onError: GraphQLUtils.errorHandler,
  })

  return { historicalSeasons: data?.historicalSeasons, loading }
}

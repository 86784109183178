import { Col, Row } from 'antd'

import { CardSection, Loader } from 'components'

import { HarvestStats, LoaderContainer, YieldStats } from './components'

interface Props {
  isLoading: boolean
  data: {
    date: string
    lotValue?: number
    areaValue?: number
    futureAreaValue?: number
    futureLotValue?: number
  }[]
  lotsQty: number
  areaQty: number
  harvested: number
  estimated: number
  delivered: number
  harvestedArea: number
  harvestedLots: number
  unharvestedArea: number
  unharvestedLots: number
}

export const HarvestAndYieldStatsCard: React.FC<Props> = ({
  isLoading,
  data,
  lotsQty,
  areaQty,
  harvested,
  estimated,
  delivered,
  harvestedArea,
  harvestedLots,
  unharvestedArea,
  unharvestedLots,
}) => {
  return (
    <CardSection title="Lotes y hectáreas cosechadas y su rendimiento">
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <Row gutter={[0, 60]}>
          <Col span={24}>
            <HarvestStats data={data} areaQty={areaQty} lotsQty={lotsQty} />
          </Col>
          <Col span={24}>
            <YieldStats
              harvested={harvested}
              estimated={estimated}
              delivered={delivered}
              harvestedLots={harvestedLots}
              harvestedArea={harvestedArea}
              unharvestedArea={unharvestedArea}
              unharvestedLots={unharvestedLots}
              totalArea={areaQty}
              totalLots={lotsQty}
            />
          </Col>
        </Row>
      )}
    </CardSection>
  )
}

interface Options {
  noFractionDigits: boolean
}

const localize = (value: number, options?: Options) =>
  new Intl.NumberFormat('es-CO', {
    maximumFractionDigits: options?.noFractionDigits ? 0 : undefined,
  }).format(value)

const roundedNumber = (value?: number) => Number(value?.toFixed())

const keepOnlyNumbers = (value: string) => value.replace(/[^0-9]/g, '')

export const NumberUtils = {
  localize,
  roundedNumber,
  keepOnlyNumbers,
}

import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation, useApolloClient } from '@apollo/client'
import { useCallback } from 'react'

import { BROADCAST_CHANNEL, STORAGE_KEYS } from 'consts'
import {
  AuthContext,
  CropFilterContext,
  HistoricalSeasonFilterContext,
  RegionFilterContext,
} from 'contexts'

import { useLocalStorage } from '../useLocalStorage'
import { LoginWithToken, LOGIN_WITH_TOKEN } from './api'

export const useAuth = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const client = useApolloClient()
  const {
    login: contextLogin,
    logout: contextLogout,
    userId,
    authChannel,
  } = AuthContext.useAuthContext()
  const { setRegion } = RegionFilterContext.useRegionFilterContext()
  const { setSelectedCrop } = CropFilterContext.useCropFilterContext()
  const { setSelectedHistoricalSeason } =
    HistoricalSeasonFilterContext.useHistoricalSeasonFilterContext()

  const [userToken, setUserToken, clearUserToken] = useLocalStorage<string>(STORAGE_KEYS.USER_TOKEN)

  const clearUserData = useCallback(async () => {
    await client.clearStore()
    clearUserToken()
    contextLogout()
    setRegion(undefined)
    setSelectedCrop(undefined)
    setSelectedHistoricalSeason(undefined)
  }, [
    clearUserToken,
    client,
    contextLogout,
    setRegion,
    setSelectedCrop,
    setSelectedHistoricalSeason,
  ])

  const logout = async () => {
    authChannel?.postMessage(BROADCAST_CHANNEL.AUTH.LOGOUT)
    await clearUserData()
    navigate('/auth', { replace: true })
  }

  const [tokenLoginMutation, { loading: tokenLoginLoading }] = useMutation<LoginWithToken>(
    LOGIN_WITH_TOKEN,
    {
      onCompleted: data => {
        contextLogin({
          userId: data.loginWithToken.user.id,
          userRole: data.loginWithToken.user.organizationRole?.role,
        })
        setUserToken(data.loginWithToken.token)
        if (pathname === '/') navigate('/dashboard')
      },
      onError: logout,
    },
  )

  const tokenLogin = () => {
    tokenLoginMutation()
  }

  return {
    userId,
    userToken,
    tokenLogin,
    tokenLoginLoading,
    logout,
    authChannel,
    clearUserData,
  }
}

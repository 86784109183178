import { Space } from 'antd'
import { useTheme } from 'styled-components'
import { useMemo } from 'react'

import { TonnesIcon } from 'assets/icons'
import { ItemInfo } from 'features/MainRouter/components'
import { NumberUtils } from 'utils'
import { useFeatures } from 'hooks'
import { FeatureSet } from 'types'
import { Ball } from 'components'

import {
  YieldStatsContainer,
  Container,
  EstimatedYieldContainer,
  ChartsContainer,
  ChartContainer,
  Chart,
  SubTitle,
} from './components'

interface Props {
  harvested: number
  estimated: number
  delivered: number
  harvestedArea: number
  unharvestedArea: number
  totalArea: number
  harvestedLots: number
  unharvestedLots: number
  totalLots: number
}

export const YieldStats: React.FC<Props> = ({
  harvested,
  estimated,
  delivered,
  harvestedArea,
  totalArea,
  harvestedLots,
  totalLots,
  unharvestedArea,
  unharvestedLots,
}) => {
  const { colors } = useTheme()
  const { featureSetId } = useFeatures()

  const areaChartData = [
    {
      name: 'harvestedArea',
      value: harvestedArea,
      fill: colors.primary[500],
      labelColor: colors.baseColor[900],
    },
    {
      name: 'unharvestedArea',
      value: unharvestedArea,
      fill: colors.baseColor[500],
      labelColor: colors.white,
    },
  ]

  const lotsChartData = [
    {
      name: 'harvestedLots',
      value: harvestedLots,
      fill: colors.primary[500],
      labelColor: colors.baseColor[900],
    },
    {
      name: 'unharvestedLots',
      value: unharvestedLots,
      fill: colors.baseColor[500],
      labelColor: colors.white,
    },
  ]

  const isOrganizationGrupoDiana = useMemo(
    () => featureSetId === FeatureSet.GRUPO_DIANA,
    [featureSetId],
  )

  const cardTitle = isOrganizationGrupoDiana
    ? 'Rendimiento estimado, declarado y entregado'
    : 'Rendimiento estimado y declarado'

  return (
    <YieldStatsContainer>
      <SubTitle>{cardTitle}</SubTitle>
      <Container>
        <EstimatedYieldContainer>
          <ItemInfo
            Icon={<TonnesIcon />}
            title="Estimadas"
            data={`${NumberUtils.localize(estimated, { noFractionDigits: true })} t`}
          />
          <ItemInfo
            Icon={<TonnesIcon />}
            title="Declaradas"
            data={`${NumberUtils.localize(harvested, { noFractionDigits: true })} t`}
          />
          {isOrganizationGrupoDiana && (
            <ItemInfo
              Icon={<TonnesIcon />}
              title="Entregadas"
              data={`${NumberUtils.localize(delivered, { noFractionDigits: true })} t`}
            />
          )}
        </EstimatedYieldContainer>
        <ChartsContainer>
          <Space size={60} direction="vertical" align="center">
            <ChartContainer>
              <Chart data={lotsChartData} />
            </ChartContainer>
            <Space size={20}>
              <ItemInfo
                Icon={<Ball background={colors.primary[500]} />}
                title="Cosechados"
                data={`${NumberUtils.localize(harvestedLots)} lotes`}
                horizontalSpacing={9}
              />
              <ItemInfo
                Icon={<Ball background={colors.baseColor[500]} />}
                title="No cosechado"
                data={`${NumberUtils.localize(unharvestedLots)} lotes`}
                horizontalSpacing={9}
              />
              <ItemInfo
                title="Total"
                data={`${NumberUtils.localize(totalLots)} lotes`}
                horizontalSpacing={9}
              />
            </Space>
          </Space>
          <Space size={60} direction="vertical" align="center">
            <ChartContainer>
              <Chart data={areaChartData} />
            </ChartContainer>
            <Space size={20}>
              <ItemInfo
                Icon={<Ball background={colors.primary[500]} />}
                title="Cosechadas"
                data={`${NumberUtils.localize(harvestedArea)} ha`}
                horizontalSpacing={9}
              />
              <ItemInfo
                Icon={<Ball background={colors.baseColor[500]} />}
                title="No cosechado"
                data={`${NumberUtils.localize(unharvestedArea)} ha`}
                horizontalSpacing={9}
              />
              <ItemInfo
                title="Total"
                data={`${NumberUtils.localize(totalArea)} ha`}
                horizontalSpacing={9}
              />
            </Space>
          </Space>
        </ChartsContainer>
      </Container>
    </YieldStatsContainer>
  )
}

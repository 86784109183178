import { gql } from '@apollo/client'

export type { ResendInvitation, ResendInvitationVariables } from './types/ResendInvitation'

export const RESEND_INVITATION = gql`
  mutation ResendInvitation($id: Int!) {
    resendOrganizationInvitation(id: $id) {
      invitation {
        id
        email
      }
    }
  }
`

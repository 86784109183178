import { useMutation } from '@apollo/client'
import { Space as AntSpace } from 'antd'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Content, FormEmailInput, LoginTextCta, StyledButton } from '../../components'
import { useFormIconsStyle } from '../hooks'
import {
  RequestPasswordChange,
  RequestPasswordChangeVariables,
  REQUEST_PASSWORD_CHANGE,
} from './api'
import { useForgotPasswordForm } from './hooks'

const Space = styled(AntSpace)`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`

export const ForgotPassword: React.FC = () => {
  const iconStyle = useFormIconsStyle()
  const navigate = useNavigate()
  const { control, handleSubmit } = useForgotPasswordForm()

  const [requestPasswordChange, { loading }] = useMutation<
    RequestPasswordChange,
    RequestPasswordChangeVariables
  >(REQUEST_PASSWORD_CHANGE, {
    onCompleted: () => navigate('/auth/password-change-request'),
    errorPolicy: 'all',
  })

  return (
    <Content>
      <Space direction="vertical" size="small">
        <FormEmailInput control={control} iconStyle={iconStyle} />
        <StyledButton
          type="primary"
          loading={loading}
          onClick={handleSubmit(({ email }) => requestPasswordChange({ variables: { email } }))}
        >
          Recuperar contraseña
        </StyledButton>
      </Space>
      <LoginTextCta />
    </Content>
  )
}

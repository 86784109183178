import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from 'contexts'
import { STORAGE_KEYS } from 'consts'
import { useLocalStorage } from 'hooks'
import { GraphQLUtils } from 'utils'

import { SIGNUP, SignUp, SignUpVariables } from '../api'

const DUPLICATED_EMAIL_ERROR = 'users_email_unique'
const DUPLICATED_EMAIL_MESSAGE = 'Ya existe una cuenta con este mail. Prueba iniciando sesión'

export const useSignup = () => {
  const [, setUserToken] = useLocalStorage(STORAGE_KEYS.USER_TOKEN)
  const navigate = useNavigate()

  const { login } = AuthContext.useAuthContext()
  return useMutation<SignUp, SignUpVariables>(SIGNUP, {
    onCompleted: ({ signup: signupResponse }) => {
      if (signupResponse.user?.isVerified) {
        login({
          userId: signupResponse.user.id,
          userRole: signupResponse.user.organizationRole?.role,
        })
        return setUserToken(signupResponse.token)
      }
      return navigate('/auth/verify-email')
    },
    onError: (error, clientOptions) => {
      const isDuplicatedEmail =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        error.graphQLErrors?.[0]?.extensions.exception?.constraint === DUPLICATED_EMAIL_ERROR

      GraphQLUtils.errorHandler(
        error,
        clientOptions,
        isDuplicatedEmail ? DUPLICATED_EMAIL_MESSAGE : undefined,
      )
    },
  })
}

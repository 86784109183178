import { Props } from '../../../types'

const TOTAL_IS_ONE = 1

export const useFilteredLotsSummary = ({ data }: Props) => {
  const lotsStats = {
    units: {
      filter: data?.organizationAnalytics.summary?.lots.filtered ?? 0,
      total: data?.organizationAnalytics.summary?.lots.total ?? 0,
    },
    ha: {
      filter: Number(data?.organizationAnalytics.summary?.size.filtered.toFixed() ?? 0),
      total: Number(data?.organizationAnalytics.summary?.size.total.toFixed() ?? 0),
    },
  }

  // We take the first lot for this occasion assuming all lots have same commercial executive and commercial name when
  // the totals for these two are one
  const lot = data?.organizationAnalytics.lots[0]?.lot
  const totalCommercialExecutives =
    data?.organizationAnalytics.summary?.totalCommercialExecutives === TOTAL_IS_ONE
      ? `${lot?.seasonField?.season?.commercialExecutive?.name} ${lot?.seasonField?.season?.commercialExecutive?.lastName}`
      : data?.organizationAnalytics.summary?.totalCommercialExecutives
  const totalCompanies =
    data?.organizationAnalytics.summary?.totalCompanies === TOTAL_IS_ONE
      ? lot?.seasonField?.season?.company?.name
      : data?.organizationAnalytics.summary?.totalCompanies

  return {
    lotsStats,
    totalCommercialExecutives,
    totalCompanies,
  }
}

import { useQuery } from '@apollo/client'

import { RiceLotStatus, AnalyticsTimeMode, DateRangeInput } from 'types'

import {
  OrganizationAnalytics,
  ORGANIZATION_ANALYTICS,
  OrganizationAnalyticsVariables,
} from './api'

export type { Lots } from './api'

interface Props {
  regionId?: number
  subregionId?: number
  commercialExecutiveId?: number
  timeMode?: AnalyticsTimeMode
  riceLotStatus: RiceLotStatus[]
  dateRange?: DateRangeInput
  companyId?: number
  cropId?: number
  varietyId?: number | null
  seasonStartRange?: string
  seasonEndRange?: string
  onCompleted?: (data: OrganizationAnalytics) => void
}

export const useOrganizationAnalyticsLots = ({
  regionId,
  subregionId,
  commercialExecutiveId,
  timeMode,
  riceLotStatus,
  dateRange,
  companyId,
  cropId,
  varietyId,
  seasonStartRange,
  seasonEndRange,
  onCompleted,
}: Props) => {
  return useQuery<OrganizationAnalytics, OrganizationAnalyticsVariables>(ORGANIZATION_ANALYTICS, {
    variables: {
      regionId,
      subregionId,
      commercialExecutiveId,
      timeMode,
      status: riceLotStatus,
      dateRange,
      companyId,
      cropId: cropId!,
      varietyId,
      seasonStartRange,
      seasonEndRange,
    },
    skip: !cropId,
    onCompleted,
  })
}

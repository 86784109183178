import styled from 'styled-components'
import { Typography } from 'antd'

const { Text: AntText } = Typography

const TextContainer = styled.span`
  overflow: hidden;
`

const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  font-size: 14px;
`

interface Props {
  text: string
  overlayInnerStyle?: React.CSSProperties
  showTooltip?: boolean
}

export const EllipsisText: React.FC<Props> = ({ text, overlayInnerStyle, showTooltip = true }) => (
  <TextContainer translate="no">
    <Text ellipsis={{ tooltip: showTooltip && text }} style={overlayInnerStyle}>
      {text}
    </Text>
  </TextContainer>
)

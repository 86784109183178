import { Popover as AntPopover, Row, Typography } from 'antd'
import styled, { css } from 'styled-components'
import { NavLink as ReactNavLink } from 'react-router-dom'
import { ComponentProps } from 'react'

import {
  DashboardIcon,
  DetailsIcon,
  HarvestIcon,
  MapIcon,
  SalesIcon,
  SettingsIcon,
} from 'assets/icons'

export { ProfilePopoverContent } from './ProfilePopoverContent'
export { SideNavbarLogo } from './SideNavbarLogo'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const FlexStartCol = styled(Container)`
  justify-content: flex-start;
  padding: 0;
`

export const FlexEndCol = styled(Container)`
  justify-content: flex-end;
  padding-bottom: 30px;
  align-items: center;
`

const iconStyles = css`
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`

export const StyledDashboardIcon = styled(DashboardIcon)`
  ${iconStyles}
`

export const StyledDetailsIcon = styled(DetailsIcon)`
  ${iconStyles}
`

export const StyledMapIcon = styled(MapIcon)`
  ${iconStyles}
`

export const StyledSettingsIcon = styled(SettingsIcon)`
  ${iconStyles}
`

export const StyledHarvestIcon = styled(HarvestIcon)`
  ${iconStyles}
`

export const StyledSalesIcon = styled(SalesIcon)`
  ${iconStyles}
`

export const NavRow = styled(Row).attrs({
  justify: 'center',
})`
  padding: 25px 5px;
`

export const NavLogoRow = styled(NavRow)`
  padding-right: 0;
  padding-left: 0;
`

export const NavLink = styled(ReactNavLink)`
  cursor: pointer;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > * {
    color: ${({ theme }) => theme.colors.white};
  }
  &.active > * {
    color: ${({ theme }) => theme.colors.secondary[600]};
  }
  &:hover > * {
    color: ${({ theme }) => theme.colors.secondary[600]};
  }
`

export const Text = styled(Typography.Text)`
  font-family: 'Inter';
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
`
const PopoverOverlayInnerStyle = {
  marginBottom: '35px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25), 0px 10px 20px rgba(0, 0, 0, 0.25)',
}

const PopoverAdapter: React.FC<ComponentProps<typeof AntPopover>> = ({
  className,
  ...restProps
}) => (
  <AntPopover
    {...restProps}
    overlayClassName={className}
    overlayInnerStyle={PopoverOverlayInnerStyle}
  />
)

export const Popover = styled(PopoverAdapter)`
  padding-left: 35px;
  .ant-popover-inner {
    padding: 0 !important;
  }
  .ant-popover-inner-content {
    width: 276px !important;
    height: 196px;
    padding: 0;
  }
  .ant-popover-arrow {
    display: none !important;
  }
`

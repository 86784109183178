import { Navigate, Route, Routes } from 'react-router-dom'
import { Space } from 'antd'

import { LogoKunaIcon } from 'assets/icons'
import { useAuth } from 'hooks'

import {
  Login,
  ForgotPassword,
  PasswordChangeRequest,
  VerifyEmail,
  Signup,
  PasswordChange,
} from './features'
import {
  BackgroundImage,
  StyledCard,
  CardImage,
  ContentContainer,
  CardContent,
  CardImageTitle,
  ContentHeader,
  Overlay,
  CardContainer,
  CardImageText,
  CardImageTextContainer,
} from './components'

export const LoginRouter = () => {
  const { userId } = useAuth()

  if (userId) {
    return <Navigate to={'/dashboard'} />
  }

  return (
    <BackgroundImage>
      <Overlay />
      <CardContainer>
        <Overlay />
        <StyledCard bodyStyle={{ padding: 0, height: '100%' }}>
          <CardContent>
            <CardImage>
              <Space>
                <LogoKunaIcon width={37} height={60} />
                <CardImageTextContainer>
                  <CardImageText>powered by</CardImageText>
                  <CardImageTitle>Kuna</CardImageTitle>
                </CardImageTextContainer>
              </Space>
            </CardImage>
            <ContentContainer>
              <ContentHeader />
              <Routes>
                <Route index element={<Login />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="verify-email" element={<VerifyEmail />} />
                <Route path="password-change-request" element={<PasswordChangeRequest />} />
                <Route path="signup" element={<Signup />} />
                <Route path="password-change" element={<PasswordChange />} />
              </Routes>
            </ContentContainer>
          </CardContent>
        </StyledCard>
        <Overlay />
      </CardContainer>
      <Overlay />
    </BackgroundImage>
  )
}

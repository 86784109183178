import { Layout as AntLayout } from 'antd'
import styled from 'styled-components'

export { ItemInfo } from './ItemInfo'
export { Body } from './Body'
export { RegionsSelect } from './RegionsSelect'
export { CropsSelect } from './CropsSelect'

export const Layout = styled(AntLayout)`
  height: 100%;
`

export const Sider = styled(AntLayout.Sider)`
  background-color: ${({ theme }) => theme.colors.baseColor[900]} !important;
`

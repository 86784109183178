import { Col, Row } from 'antd'
import { useTheme } from 'styled-components'

import { CardSection, Loader } from 'components'

import { Chart, Title, ChartContainer } from './components'
import { LoaderContainer } from '..'
import { DataObject } from '../../types'

interface Props {
  chartData: DataObject[]
  isLoading: boolean
}

export const VarietyStatsCard: React.FC<Props> = ({ chartData, isLoading }) => {
  const { colors } = useTheme()

  return (
    <CardSection title="Lotes y hectáreas por variedad">
      {isLoading ? (
        <LoaderContainer $minHeight="415px" align="middle" justify="center">
          <Loader />
        </LoaderContainer>
      ) : (
        <Row gutter={[{ xl: 24 }, { xs: 24, md: 24, xl: 0 }]}>
          <Col xs={24} xl={12}>
            <Row justify="center">
              <Title>Lotes</Title>
            </Row>
            <ChartContainer>
              <Chart
                data={chartData}
                fill={colors.primary[500]}
                textColor={colors.white}
                dataKey="lots"
              />
            </ChartContainer>
          </Col>
          <Col xs={24} xl={12}>
            <Row justify="center">
              <Title>Hectáreas</Title>
            </Row>
            <ChartContainer flex="auto">
              <Chart
                data={chartData}
                fill={colors.primary[500]}
                textColor={colors.white}
                dataKey="size"
              />
            </ChartContainer>
          </Col>
        </Row>
      )}
    </CardSection>
  )
}

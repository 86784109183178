import { UserOutlined } from '@ant-design/icons'
import { Avatar as AntAvatar } from 'antd'
import { ComponentProps, forwardRef } from 'react'
import styled from 'styled-components'

type Props = ComponentProps<typeof AntAvatar>

const Avatar: React.FC<Props> = forwardRef((props, ref) => {
  return <AntAvatar ref={ref} {...props} size={48} icon={<UserOutlined />} />
})

interface UserAvatarProps {
  $borderSize?: '2px' | '4px'
  cursor?: 'pointer' | 'unset'
}

export const UserAvatar = styled(Avatar)<UserAvatarProps>`
  background-color: ${({ theme }) => theme.colors.baseColor[900]};
  border: ${({ theme, $borderSize = '2px' }) => `${$borderSize} solid ${theme.colors.white}`};
  cursor: ${({ cursor }) => cursor ?? 'unset'};
`

import { useRef, useState } from 'react'
import { Dayjs } from 'dayjs'
import { RadioChangeEvent } from 'antd'

import { CropFilterContext, HistoricalSeasonFilterContext, RegionFilterContext } from 'contexts'
import { useCurrentUser, useOrganizationAnalyticsLots } from 'hooks'
import { DetailsFiltersContext } from 'features/MainRouter/contexts'
import { CommercialExecutive } from 'hooks/useCommercialExecutives/api'
import { OrganizationCompany } from 'components/CompaniesSelect/api'
import { Subregion } from 'components/SubregionsSelect/api'
import { AnalyticsTimeMode, RiceLotStatus, Selects, UserOrganizationRole } from 'types'
import { SELECTS } from 'consts'
import { MiscUtils } from 'utils'

import { DateType } from '../../types'

interface LocalDateRangeInput {
  startDate?: Dayjs | null
  endDate?: Dayjs | null
}

export const useDetails = () => {
  const [selectedFutureDateRange, setSelectedFutureDateRange] = useState<LocalDateRangeInput>()
  const [selectedCommercialExecutive, setSelectedCommercialExecutive] =
    useState<CommercialExecutive>()
  const [dateType, setDateType] = useState<DateType>(DateType.CURRENT)
  const [selectedCompany, setSelectedCompany] = useState<OrganizationCompany>()
  const [selectedSubregion, setSelectedSubregion] = useState<Subregion>()
  const [selectedLotStatus, setSelectedLotStatus] = useState<RiceLotStatus[]>([])
  const [selectedVarietyId, setSelectedVarietyId] = useState<number | Selects>()
  const [selectedTimeMode, setSelectedTimeMode] = useState<AnalyticsTimeMode.CURRENT | undefined>(
    AnalyticsTimeMode.CURRENT,
  )

  const { region } = RegionFilterContext.useRegionFilterContext()
  const { selectedCrop } = CropFilterContext.useCropFilterContext()
  const {
    setCommercialExecutive,
    setTimeMode,
    setSubregion,
    setFutureDateRange,
    setLotStatus,
    setCompany,
    setVarietyId,
    organizationStatusMilestones,
    commercialExecutive,
    varietyId,
    subregion,
    timeMode,
    lotStatus,
    company,
    futureDateRange,
  } = DetailsFiltersContext.useDetailsFiltersContext()
  const { setSelectedHistoricalSeason, selectedHistoricalSeason } =
    HistoricalSeasonFilterContext.useHistoricalSeasonFilterContext()

  const { data, loading } = useOrganizationAnalyticsLots({
    regionId: region?.id,
    subregionId: subregion?.id,
    commercialExecutiveId: commercialExecutive?.id,
    timeMode,
    riceLotStatus: lotStatus?.length ? lotStatus : [],
    companyId: company?.id,
    dateRange: futureDateRange,
    cropId: selectedCrop?.id,
    seasonStartRange: selectedHistoricalSeason?.startRange,
    seasonEndRange: selectedHistoricalSeason?.endRange,
    varietyId: MiscUtils.formatVarietyId(varietyId),
  })

  const filteredLotsSummaryCardRef = useRef<HTMLDivElement>(null)

  const { role } = useCurrentUser()

  const isLoggedUserACommercialUser = role === UserOrganizationRole.COMMERCIAL

  const scrollToFilteredSummaryCard = () =>
    filteredLotsSummaryCardRef.current?.scrollIntoView({ behavior: 'smooth' })

  const onSelectedDateType = (e: RadioChangeEvent) => {
    const selectedFilter = e.target.value as DateType
    setDateType(selectedFilter)
    setSelectedFutureDateRange(undefined)
    selectedFilter === DateType.CURRENT
      ? setSelectedTimeMode(AnalyticsTimeMode.CURRENT)
      : setSelectedTimeMode(undefined)
  }
  const onChangeStartDate = (date: Dayjs | null) => {
    setSelectedFutureDateRange(prevState => {
      return { ...prevState, startDate: date }
    })
  }
  const onChangeEndDate = (date: Dayjs | null) => {
    setSelectedFutureDateRange(prevState => {
      return { ...prevState, endDate: date }
    })
  }

  const onHandleDeleteFilters = () => {
    setSelectedFutureDateRange(undefined)
    !isLoggedUserACommercialUser && setSelectedCommercialExecutive(undefined)
    setDateType(DateType.CURRENT)
    setSelectedCompany(undefined)
    setSelectedSubregion(undefined)
    setSelectedLotStatus(organizationStatusMilestones)
    setSelectedTimeMode(AnalyticsTimeMode.CURRENT)
    setSelectedVarietyId(undefined)
    setSelectedVarietyId(Selects.ALL_VARIETIES)

    !isLoggedUserACommercialUser && setCommercialExecutive(undefined)
    setCompany(undefined)
    setLotStatus(organizationStatusMilestones)
    setTimeMode(AnalyticsTimeMode.CURRENT)
    setFutureDateRange(undefined)
    setSubregion(undefined)
    setVarietyId(Selects.ALL_VARIETIES)
  }

  const onHandleApplyFilters = () => {
    setCommercialExecutive(
      selectedCommercialExecutive?.id === SELECTS.DEFAULT_OPTION_ID
        ? undefined
        : selectedCommercialExecutive,
    )
    setCompany(selectedCompany)
    setLotStatus(selectedLotStatus)
    setTimeMode(selectedTimeMode)
    if (selectedTimeMode) setFutureDateRange(undefined)
    if (selectedFutureDateRange?.startDate && selectedFutureDateRange?.endDate) {
      setFutureDateRange({
        startDate: selectedFutureDateRange?.startDate?.format('MM/DD/YYYY'),
        endDate: selectedFutureDateRange?.endDate?.format('M/DD/YYYY'),
      })
    }
    setSubregion(
      selectedSubregion?.id === SELECTS.DEFAULT_OPTION_ID ? undefined : selectedSubregion,
    )
    setVarietyId(selectedVarietyId)
    scrollToFilteredSummaryCard()
  }

  return {
    loading,
    data,
    selectedFutureDateRange,
    selectedCommercialExecutive,
    dateType,
    selectedCompany,
    selectedSubregion,
    selectedLotStatus,
    selectedVarietyId,
    commercialExecutive,
    filteredLotsSummaryCardRef,
    organizationStatusMilestones,
    selectedHistoricalSeason,
    setSelectedCommercialExecutive,
    setSelectedSubregion,
    setSelectedCompany,
    setSelectedLotStatus,
    setSelectedVarietyId,
    onHandleApplyFilters,
    onHandleDeleteFilters,
    onChangeEndDate,
    onChangeStartDate,
    onSelectedDateType,
    setSelectedHistoricalSeason,
    setVarietyId,
    region,
    subregion,
  }
}

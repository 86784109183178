import { useMemo } from 'react'

import { Body, RegionsSelect, CropsSelect } from 'features/MainRouter/components'
import {
  CommercialExecutivesSelect,
  CompaniesSelect,
  SubregionsSelect,
  HistoricalSeasonSelect,
  VarietiesSelect,
} from 'components'
import { useFeatures, useUser } from 'hooks'
import { UserOrganizationRole } from 'types'

import {
  StatusStatsCard,
  HarvestAndYieldStatsCard,
  OrganizationAnalyticsSummary,
  CardsContainer,
} from './components'
import { useDashboard } from './hooks'

export const Dashboard = () => {
  const { featureFlags } = useFeatures()
  const user = useUser()

  const {
    setCommercialExecutive,
    setSubregion,
    setCompany,
    setSelectedHistoricalSeason,
    setSelectedVarietyId,
    company,
    loading,
    estimatedProduction,
    harvestedProduction,
    productionToMill,
    harvestedLotsQty,
    unharvestedLotsQty,
    totalLotsHarvested,
    harvestedHaQty,
    unharvestedHaQty,
    totalHaHarvested,
    harvestedLotStatsChartData,
    commercialExecutiveId,
    selectedHistoricalSeason,
    region,
    subregion,
    selectedVarietyId,
    cropId,
  } = useDashboard()

  const allHeaderItems = useMemo(
    () => ({
      seasonsSelect: (
        <HistoricalSeasonSelect
          onChange={setSelectedHistoricalSeason}
          selectedHistoricalSeason={selectedHistoricalSeason}
          width="170px"
        />
      ),
      crop: (
        <CropsSelect
          commercialExecutiveId={commercialExecutiveId}
          seasonStartRange={selectedHistoricalSeason?.startRange}
          seasonEndRange={selectedHistoricalSeason?.endRange}
          width="170px"
          regionId={region?.id}
          subregionId={subregion?.id}
        />
      ),
      variety: (
        <VarietiesSelect
          onChange={setSelectedVarietyId}
          value={selectedVarietyId}
          commercialExecutiveId={commercialExecutiveId}
          seasonStartRange={selectedHistoricalSeason?.startRange}
          seasonEndRange={selectedHistoricalSeason?.endRange}
          regionId={region?.id}
          subregionId={subregion?.id}
          cropId={cropId}
        />
      ),
      region: <RegionsSelect onChange={() => setCompany(undefined)} width="150px" />,
      subregion: <SubregionsSelect onChange={setSubregion} width="150px" />,
      commercialExecutive: (
        <CommercialExecutivesSelect
          onChange={setCommercialExecutive}
          value={commercialExecutiveId}
        />
      ),
      companies: (
        <CompaniesSelect
          selectedCommercialExecutiveId={commercialExecutiveId}
          onChange={setCompany}
          value={company?.id}
        />
      ),
    }),
    [
      commercialExecutiveId,
      company?.id,
      region?.id,
      selectedHistoricalSeason,
      selectedVarietyId,
      setCommercialExecutive,
      setCompany,
      setSelectedHistoricalSeason,
      setSelectedVarietyId,
      setSubregion,
      subregion?.id,
      cropId,
    ],
  )

  const selectedHeaderItems = useMemo(() => {
    const isMill = user?.organizationRole?.role === UserOrganizationRole.MILL
    const addSubregionSelect = isMill
    const addCommercialExecutiveSelect = !isMill && featureFlags.crm?.enabled
    const addCompaniesSelect = featureFlags.subFarmersManagement?.enabled

    const items = [
      allHeaderItems.seasonsSelect,
      allHeaderItems.crop,
      allHeaderItems.variety,
      allHeaderItems.region,
    ]
    if (addSubregionSelect) items.push(allHeaderItems.subregion)

    if (addCommercialExecutiveSelect) items.push(allHeaderItems.commercialExecutive)

    if (addCompaniesSelect) items.push(allHeaderItems.companies)
    return items
  }, [
    allHeaderItems,
    featureFlags.crm?.enabled,
    featureFlags.subFarmersManagement?.enabled,
    user?.organizationRole?.role,
  ])

  return (
    <Body headerTitle="Resumen de lotes" headerItems={selectedHeaderItems}>
      <CardsContainer>
        <OrganizationAnalyticsSummary />
        <StatusStatsCard />
        <HarvestAndYieldStatsCard
          isLoading={loading}
          data={harvestedLotStatsChartData}
          lotsQty={harvestedLotsQty}
          areaQty={harvestedHaQty}
          harvested={harvestedProduction}
          estimated={estimatedProduction}
          delivered={productionToMill}
          harvestedArea={totalHaHarvested}
          harvestedLots={totalLotsHarvested}
          unharvestedArea={unharvestedHaQty}
          unharvestedLots={unharvestedLotsQty}
        />
      </CardsContainer>
    </Body>
  )
}

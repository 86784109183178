import React from 'react'

import { TimeSelect, LotStatusSelect } from 'components'
import { MapFiltersContext } from 'features/MainRouter/contexts'
import { LocationIcon } from 'assets/icons'

import {
  Container,
  ContainerSelect,
  TimeSelectContainer,
  LotsFilteredCounter,
  CenterToolContainer,
  ToolsContainer,
} from './components'

interface Props {
  lotsQuantity: number
  lotsWithoutAreaQuantity: number
  onCenterLots: () => void
}
export const RightSideBar: React.FC<Props> = ({
  lotsQuantity,
  lotsWithoutAreaQuantity,
  onCenterLots,
}) => {
  const { setTimeMode, setLotStatus, lotStatus } = MapFiltersContext.useMapFiltersContext()
  return (
    <Container gutter={[0, 10]}>
      <TimeSelectContainer span={24}>
        <TimeSelect onChange={setTimeMode} />
      </TimeSelectContainer>
      <ContainerSelect>
        <LotStatusSelect open labelWithBall value={lotStatus} onChange={setLotStatus} />
      </ContainerSelect>
      <ToolsContainer justify="space-between">
        <LotsFilteredCounter text={'Lotes filtrados:'} lotsQuantity={lotsQuantity} />
        <CenterToolContainer onClick={onCenterLots}>
          <LocationIcon />
        </CenterToolContainer>
      </ToolsContainer>
      {lotsWithoutAreaQuantity > 0 && (
        <LotsFilteredCounter
          text={'Lotes filtrados sin perímetro:'}
          lotsQuantity={lotsWithoutAreaQuantity}
        />
      )}
    </Container>
  )
}

import { useContext, useState, createContext } from 'react'

import { Selects } from 'types'

interface VarietyFilterContextType {
  selectedVarietyId?: number | Selects.NO_VARIETY
  setSelectedVarietyId: (variety?: number | Selects.NO_VARIETY) => void
}

const Context = createContext<VarietyFilterContextType>({
  selectedVarietyId: undefined,
  setSelectedVarietyId: () => {
    throw new Error(
      'Attempted to use default setSelectedVarietyId function for VarietyFilterContext, make sure to pass a custom function implementation',
    )
  },
})

const useValue = (): VarietyFilterContextType => {
  const [selectedVarietyId, setSelectedVarietyId] = useState<number | Selects.NO_VARIETY>()

  return {
    selectedVarietyId,
    setSelectedVarietyId,
  }
}

const useVarietyFilterContext = () => {
  return useContext(Context)
}

export const VarietyFilterContext = {
  Provider: Context.Provider,
  useValue,
  useVarietyFilterContext,
}

import { Select } from 'antd'

import { MiscUtils } from 'utils'
import { useCommercialExecutives } from 'hooks'
import { CommercialExecutive } from 'hooks/useCommercialExecutives/api'
import { SELECTS } from 'consts'

interface Props {
  onChange: (commercialExecutive?: CommercialExecutive) => void
  value?: number
  isDisabled?: boolean
  commercialExecutiveOptionsBySelectedCompany?: { value: number; label: string }[]
  showAllOption?: boolean
}

export const CommercialExecutivesSelect: React.FC<Props> = ({
  onChange,
  value,
  isDisabled,
  commercialExecutiveOptionsBySelectedCompany,
  showAllOption = true,
}) => {
  const { loading, commercialExecutivesOptions, commercialUser, commercialExecutives } =
    useCommercialExecutives({ showAllOption })

  return (
    <Select
      showSearch
      loading={loading}
      filterOption={(input, option) => {
        if (!option) return false
        return MiscUtils.removeAccents(option.label.toLowerCase()).includes(
          input.toLocaleLowerCase(),
        )
      }}
      placeholder="Seleccionar Ejec. Comercial"
      options={commercialExecutiveOptionsBySelectedCompany ?? commercialExecutivesOptions}
      notFoundContent={'No hay coincidencia'}
      onChange={commercialExecutiveId => {
        const selectedCommercialExecutive = commercialExecutives?.find(
          commercialExecutive => commercialExecutive.id === commercialExecutiveId,
        )
        onChange(
          commercialExecutiveId === SELECTS.DEFAULT_OPTION_ID
            ? undefined
            : selectedCommercialExecutive,
        )
      }}
      defaultValue={!commercialUser && showAllOption ? 0 : undefined}
      value={commercialUser?.id ?? value}
      disabled={!!commercialUser || isDisabled}
    />
  )
}

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { VALIDATIONS } from 'consts'

interface Form {
  name: string
  lastName: string
  email: string
  password: string
}

export const useSignupForm = (defaultValues: Partial<Form>) => {
  const validationSchema = yup.object().shape({
    name: yup.string().required('Campo requerido'),
    lastName: yup.string().required('Campo requerido'),
    email: yup.string().email('Debe ser un formato de email válido').required('Campo requerido'),
    password: yup
      .string()
      .min(VALIDATIONS.PASSWORD_MIN_LENGTH, 'Mínimo 8 caracteres')
      .required('Campo requerido'),
  })

  return useForm<Form>({ resolver: yupResolver(validationSchema), defaultValues })
}

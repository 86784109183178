import { isNumber } from 'lodash'

import { SelectValueType, Selects } from 'types'

const removeAccents = (text: string) => text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

const setWebDocumentTitle = (title: string) => {
  window.document.title = title
}

const formatVarietyId = (selectedVarietyId?: Selects | number) => {
  if (selectedVarietyId === Selects.NO_VARIETY) return null

  return isNumber(selectedVarietyId) ? selectedVarietyId : undefined
}

const formatSelectValue = <T>(value?: SelectValueType<T>) => {
  if (value === Selects.ALL_OPTIONS) return undefined

  return value
}

export const MiscUtils = {
  removeAccents,
  setWebDocumentTitle,
  formatVarietyId,
  formatSelectValue,
}

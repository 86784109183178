import { Button, Col, Typography } from 'antd'
import styled from 'styled-components'

export { RolesSelect } from './RolesSelect'
export { InvitationModal } from './InvitationModal'

const { Text: AntText } = Typography

export const Text = styled(AntText)`
  font-weight: 700;
`

export const IconContainer = styled(Col)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.baseColor[800]};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  &:hover {
    background-color: ${({ theme }) => theme.colors.baseColor[600]};
  }
`

export const StyledButton = styled(Button).attrs({ type: 'primary' })`
  height: 38px;
  width: 142px;
`

import { useQuery } from '@apollo/client'

import { RegionFilterContext } from 'contexts'

import { SUBREGIONS, Subregions, SubregionsVariables } from '../../api'

export const useSubregions = () => {
  const { region } = RegionFilterContext.useRegionFilterContext()

  const { data, loading } = useQuery<Subregions, SubregionsVariables>(SUBREGIONS, {
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      regionId: region?.id!,
    },
    skip: !region,
  })

  const subregionsOptions = data?.subregions.results.map(subregion => ({
    value: subregion.id,
    label: subregion.name,
  }))

  return {
    loading,
    subregionsOptions: [{ value: 0, label: 'Todas las sub zonas' }, ...(subregionsOptions ?? [])],
    subregions: data?.subregions.results,
    regionId: region?.id,
  }
}

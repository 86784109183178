import dayjs, { Dayjs } from 'dayjs'
import { useContext, useState, createContext } from 'react'

import { CommercialExecutive } from 'hooks/useCommercialExecutives/api'
import { Subregion } from 'components/SubregionsSelect/api'
import { OrganizationCompany } from 'components/CompaniesSelect/api'

interface DashboardFiltersContextType {
  commercialExecutive?: CommercialExecutive
  setCommercialExecutive: (commercialExecutive?: CommercialExecutive) => void
  setStartDate: (date: Dayjs | null) => void
  setEndDate: (date: Dayjs | null) => void
  startDate: Dayjs | null
  endDate: Dayjs | null
  subregion?: Subregion
  setSubregion: (subregion?: Subregion) => void
  company?: OrganizationCompany
  setCompany: (company?: OrganizationCompany) => void
}

const Context = createContext<DashboardFiltersContextType>({
  commercialExecutive: undefined,
  setCommercialExecutive: () => {
    throw new Error(
      'Attempted to use default setCommercialExecutive function for DashboardFiltersContext, make sure to pass a custom function implementation',
    )
  },
  subregion: undefined,
  setSubregion: () => {
    throw new Error(
      'Attempted to use default setSubregion function for DashboardFiltersContext, make sure to pass a custom function implementation',
    )
  },
  startDate: null,
  setStartDate: () => {
    throw new Error(
      'Attempted to use default setStartDate function for DashboardFiltersContext, make sure to pass a custom function implementation',
    )
  },
  endDate: null,
  setEndDate: () => {
    throw new Error(
      'Attempted to use default setStartDate function for DashboardFiltersContext, make sure to pass a custom function implementation',
    )
  },
  company: undefined,
  setCompany: () => {
    throw new Error(
      'Attempted to use default setCompany function for DashboardFiltersContext, make sure to pass a custom function implementation',
    )
  },
})

const useValue = (): DashboardFiltersContextType => {
  const [subregion, setSubregion] = useState<Subregion>()
  const [commercialExecutive, setCommercialExecutive] = useState<CommercialExecutive | undefined>()
  const [company, setCompany] = useState<OrganizationCompany>()

  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(30, 'days'))
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs().add(15, 'days'))

  // This logic is only for mock data, when we integrated api calls we will delete this logic
  return {
    commercialExecutive,
    setCommercialExecutive,
    subregion,
    setSubregion,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    company,
    setCompany,
  }
}

const useDashboardFiltersContext = () => {
  return useContext(Context)
}

export const DashboardFiltersContext = {
  Provider: Context.Provider,
  useValue,
  useDashboardFiltersContext,
}

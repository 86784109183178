import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

import { AuthContext } from 'contexts'
import { STORAGE_KEYS } from 'consts'
import { useLocalStorage } from 'hooks'

import { LOGIN, Login, LoginVariables } from '../api'

export const useLogin = () => {
  const [, setUserToken] = useLocalStorage(STORAGE_KEYS.USER_TOKEN)
  const navigate = useNavigate()

  const { login } = AuthContext.useAuthContext()
  return useMutation<Login, LoginVariables>(LOGIN, {
    onCompleted: data => {
      login({ userId: data.login.user.id, userRole: data.login.user.organizationRole?.role })
      setUserToken(data.login.token)
      navigate('/dashboard', { replace: true })
    },
    onError: error => console.log(error),
  })
}

import { Select } from 'antd'

import { FortnightOptions } from '../../types'

interface Props {
  onChange: (fortnight: string) => void
  value?: string
  options: FortnightOptions[]
}

const { Option } = Select

export const FortnightSelect: React.FC<Props> = ({ onChange, value, options }) => {
  return (
    <Select
      listHeight={300}
      placeholder="Sin seleccionar..."
      value={options.length ? value : undefined}
      onChange={onChange}
    >
      {options.map(fortnight => (
        <Option key={fortnight.value} value={fortnight.value}>
          {fortnight.label}
        </Option>
      ))}
    </Select>
  )
}

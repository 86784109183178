import { gql } from '@apollo/client'

export type {
  AssignCommercialExecutiveGoal,
  AssignCommercialExecutiveGoalVariables,
  AssignCommercialExecutiveGoal_assignCommercialExecutiveGoal_assignedGoal as AssignedGoal,
} from './types/AssignCommercialExecutiveGoal'

export const ASSIGN_COMMERCIAL_EXECUTIVE_GOAL = gql`
  mutation AssignCommercialExecutiveGoal($assignGoalDTO: AssignGoalInput!) {
    assignCommercialExecutiveGoal(assignGoalDTO: $assignGoalDTO) {
      success
      assignedGoal {
        id
        yearMonth
        goal
        contributionGoal
      }
    }
  }
`

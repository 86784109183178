import { PieChart, Pie, Sector } from 'recharts'
import { useTheme } from 'styled-components'

type ChartDataObject = {
  value: number
}

const RenderActiveShape = (props: {
  cx: number
  cy: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  percent: number
  fillColor: string
  text: string
}) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, percent, fillColor, text } = props
  const { colors } = useTheme()

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={-15}
        textAnchor="middle"
        style={{
          lineHeight: '19px',
          fontWeight: 400,
          fontSize: '16px',
          color: colors.baseColor[800],
        }}
      >
        {text}
      </text>
      <text
        x={cx}
        y={cy}
        dy={25}
        style={{ fontWeight: 600, fontSize: '30px' }}
        textAnchor="middle"
        fill={colors.baseColor[900]}
      >
        {percent * 100 ? `${(percent * 100).toFixed()}%` : '-'}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fillColor}
      />
    </g>
  )
}

interface Props {
  data: ChartDataObject[]
  fillColor: string
  text: string
}

export const Chart: React.FC<Props> = ({ data, fillColor, text }) => {
  const { colors } = useTheme()

  const getDataValues = () => {
    const dataHasValues = data.some(dataValue => !!dataValue.value)
    if (dataHasValues) return data
    return [{ value: 0 }, { value: 100 }]
  }
  return (
    <PieChart width={200} height={200}>
      <Pie
        activeIndex={0}
        activeShape={props => <RenderActiveShape {...props} fillColor={fillColor} text={text} />}
        innerRadius={82}
        outerRadius={100}
        cx="50%"
        cy="50%"
        fill={colors.baseColor[700]}
        data={getDataValues()}
        dataKey="value"
      />
    </PieChart>
  )
}

import { ShopOutlined } from '@ant-design/icons'

import { SquareAvatar } from 'components'

interface Props {
  organizationLogo?: string
}
export const SideNavbarLogo: React.FC<Props> = ({ organizationLogo }) => {
  return (
    <SquareAvatar src={organizationLogo} onError={() => true}>
      <ShopOutlined style={{ color: 'white', fontSize: '32px' }} />
    </SquareAvatar>
  )
}

import { Space } from 'antd'
import styled from 'styled-components'

export { StatusStatsCard } from './StatusStatsCard'
export { HarvestAndYieldStatsCard } from './HarvestAndYieldStatsCard'
export { OrganizationAnalyticsSummary } from './OrganizationAnalyticsSummary'

export const CardsContainer = styled(Space).attrs({
  size: [0, 60],
  direction: 'vertical',
})`
  display: flex;
`

import React from 'react'
import styled from 'styled-components'
import { Typography } from 'antd'

const { Text: AntText } = Typography

const StyledCard = styled.div`
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  background-color: ${({ theme }) => theme.colors.baseColor[900]};
  display: flex;
  align-items: center;
  padding: 9px 11px;
`
const Text = styled(AntText)`
  color: ${({ theme }) => theme.colors.white};
  size: 13px;
  font-weight: 600;
`

interface Props {
  text: string
  lotsQuantity: number
}
export const LotsFilteredCounter: React.FC<Props> = ({ text, lotsQuantity }) => {
  return (
    <StyledCard>
      <Text>
        {text} {lotsQuantity}
      </Text>
    </StyledCard>
  )
}

import { PlusCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const ImageContainer = styled.div`
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  border: 2px solid ${({ theme }) => theme.colors.baseColor[300]};
  overflow: hidden;
`

const Image = styled.img`
  width: 100%;
`

const StyledPlusCircleOutlined = styled(PlusCircleOutlined)`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.baseColor[300]};
`

interface Props {
  image?: string
}

export const ImageViewer: React.FC<Props> = ({ image }) => {
  return (
    <ImageContainer>
      {image ? <Image src={image} alt={'logo'} /> : <StyledPlusCircleOutlined />}
    </ImageContainer>
  )
}

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { UserOrganizationRole } from 'types'

export const useInvitationForm = () => {
  const validationSchema = yup.object().shape({
    email: yup.string().email('Debe ser un formato de email válido').required('Campo requerido'),
    role: yup
      .string()
      .is([...Object.keys(UserOrganizationRole)])
      .required('Campo requerido'),
    regionId: yup.number().when('role', {
      is: UserOrganizationRole.COMMERCIAL,
      then: regionId => regionId.required('Campo requerido'),
    }),
  })

  return useForm({ resolver: yupResolver(validationSchema) })
}

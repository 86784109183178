import { useEffect } from 'react'
import styled from 'styled-components'

import { RegionFilterContext } from 'contexts'
import { useCommercialExecutives, useRegions } from 'hooks'
import { SELECTS } from 'consts'
import { RegionsSelect as BaseRegionsSelect } from 'components'

interface SelectProps {
  width?: string
}

export const Select = styled(BaseRegionsSelect)<SelectProps>`
  .ant-select-selector {
    width: ${({ width }) => width && width} !important;
  }
`

interface Props {
  onChange?: () => void
  width?: string
}
export const RegionsSelect: React.FC<Props> = ({ onChange, width }) => {
  const { setRegion, region } = RegionFilterContext.useRegionFilterContext()
  const { defaultRegionIdByCommercialExecutive } = useCommercialExecutives()
  const { regions } = useRegions()

  // When region is not selected we assign value 0 to show 'Todas las zonas' in placeholder
  const regionValue = region?.id ?? 0

  const defaultRegion = regions?.find(
    _region => _region.id === defaultRegionIdByCommercialExecutive,
  )

  useEffect(() => {
    if (defaultRegion) {
      setRegion(defaultRegion)
    }
  }, [defaultRegion, setRegion])

  return (
    <Select
      value={defaultRegion?.id ?? regionValue}
      width={width}
      onChange={selectedRegionId => {
        onChange?.()
        const selectedRegion = regions?.find(_region => _region.id === selectedRegionId)
        setRegion(selectedRegionId === SELECTS.DEFAULT_OPTION_ID ? undefined : selectedRegion)
      }}
      disabled={!!defaultRegion}
    />
  )
}

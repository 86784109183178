import { OrganizationAnalytics } from 'hooks/useOrganizationAnalyticsLots/api'

export enum DateType {
  CURRENT = 'CURRENT',
  NEXT = 'NEXT',
}

export interface Props {
  data?: OrganizationAnalytics
  loading?: boolean
}

import { Row } from 'antd'
import styled from 'styled-components'

export { HarvestStats } from './HarvestStats'
export { YieldStats } from './YieldStats'

export const LoaderContainer = styled(Row).attrs({
  align: 'middle',
  justify: 'center',
})`
  width: 100%;
  min-height: 630px;
`

import { useEffect } from 'react'

import { CardSection, HistoricalSeasonSelect } from 'components'
import { Body, CropsSelect, RegionsSelect } from 'features/MainRouter/components'
import { Selects } from 'types'

import {
  FilteredLotsSummaryCard,
  FiltersCard,
  ResultsCard,
  ExportButton,
  CardContainer,
  CardsContainer,
} from './components'
import { useDetails } from './hooks'

export const Details = () => {
  const {
    loading,
    data,
    selectedFutureDateRange,
    selectedCommercialExecutive,
    dateType,
    selectedCompany,
    selectedSubregion,
    selectedLotStatus,
    selectedVarietyId,
    commercialExecutive,
    filteredLotsSummaryCardRef,
    organizationStatusMilestones,
    selectedHistoricalSeason,
    region,
    subregion,
    setSelectedCommercialExecutive,
    setSelectedSubregion,
    setSelectedCompany,
    setSelectedLotStatus,
    setSelectedVarietyId,
    onHandleApplyFilters,
    onHandleDeleteFilters,
    onChangeEndDate,
    onChangeStartDate,
    onSelectedDateType,
    setSelectedHistoricalSeason,
    setVarietyId,
  } = useDetails()

  useEffect(() => {
    setSelectedLotStatus(organizationStatusMilestones)
  }, [organizationStatusMilestones, setSelectedLotStatus])

  const headerItems = [
    <HistoricalSeasonSelect
      onChange={setSelectedHistoricalSeason}
      selectedHistoricalSeason={selectedHistoricalSeason}
    />,
    <RegionsSelect onChange={onHandleDeleteFilters} />,
    <CropsSelect
      commercialExecutiveId={commercialExecutive?.id}
      seasonStartRange={selectedHistoricalSeason?.startRange}
      seasonEndRange={selectedHistoricalSeason?.endRange}
      onChange={() => {
        setSelectedVarietyId(Selects.ALL_VARIETIES)
        setVarietyId(Selects.ALL_VARIETIES)
      }}
      regionId={region?.id}
      subregionId={subregion?.id}
    />,
  ]

  return (
    <Body headerTitle="Detalle de lotes" headerItems={headerItems}>
      <CardsContainer>
        <CardSection title="Filtros" isCollapsible openCollapsible>
          <FiltersCard
            radioValue={dateType}
            startDate={selectedFutureDateRange?.startDate ?? null}
            endDate={selectedFutureDateRange?.endDate ?? null}
            selectedLotStatus={selectedLotStatus}
            selectedCompany={selectedCompany}
            selectedSubregion={selectedSubregion}
            selectedCommercialExecutive={selectedCommercialExecutive}
            onChangeRadio={onSelectedDateType}
            onChangeStartDate={onChangeStartDate}
            onChangeEndDate={onChangeEndDate}
            onChangeCompany={setSelectedCompany}
            onChangeCommercialExecutive={setSelectedCommercialExecutive}
            onChangeLotStatus={setSelectedLotStatus}
            onApplyFilters={onHandleApplyFilters}
            onDeleteFilters={onHandleDeleteFilters}
            onSubregionChange={setSelectedSubregion}
            onVarietyChange={setSelectedVarietyId}
            selectedVarietyId={selectedVarietyId}
          />
        </CardSection>
        <CardSection
          ref={filteredLotsSummaryCardRef}
          title="Resumen de filtros aplicados"
          isCollapsible
          openCollapsible
        >
          <FilteredLotsSummaryCard data={data} loading={loading} />
        </CardSection>
        <CardContainer>
          <CardSection title="Resultados" actionBar={<ExportButton />} openCollapsible>
            <ResultsCard data={data} loading={loading} />
          </CardSection>
        </CardContainer>
      </CardsContainer>
    </Body>
  )
}

import React, { useEffect, useState } from 'react'

import { useUpdateOrganization } from 'hooks'
import { CardSection, Upload } from 'components'

import { useOrganizationForm } from '../../hooks'
import { FormInput, InputTitle, StyledCol, StyledRow } from './components'
import { CardContainer } from '..'

interface Props {
  organization: { id?: number; name?: string; logo?: string }
}

export const OrganizationCard: React.FC<Props> = ({ organization }) => {
  const { updateOrganization } = useUpdateOrganization()
  const { control, handleSubmit, setValue } = useOrganizationForm()

  const [image, setImage] = useState<string>()

  useEffect(() => {
    if (organization.name) setValue('name', organization.name)
  }, [organization.name, setValue])

  return (
    <CardContainer>
      <CardSection title="Información">
        <StyledRow>
          <StyledCol>
            <InputTitle>Logo</InputTitle>
            <Upload
              allowCrop
              image={image ?? organization.logo}
              onChange={(imageResult, logo) => {
                if (!organization.id) return
                setImage(imageResult)
                updateOrganization({
                  variables: { id: organization.id, updateOrganizationDTO: { logo } },
                })
              }}
            />
          </StyledCol>
          <StyledCol style={{ flex: 1 }}>
            <InputTitle>Nombre de la organización</InputTitle>
            <FormInput
              control={control}
              name="name"
              placeholder="Nombre"
              capitalize
              onHandleBlur={handleSubmit(({ name }) => {
                if (!organization.id) return
                updateOrganization({
                  variables: {
                    id: organization.id,
                    updateOrganizationDTO: { name },
                  },
                })
              })}
            />
          </StyledCol>
        </StyledRow>
      </CardSection>
    </CardContainer>
  )
}

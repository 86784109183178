import { Col } from 'antd'
import { ComponentProps } from 'react'
import styled from 'styled-components'

const ChildContainer = styled.div``

const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.baseColor[900]};
`
const StyledCol = styled(Col)`
  height: 121px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`
type ColTypes = ComponentProps<typeof StyledCol>
type CustomProps = {
  title: string
}
type Props = ColTypes & CustomProps

export const ColWithTitle: React.FC<Props> = ({ title, children, ...props }) => (
  <StyledCol {...props}>
    <Title>{title}</Title>
    <ChildContainer>{children}</ChildContainer>
  </StyledCol>
)

import { Space, Typography, Button as AntButton } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 196px;
  background-color: ${({ theme }) => theme.colors.baseColor[850]};
  border-radius: ${({ theme }) => theme.foundation.borderRadius};
  border: ${({ theme }) => `1px solid ${theme.colors.white}`};
`

export const Item = styled(Space).attrs({
  size: 20,
})`
  padding: 0 30px;
`

const { Text, Link: AntLink } = Typography

export const DescriptionText = styled(Text)`
  font-size: 13px !important;
  color: ${({ theme }) => theme.colors.white} !important;
  font-weight: 400 !important;
  line-height: 24px !important;
`

export const UsernameText = styled(Text)`
  color: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-weight: 600 !important;
`

export const Link = styled(AntLink)`
  color: ${({ theme }) => theme.colors.secondary[600]} !important;
`

export const Button = styled(AntButton)`
  padding: 0;
  height: 0;
  & span {
    color: ${({ theme }) => theme.colors.secondary[600]};
    font-size: 13px;
    line-height: 24px;
  }
`

export const Divider = styled.div`
  height: 1px;
  border: ${({ theme }) => `1px solid ${theme.colors.baseColor[400]}`};
`

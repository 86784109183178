import styled from 'styled-components'
import { Col } from 'antd'

const Container = styled(Col)`
  padding-top: 62px;
  height: 100%;
`
const Content = styled(Col)`
  height: 100%;
  background: ${({ theme }) => theme.colors.baseColor[100]};
  border-radius: 25px;
`

export const PlaceholderCard = () => {
  return (
    <Container>
      <Content />
    </Container>
  )
}

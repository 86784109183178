import { Marker } from 'react-map-gl'

import { PopupInfo } from '../../types'
import { PopupCard } from './components'

export const LotPopup: React.FC<PopupInfo> = ({
  name,
  emergenceDays,
  size,
  variety,
  companyName,
  coordinates,
  commercialExecutive,
}) => {
  return (
    <Marker latitude={coordinates[1]} longitude={coordinates[0]} draggable={false}>
      <PopupCard
        name={name}
        emergenceDays={emergenceDays}
        size={size}
        variety={variety}
        companyName={companyName}
        comercialExecutive={commercialExecutive}
      />
    </Marker>
  )
}

import { HiddenEyeIcon, PolygonIcon, TrashIcon } from 'assets/icons'
import { DrawingOption } from 'features/MainRouter/features/Map/types'

import { MenuContainer } from './components'

interface Props {
  selectedItem: DrawingOption
  isDrawingToolAvailable: boolean
  onDrawingOptionSelected: (option: DrawingOption) => void
}

export const MeasureAreaDropdown: React.FC<Props> = ({
  selectedItem,
  isDrawingToolAvailable,
  onDrawingOptionSelected,
}) => {
  const dataSource = [
    {
      key: 'draw',
      label: 'Dibujar perímetro',
      icon: <PolygonIcon />,
    },
    {
      key: 'hide',
      label: 'Ocultar áreas',
      icon: <HiddenEyeIcon />,
      disabled: isDrawingToolAvailable,
    },
    {
      key: 'delete',
      label: 'Borrar área',
      icon: <TrashIcon />,
      disabled: isDrawingToolAvailable,
    },
    {
      key: 'delete-all',
      label: 'Borrar todas',
      icon: <TrashIcon />,
      disabled: isDrawingToolAvailable,
    },
  ]

  return (
    <MenuContainer
      selectedKeys={selectedItem ? [selectedItem] : []}
      onClick={e => onDrawingOptionSelected(e.key as DrawingOption)}
      items={dataSource}
    />
  )
}

import { Body } from 'features/MainRouter/components'

import { Container, OrganizationCard, UsersCard } from './components'
import { useSettings } from './hooks'

export const Settings = () => {
  const { organization, usersLoading, usersCardData } = useSettings()

  return (
    <Body headerTitle="Ajustes">
      <Container>
        <OrganizationCard organization={organization} />
        <UsersCard
          loading={usersLoading}
          users={usersCardData}
          organizationFeatures={organization.features}
        />
      </Container>
    </Body>
  )
}

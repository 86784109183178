import { UserOrganizationRole } from 'types'

import { useUser } from './useUser'

export const usePermissions = () => {
  const user = useUser()
  const isOrganizationAdmin = user?.organizationRole?.role === UserOrganizationRole.ADMIN

  const permissions = {
    isOrganizationAdmin,
  }

  return {
    permissions,
  }
}

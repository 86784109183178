import { Input as AntInput } from 'antd'
import { isNumber } from 'lodash'
import { useState } from 'react'

import { NumberUtils } from 'utils'

type Props = {
  isDisabled: boolean
  placeholder: string
  defaultValue: string
  addonBefore?: string
  onValueChange: (value: string) => void
}

export const Input: React.FC<Props> = ({
  isDisabled,
  placeholder,
  defaultValue,
  addonBefore,
  onValueChange,
}) => {
  const [value, setValue] = useState<number>()

  return (
    <AntInput
      disabled={isDisabled}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={isNumber(value) ? NumberUtils.localize(value) : undefined}
      onChange={event => {
        const newValue = Number(NumberUtils.keepOnlyNumbers(event.target.value)) || undefined
        setValue(newValue)
      }}
      addonBefore={addonBefore}
      onBlur={event => onValueChange(event.target.value)}
    />
  )
}

import { useQuery } from '@apollo/client'
import { Select as AntSelect } from 'antd'
import { useMemo } from 'react'
import styled from 'styled-components'

import { CropFilterContext, VarietyFilterContext } from 'contexts'
import { Selects } from 'types'

import { Crop, CROPS, Crops, CropsVariables } from './api'

export type { Crop }

interface SelectProps {
  width?: string
}

export const Select = styled(AntSelect)<SelectProps>`
  .ant-select-selector {
    width: ${({ width }) => width && width} !important;
  }
`

interface Props {
  onChange?: (crop: Crop) => void
  loading?: boolean
  defaultValue?: number
  disabled?: boolean
  commercialExecutiveId?: number
  seasonStartRange?: string
  seasonEndRange?: string
  width?: string
  regionId?: number
  subregionId?: number
}

export const CropsSelect: React.FC<Props> = ({
  onChange,
  loading,
  defaultValue,
  disabled,
  commercialExecutiveId,
  seasonStartRange,
  seasonEndRange,
  width,
  regionId,
  subregionId,
}) => {
  const { setSelectedCrop, selectedCrop } = CropFilterContext.useCropFilterContext()
  const { setSelectedVarietyId, selectedVarietyId } = VarietyFilterContext.useVarietyFilterContext()

  const { data, loading: dataLoading } = useQuery<Crops, CropsVariables>(CROPS, {
    variables: { commercialExecutiveId, seasonStartRange, seasonEndRange, regionId, subregionId },
    onCompleted: cropsResults => {
      const crops = cropsResults.organization.crops
      if (!crops?.length) {
        setSelectedCrop(undefined)
        setSelectedVarietyId(undefined)
        return
      }
      const isSelectedCropInNewCrops = crops.some(crop => crop.id === selectedCrop?.id)
      setSelectedCrop(isSelectedCropInNewCrops ? selectedCrop : crops[0])
      const filteredVarieties = cropsResults?.organization.crops?.find(
        crop => crop.id === selectedCrop?.id,
      )
      const isNoVarietySelected = selectedVarietyId === Selects.NO_VARIETY
      if (isNoVarietySelected && filteredVarieties?.varieties?.withNoVarietyOption) return

      const isSelectedVarietyInNewVarieties = filteredVarieties?.varieties?.results?.find(
        variety => variety.id === selectedVarietyId,
      )?.id

      setSelectedVarietyId(
        isSelectedVarietyInNewVarieties ? isSelectedVarietyInNewVarieties : undefined,
      )
    },
  })

  const options = useMemo(() => {
    if (!data?.organization.crops?.length) return []

    return data.organization.crops.map(crop => ({ value: crop.id, label: crop.locales.es }))
  }, [data])

  return (
    <Select
      placeholder={'Seleccionar cultivo'}
      defaultValue={defaultValue}
      value={loading || dataLoading ? undefined : selectedCrop?.id}
      loading={dataLoading || loading}
      disabled={disabled || !options.length}
      width={width}
      onChange={id => {
        const newSelectedCrop = data?.organization.crops?.find(crop => crop.id === id)
        if (!newSelectedCrop) return setSelectedVarietyId(undefined)

        setSelectedCrop(newSelectedCrop)
        onChange?.(newSelectedCrop)

        const isNoVarietySelected = selectedVarietyId === Selects.NO_VARIETY
        if (isNoVarietySelected && newSelectedCrop?.varieties?.withNoVarietyOption) return

        const isSelectedVarietyInNewVarieties = newSelectedCrop?.varieties?.results?.find(
          variety => variety.id === selectedVarietyId,
        )?.id

        setSelectedVarietyId(
          isSelectedVarietyInNewVarieties ? isSelectedVarietyInNewVarieties : undefined,
        )
      }}
      options={options}
    />
  )
}

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { config } from 'config'

const init = () => {
  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: config.env,
    release: `agrosales-web@${config.version}`,
  })
}

export const SentryService = {
  init,
}

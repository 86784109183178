import { gql } from '@apollo/client'

export type { HistoricalSeasons } from './types/HistoricalSeasons'

export const HISTORICAL_SEASONS = gql`
  query HistoricalSeasons {
    historicalSeasons {
      name
      endRange
      startRange
    }
  }
`

import { ThemeConfig } from 'antd'

import { Colors } from './theme/colors'
import { Foundation } from './theme/foundation'
import { Modal } from './theme/modal'

export const antdTheme: ThemeConfig = {
  token: {
    fontFamily: 'Inter',
    colorPrimary: Colors.primary[500],
  },
  components: {
    Button: {
      colorText: Colors.primary[500],
      borderRadius: parseInt(Foundation.borderRadius),
    },
    Card: {
      paddingLG: 0,
    },
    DatePicker: {
      colorBgContainer: Colors.baseColor[900],
      colorBgElevated: Colors.baseColor[900],
      colorPrimary: Colors.primary[500],
      controlItemBgHover: Colors.primary[500],
      fontSize: 13,
      borderRadiusSM: parseInt(Foundation.borderRadius),
      colorTextPlaceholder: Colors.baseColor[300],
    },
    Dropdown: {
      colorBgElevated: Colors.baseColor[900],
    },
    Input: {
      borderRadiusOuter: parseInt(Foundation.borderRadius),
      borderRadius: parseInt(Foundation.borderRadius),
      colorTextPlaceholder: Colors.baseColor[400],
      colorIcon: Colors.white,
      colorIconHover: Colors.white,
    },
    Modal: {
      titleFontSize: parseInt(Modal.title.fontSize),
    },
    Popover: {
      paddingContentHorizontal: 2,
      paddingContentVertical: 2,
    },
    Select: {
      colorBorder: Colors.baseColor[900],
      colorBgContainer: Colors.baseColor[900],
      colorBgElevated: Colors.baseColor[900],
      colorTextPlaceholder: Colors.baseColor[300],
      colorTextQuaternary: Colors.white,
      controlItemBgHover: Colors.baseColor[500],
      fontSize: 13,
    },
    Typography: {
      colorLinkHover: Colors.secondary[600],
    },
  },
}

import { gql } from '@apollo/client'

export type {
  UpdateOrganizationUser,
  UpdateOrganizationUserVariables,
} from './types/UpdateOrganizationUser'

export const UPDATE_ORG_USER = gql`
  mutation UpdateOrganizationUser($userId: Int!, $role: UserOrganizationRole, $regionId: Int) {
    updateOrganizationUser(userId: $userId, role: $role, regionId: $regionId) {
      orgUser {
        id
        user {
          id
          email
        }
        role
        regionId
      }
    }
  }
`

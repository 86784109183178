import { Space } from 'antd'
import styled from 'styled-components'

export { OrganizationCard } from './OrganizationCard'
export { UsersCard } from './UsersCard'

export const CardContainer = styled.div`
  padding: 12px 16px;
`

export const Container = styled(Space).attrs({
  size: 60,
  direction: 'vertical',
})`
  display: flex;
`

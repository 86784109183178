import { useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'
import { useOrganizationUsers } from 'features/MainRouter/features/Settings/hooks'

import { INVITE_USER, InviteOrganizationUser, InviteOrganizationUserVariables } from './api'

export const useInviteUser = (onSuccess: () => void) => {
  const { refetch: refetchOrganization } = useOrganizationUsers()

  const [inviteUser, { loading }] = useMutation<
    InviteOrganizationUser,
    InviteOrganizationUserVariables
  >(INVITE_USER, {
    onCompleted: () => {
      refetchOrganization()
      onSuccess()
    },
    onError: GraphQLUtils.errorHandler,
  })

  return { inviteUser, loading }
}

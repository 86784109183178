import { Space } from 'antd'
import { ReactElement } from 'react'
import styled from 'styled-components'

const Title = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.baseColor[800]};
`

const SubTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.baseColor[900]};
`

interface Props {
  Icon?: ReactElement
  title: string
  data: string
  horizontalSpacing?: number
  verticalSpacing?: number
}

export const ItemInfo: React.FC<Props> = ({
  Icon,
  title,
  data,
  horizontalSpacing = 20,
  verticalSpacing = 9,
}) => {
  return (
    <>
      <Space size={horizontalSpacing} align="start">
        {Icon && <>{Icon}</>}
        <Space direction="vertical" size={verticalSpacing}>
          <Title>{title}</Title>
          <SubTitle>{data}</SubTitle>
        </Space>
      </Space>
    </>
  )
}

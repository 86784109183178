import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect } from 'react'

import { BROADCAST_CHANNEL } from 'consts'

import { useAuth } from './useAuth'

export const useBrowserLogout = () => {
  const { authChannel, clearUserData } = useAuth()
  const navigate = useNavigate()

  const authChannelHandler = useCallback(
    async (e: MessageEvent) => {
      if (e.data === BROADCAST_CHANNEL.AUTH.LOGOUT) {
        await clearUserData()
        navigate('/auth', { replace: true })
      }
    },
    [clearUserData, navigate],
  )

  useEffect(() => {
    return () => {
      authChannel?.close()
    }
  }, [authChannel])

  useEffect(() => {
    authChannel?.addEventListener('message', authChannelHandler)

    return () => {
      authChannel?.removeEventListener('message', authChannelHandler)
    }
  }, [authChannel, authChannelHandler])
}

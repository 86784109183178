import React from 'react'
import { Select as AntSelect } from 'antd'
import styled from 'styled-components'

import { OrganizationFeatures, UserOrganizationRole } from 'types'

const Select = styled(AntSelect)`
  .ant-select {
    width: 100% !important;
  }
  .ant-select-selector {
    width: 100% !important;
  }
  .ant-select-clear {
    color: white;
  }
  color: white;
` as typeof AntSelect

interface Props {
  onChange: (role: UserOrganizationRole) => void
  value?: UserOrganizationRole
  disabled?: boolean
  organizationFeatures?: OrganizationFeatures[]
  width?: string
  disabledRoles?: UserOrganizationRole[]
}

export const RolesSelect: React.FC<Props> = ({
  onChange,
  value,
  disabled,
  organizationFeatures,
  width,
  disabledRoles,
}) => {
  const allOptions = [
    {
      value: UserOrganizationRole.ADMIN,
      label: 'Admin',
      features: [
        OrganizationFeatures.SUB_FARMERS_MANAGEMENT,
        OrganizationFeatures.CRM,
        OrganizationFeatures.SUPPLY,
      ],
      disabled: disabledRoles?.includes(UserOrganizationRole.ADMIN),
    },
    {
      value: UserOrganizationRole.COMMERCIAL,
      label: 'Comercial',
      features: [OrganizationFeatures.CRM],
      disabled: disabledRoles?.includes(UserOrganizationRole.COMMERCIAL),
    },
    {
      value: UserOrganizationRole.MILL,
      label: 'Molino',
      features: [OrganizationFeatures.CRM],
      disabled: disabledRoles?.includes(UserOrganizationRole.MILL),
    },
    {
      value: UserOrganizationRole.SALES_REP,
      label: 'Rep. de Ventas',
      features: [OrganizationFeatures.SUPPLY],
      disabled: disabledRoles?.includes(UserOrganizationRole.SALES_REP),
    },
  ]

  const filteredOptionsByOrgFeature = organizationFeatures?.length
    ? allOptions.filter(option =>
        organizationFeatures.some(feature => option.features.includes(feature)),
      )
    : undefined

  return (
    <Select
      style={{ width: width ?? '100%' }}
      disabled={!filteredOptionsByOrgFeature || disabled}
      options={filteredOptionsByOrgFeature}
      value={value}
      onChange={onChange}
    />
  )
}

import { Select } from 'antd'

import { AnalyticsTimeMode } from 'types'

interface Props {
  onChange: (time: AnalyticsTimeMode) => void
}

export const TimeSelect: React.FC<Props> = ({ onChange }) => {
  const options = [
    { label: 'Actual', value: AnalyticsTimeMode.CURRENT },
    { label: 'Próxima semana', value: AnalyticsTimeMode.NEXT_WEEK },
    { label: 'Próximas 2 semanas', value: AnalyticsTimeMode.NEXT_TWO_WEEKS },
    { label: 'Próximo mes', value: AnalyticsTimeMode.NEXT_MONTH },
    { label: 'Próximos 2 meses', value: AnalyticsTimeMode.NEXT_TWO_MONTHS },
  ]

  return <Select defaultValue={AnalyticsTimeMode.CURRENT} options={options} onChange={onChange} />
}

import React, { useMemo, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Empty, Row, Typography } from 'antd'
import styled from 'styled-components'

import {
  CardSection,
  CommercialExecutivesSelect,
  ConfirmationModal,
  Loader,
  Table,
} from 'components'

import { UnassignedClient } from './hooks/useUnassignedClients/api'
import { useAssignClient, useUnassignedClients } from './hooks'
import { CardContainer } from '..'

type ClientOption = UnassignedClient & { assignedCommercialExcutiveId?: number }
type AssignmentData = Pick<UnassignedClient, 'name' | 'id'> & {
  commercialExecutiveId: number
  commercialExecutiveName: string
  index: number
}

const { Text: AntText } = Typography

const Text = styled(AntText)`
  font-weight: 700;
`

export const ClientAssignmentCard = () => {
  const { clients, isClientsLoading } = useUnassignedClients()
  const { assignClient, loading: assignClientLoading } = useAssignClient()

  const [assignmentData, setAssignmentData] = useState<AssignmentData>()

  const clientsOptions = useMemo(() => {
    const options: ClientOption[] = clients.map(client => ({
      ...client,
      assignedCommercialExcutiveId: undefined,
    }))

    if (assignmentData) {
      options[assignmentData.index].assignedCommercialExcutiveId =
        assignmentData.commercialExecutiveId
    }

    return options
  }, [assignmentData, clients])

  const columns: ColumnsType<ClientOption> = [
    {
      title: 'Cliente',
      render: (_, { name }) => <Text>{name}</Text>,
    },
    {
      title: 'Marca',
      dataIndex: 'brand',
    },
    {
      title: 'NIT',
      dataIndex: 'governmentId',
    },
    {
      title: 'Días desde visita',
      dataIndex: 'daysSinceVisit',
    },
    {
      title: 'Comercial asignado',
      render: (_, { id, name, assignedCommercialExcutiveId }, index) => (
        <CommercialExecutivesSelect
          value={assignedCommercialExcutiveId}
          showAllOption={false}
          onChange={commercialExecutive => {
            if (!commercialExecutive) return

            setAssignmentData({
              index,
              id,
              name,
              commercialExecutiveId: commercialExecutive.id,
              commercialExecutiveName: commercialExecutive.name,
            })
          }}
        />
      ),
    },
  ]

  return (
    <CardContainer>
      <CardSection title="Asignación de clientes">
        {isClientsLoading ? (
          <Row justify="center" align="middle" style={{ minHeight: '200px' }}>
            <Loader />
          </Row>
        ) : (
          <Table
            columns={columns}
            rowKey={'id'}
            dataSource={clientsOptions}
            pagination={false}
            scroll={{ x: 1000 }}
            locale={{
              emptyText: (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sin información" />
              ),
            }}
          />
        )}
        {assignmentData && (
          <ConfirmationModal
            title="Confirmar asignación"
            description={`¿Está seguro que quiere asignar el comercial ${assignmentData.commercialExecutiveName} al cliente ${assignmentData.name}?`}
            onConfirm={() => {
              assignClient({
                variables: {
                  assignClientDTO: {
                    id: assignmentData.id,
                    commercialExecutiveId: assignmentData.commercialExecutiveId,
                  },
                },
              })
              setAssignmentData(undefined)
            }}
            onCancel={() => {
              setAssignmentData(undefined)
            }}
            loading={assignClientLoading}
          />
        )}
      </CardSection>
    </CardContainer>
  )
}

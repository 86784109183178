import { Col, Form, Row, Modal } from 'antd'
import React from 'react'
import { Controller } from 'react-hook-form'

import { RegionsSelect } from 'components'
import { OrganizationFeatures, UserOrganizationRole } from 'types'
import { SELECTS } from 'consts'
import { useFeatures } from 'hooks'

import { useInvitationForm, useInviteUser } from './hooks'
import { StyledFormInput, Text, InputTitle, FormContainer, StyledCol } from './components'
import { RolesSelect } from '../RolesSelect'
import { StyledButton } from '..'

interface Props {
  organizationFeatures?: OrganizationFeatures[]
  onSuccess: () => void
  onCancel: () => void
}
export const InvitationModal: React.FC<Props> = ({ onSuccess, onCancel, organizationFeatures }) => {
  const { control, handleSubmit, watch, setValue } = useInvitationForm()
  const { inviteUser, loading } = useInviteUser(onSuccess)
  const { assignableRegionRoles } = useFeatures()

  const selectedRole = watch().role

  return (
    <Modal
      title={'Agregar usuario'}
      open
      centered
      width={650}
      footer={null}
      onCancel={onCancel}
      closable={false}
    >
      <FormContainer>
        <Row>
          <Text>Escribe el mail de la persona a invitar a ser miembro de la organización</Text>
        </Row>
        <Col>
          <InputTitle>Mail del nuevo miembro</InputTitle>
          <StyledFormInput control={control} name="email" placeholder="Email" />
        </Col>
        <Row justify="space-between">
          <Row
            justify="space-between"
            style={{
              width: '345px',
            }}
            wrap
          >
            <StyledCol>
              <InputTitle>Rol</InputTitle>
              <Controller
                name="role"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error: fieldError } }) => (
                  <Form.Item
                    name={'role'}
                    validateStatus={fieldError && 'error'}
                    help={fieldError?.message}
                  >
                    <RolesSelect
                      width="165px"
                      value={value}
                      onChange={role => {
                        onChange(role)
                        const regionId = assignableRegionRoles.includes(role)
                          ? undefined
                          : SELECTS.DEFAULT_OPTION_ID

                        setValue('regionId', regionId)
                      }}
                      organizationFeatures={organizationFeatures}
                    />
                  </Form.Item>
                )}
              ></Controller>
            </StyledCol>
            <StyledCol>
              <InputTitle>Zona asignada</InputTitle>
              <Controller
                name="regionId"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error: fieldError } }) => (
                  <Form.Item validateStatus={fieldError && 'error'} help={fieldError?.message}>
                    <RegionsSelect
                      disabled={!assignableRegionRoles.includes(selectedRole)}
                      width="165px"
                      value={value}
                      onChange={onChange}
                      includeDefaultValue={
                        !assignableRegionRoles.includes(selectedRole) ||
                        selectedRole === UserOrganizationRole.ADMIN
                      }
                    />
                  </Form.Item>
                )}
              ></Controller>
            </StyledCol>
          </Row>
          <StyledCol>
            <StyledButton
              loading={loading}
              onClick={handleSubmit(({ email, role, regionId }) => {
                inviteUser({
                  variables: {
                    inviteOrgUserDTO: {
                      email,
                      role,
                      regionId: regionId === SELECTS.DEFAULT_OPTION_ID ? undefined : regionId,
                    },
                  },
                })
              })}
            >
              Invitar
            </StyledButton>
          </StyledCol>
        </Row>
      </FormContainer>
    </Modal>
  )
}

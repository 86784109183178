const autoCapitalize = (value: string): string => {
  const splittedString = value.split(' ')
  const capitalizedWords = splittedString.map(
    word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  )

  return capitalizedWords.join(' ')
}

export const ConversionUtils = {
  autoCapitalize,
}

import { Navigate, Route, Routes } from 'react-router-dom'
import { MapProvider } from 'react-map-gl'
import { useEffect } from 'react'

import { useFeatures } from 'hooks'
import { MiscUtils } from 'utils'
import { WEB } from 'consts'
import { PrivateRoute } from 'components'
import { UserOrganizationRole } from 'types'

import { SideNavbar, Map, Dashboard, Details, Settings, HarvestStats, Sales } from './features'
import { Layout, Sider } from './components'
import {
  MapFiltersContext,
  DashboardFiltersContext,
  DetailsFiltersContext,
  HarvestFiltersContext,
} from './contexts'

export const MainRouter = () => {
  const mapContextValue = MapFiltersContext.useValue()
  const dashboardContextValue = DashboardFiltersContext.useValue()
  const detailsContextValue = DetailsFiltersContext.useValue()
  const harvestContextValue = HarvestFiltersContext.useValue()

  const { featureFlags } = useFeatures()

  useEffect(() => {
    if (featureFlags.crm?.enabled) {
      MiscUtils.setWebDocumentTitle(WEB.DOCUMENT_TITLE_BY_FEATURES.crm)
      return
    }
    MiscUtils.setWebDocumentTitle(WEB.DOCUMENT_TITLE_BY_FEATURES.subFarmersManagement)
  }, [featureFlags.crm?.enabled])

  return (
    <Layout>
      <Sider width={'80px'}>
        <SideNavbar />
      </Sider>
      <Routes>
        <Route
          path="dashboard"
          element={
            <DashboardFiltersContext.Provider value={dashboardContextValue}>
              <Dashboard />
            </DashboardFiltersContext.Provider>
          }
        />
        <Route
          path="details"
          element={
            <DetailsFiltersContext.Provider value={detailsContextValue}>
              <Details />
            </DetailsFiltersContext.Provider>
          }
        />
        <Route
          path="map"
          element={
            <MapProvider>
              <MapFiltersContext.Provider value={mapContextValue}>
                <Map />
              </MapFiltersContext.Provider>
            </MapProvider>
          }
        />
        <Route
          path="harvest"
          element={
            <HarvestFiltersContext.Provider value={harvestContextValue}>
              <HarvestStats />
            </HarvestFiltersContext.Provider>
          }
        />
        <Route
          path="sales"
          element={
            <PrivateRoute roles={[UserOrganizationRole.ADMIN]}>
              <Sales />
            </PrivateRoute>
          }
        />
        <Route
          path="settings"
          element={
            <PrivateRoute roles={[UserOrganizationRole.ADMIN]}>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </Layout>
  )
}
